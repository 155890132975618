import {
  Box,
  Button,
  Image,
  Link,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useUserEventsService } from "../../../../services/Admin/events/userEventsService";
import { TicketDTO } from "../../../../services/events/dto";
import { useProfileService } from "../../../../services/profile/profileService";

import { formateDateAndHour } from "../../../../utils/dateFormatters";
import { formatFullName } from "../../../../utils/stringFormatters";
import { useAsync } from "../../../../utils/useAsync";
import { TicketDetail } from "../components/TicketDetail";
import { TicketTransfer } from "../components/TicketTransfer";

export type TicketListItemProps = {
  _id: string;
  imageURL: string;
  qr: string;
  code: string;
  ticketDescription: string;
  variant?: string;
  title: string;
  date: string;
  location: string;
  profileName: string;
  profileIdNumber: string;

  getTicketPDF: (id: string) => Promise<void>;

  setSelectedTicket: (item: any) => void;
  onDrawerOpen: () => void;
};

export const TicketListItem = (props: TicketListItemProps) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [props.imageURL]);

  return (
    <Box
      bgColor={"secondary.main"}
      width={"100%"}
      height={"max-content"}
      borderRadius={"xl"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={["column", "column", "row"]}
    >
      <Box w={["100%", "100%", "xs"]}>
        <Skeleton
          startColor="secondary.800"
          endColor="secondary.700"
          width={"100%"}
          height={"100%"}
          style={{ aspectRatio: 2.2 }}
          isLoaded={imageLoaded}
        >
          <Image
            src={props.imageURL}
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageLoaded(true)}
            fallback={<Box height={"100%"} width={"100%"}></Box>}
            overflow={"hidden"}
            width={"100%"}
            height={"100%"}
            borderLeftRadius={[0, 0, "xl"]}
            style={{ aspectRatio: 2.2 }}
            borderTopRadius={["xl", "xl", 0]}
          />
        </Skeleton>
      </Box>

      <Box
        px={6}
        py={[4, 4, 4, 6, 8]}
        display={"flex"}
        flexGrow={1}
        flexDir={["column", "column", "row"]}
        justifyContent="space-between"
      >
        <Box
          color={"secondary.contrast"}
          display={"flex"}
          flexDir={"column"}
          justifyContent="space-between"
        >
          <Text
            noOfLines={[0, 0, 1]}
            textAlign={["center", "center", "left"]}
            fontWeight={600}
          >
            {props.title}
          </Text>
          <Text noOfLines={[0, 0, 1]} textAlign={["center", "center", "left"]}>
            {props.date}
          </Text>
          <Text noOfLines={[0, 0, 1]} textAlign={["center", "center", "left"]}>
            {props.location}
          </Text>
        </Box>
        <Box display={"flex"} flexDir={"column"} justifyContent="center">
          <Button
            onClick={() => {
              props.setSelectedTicket(props);
              props.onDrawerOpen();
            }}
            colorScheme={"primary"}
            mt={[4, 4, 2]}
          >
            Ver ticket
          </Button>
          <Button
            mt={[4, 4, 2]}
            isLoading={isDownloading}
            onClick={async () => {
              setIsDownloading(true);
              await props.getTicketPDF(props._id);
              setIsDownloading(false);
            }}
          >
            Descargar PDF
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const mapTicketDTOToItem = (
  ticket: TicketDTO,
  getTicketPDF: (id: string) => Promise<void>,
  setSelectedTicket: (item: any) => void,
  onDrawerOpen: () => void
): TicketListItemProps => {
  if (ticket && ticket.meta) {
    return {
      imageURL: ticket.meta.images?.card.url,
      _id: ticket._id,
      qr: ticket.qr,
      code: ticket.transactionId,
      ticketDescription: ticket.name,
      variant: ticket.meta?.name,
      title: ticket.meta.eventString,
      date: formateDateAndHour(new Date(ticket.meta.datetimeFunction)),
      location: ticket.meta.placeString,
      profileName: formatFullName(
        ticket.meta.user.profile.firstname,
        ticket.meta.user.profile.lastname
      ),
      profileIdNumber: ticket.meta.user.profile.idNumber,

      getTicketPDF,
      setSelectedTicket,
      onDrawerOpen,
    };
  }
};

export type TicketListProps = {};

export const TicketList = (props: TicketListProps) => {
  const profileService = useProfileService();
  const detailDrawerManager = useDisclosure();
  const transferDrawerManager = useDisclosure();
  const [selectedTicket, setSelectedTicket] =
    useState<TicketListItemProps | null>(null);

  const [data, error, loading, execute] = useAsync(profileService.getTickets, {
    defaultValue: [],
  });

  const eventService = useUserEventsService();

  const getTicketPDF = async (id: string) => {
    try {
      const bufferedFile = await eventService.getTicketPDF(id);

      const href = URL.createObjectURL(new Blob([bufferedFile]));

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        "ChileTiket-" + new Date().toISOString() + ".pdf"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL

      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }

      URL.revokeObjectURL(href);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    execute();
  }, []);

  return (
    <>
      <TicketDetail
        drawerManager={detailDrawerManager}
        selectedTicket={selectedTicket}
        transferCall={() => {
          detailDrawerManager.onClose();
          transferDrawerManager.onOpen();
        }}
      />
      <TicketTransfer
        drawerManager={transferDrawerManager}
        selectedTicket={selectedTicket}
        transferCallback={execute}
      />
      <Stack spacing={8} height={"full"}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Text color={"muted.main"}>
            Ocurrió un error. Inténtalo mas tarde.
          </Text>
        ) : data.length === 0 ? (
          <Box
            borderColor={"secondary.main"}
            borderWidth={"3px"}
            borderRadius={"xl"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"full"}
            p={5}
          >
            <Text textAlign={"center"} fontSize={36} fontWeight={700}>
              Ups!
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              Actualmente no tienes ningún ticket disponible.
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              Una vez que obtengas uno, podrás verlo aquí mismo.
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              Mientras tanto mirá todos los eventos disponibles que tenemos para
              vos!
            </Text>
            <Link textAlign={"center"} color={"primary.main"} href="/">
              Ir al Home
            </Link>
          </Box>
        ) : (
          data.map((ticket, index) => (
            <TicketListItem
              key={index}
              setSelectedTicket={setSelectedTicket}
              onDrawerOpen={detailDrawerManager.onOpen}
              {...mapTicketDTOToItem(
                ticket,
                getTicketPDF,
                setSelectedTicket,
                detailDrawerManager.onOpen
              )}
            />
          ))
        )}
      </Stack>
    </>
  );
};
