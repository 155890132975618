import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { DeleteDiscountIcon } from "../../../../../components/Icons/DeleteDiscountIcon";

export const DeleteModal = ({ deleteFn }: { deleteFn: () => void }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant="outline"
        borderColor="red.main"
        textColor="red.main"
        borderRadius="full"
        py="8px"
        pl="24px"
        pr="32px"
        gap="8px"
        onClick={onOpen}
      >
        <DeleteDiscountIcon />
        Eliminar
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor="secondary.main">
          <ModalHeader px="32px" py="16px">
            Eliminar descuento
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            px="32px"
            py="24px"
            borderTop="1px solid muted.600"
            borderBottom="1px solid muted.600"
          >
            <Text noOfLines={2}>
              ¿Estás seguro que quieres eliminar este descuento? Esta acción no
              se puede revertir.
            </Text>
          </ModalBody>

          <ModalFooter px="24px" py="16px">
            <Button
              colorScheme="primary"
              variant="outline"
              borderRadius="full"
              mr={3}
              px="24px"
              py="8px"
              fontSize="14px"
              onClick={onClose}
            >
              Volver
            </Button>
            <Button
              variant="solid"
              bgColor="red.main"
              borderRadius="full"
              px="24px"
              py="8px"
              fontSize="14px"
              onClick={() => {
                deleteFn();
                onClose();
              }}
            >
              Eliminar descuento
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
