import { useContext } from "react";
import { AxiosContext } from "../../providers/axiosContext";
import { AsyncStore } from "../../utils/createAsyncStore";
import {
  PaymentDTO,
  ShoppingCartDTO,
  ShoppingSessionDTO,
  ShoppingSessionInfoDTO,
} from "./dto";
import { GetTicketByShoppingSessionDTO, TicketByShoppingSessionDTO } from "../events/dto";
import { useShoppingSessionAsyncStore } from "./shoppingSessionStore";
import { getProducerURL } from "../../utils/getProducer";


export interface ShoppingSessionService {
  getShoppingSessionPayment: (
    sessionId: string,
    type: string
  ) => Promise<PaymentDTO>;
  getFastPassShoppingSessionPayment: (
    sessionId: string,
    type: string,
    eventId: string
  ) => Promise<PaymentDTO>;
  createShoppingSession: (cart: ShoppingCartDTO) => Promise<ShoppingSessionDTO>;
  createFastPasssShoppingSession: (cart: ShoppingCartDTO) => Promise<ShoppingSessionDTO>;
  getShoppingSession: (id: string) => Promise<ShoppingSessionInfoDTO>;
  getFastPassShoppingSession: (id: string) => Promise<ShoppingSessionInfoDTO>;
  storage: AsyncStore<ShoppingSessionDTO>;
  validateToken: (token: string) => Promise<ResponseData>;
  getDiscount: (
    code: string,
    shoppingSessionId: string
  ) => Promise<ShoppingSessionDTO>;
  getTicketByShoppingSession: (sessionId: string) => Promise<TicketByShoppingSessionDTO>; 
}

interface ResponseData {
  success: boolean;
  challenge_ts: string;
  hostname: string;
  score: number;
  action: string;
}

export const useShoppingSessionService = (): ShoppingSessionService => {
  const axiosContext = useContext(AxiosContext);

  const storage = useShoppingSessionAsyncStore();

  const getShoppingSessionPayment = async (
    sessionId: string,
    type: string
  ): Promise<PaymentDTO> => {
    const res = await axiosContext.authAxios.get<PaymentDTO>(
      `/tickets/shopping-sessions/payment/${sessionId}/${type}`,
      {
        params: {
          backurl: `${window.location.origin}/profile?screen=tickets`,
        },
      }
    );
    console.log("window.location.href", window.location.href);
  
    // Procesar parámetros
    const queryParams = new URLSearchParams(window.location.search);
    const paymentStatus = queryParams.get("paymentStatus");
    const orderId = queryParams.get("orderId");
  
    if (paymentStatus) {
      console.log("Estado del pago:", paymentStatus, "Orden ID:", orderId);
      // Puedes agregar lógica adicional aquí según el estado del pago
    }
  
    // Limpia los parámetros de la URL
    if (window.location.search) {
      const cleanUrl = window.location.origin + window.location.pathname;
      window.history.replaceState(null, "", cleanUrl);
    }
  
    return res.data;
  };
  
  

  const getFastPassShoppingSessionPayment = async (
    sessionId: string,
    type: string,
    eventId: string
  ): Promise<PaymentDTO> => {
    const res = await axiosContext.authAxios.get<PaymentDTO>(
      `/tickets/shopping-sessions/payment/fastpass/${sessionId}/${type}`,
      {
        params: {
          backurl: `${window.location.origin}/fastPass/events/${eventId}`,
        },
      }
    );
    console.log("window.location.href",window.location.href)
    return res.data;
  };

  const createShoppingSession = async (cart: ShoppingCartDTO) => {
    const res = await axiosContext.authAxios.post<ShoppingSessionDTO>(
      "/tickets/shopping-sessions",
      cart
    );
    return res.data;
  };

  const createFastPasssShoppingSession = async (cart: ShoppingCartDTO) => {
    const res = await axiosContext.authAxios.post<ShoppingSessionDTO>(
      "/tickets/shopping-sessions/fastpass",
      cart
    );
    return res.data;
  };

  const getFastPassShoppingSession = async (id: string) => {
    const res = await axiosContext.authAxios.get<ShoppingSessionInfoDTO>(
      `/tickets/shopping-sessions/fastpass/${id}`
    );
    return res.data;
  };

  const getShoppingSession = async (id: string) => {
    const res = await axiosContext.authAxios.get<ShoppingSessionInfoDTO>(
      `/tickets/shopping-sessions/${id}`
    );
    return res.data;
  };

  const validateToken = async (token: string) => {
    const res = await axiosContext.authAxios.get<ResponseData>(
      `/validation-utils/google/captcha`,
      { params: { response: token } }
    );
    return res.data;
  };

  const getDiscount = async (code: string, shoppingSessionId: string) => {
    const res = await axiosContext.authAxios.post<ShoppingSessionDTO>(
      `/tickets/discount-codes/payment/`,
      { code, shoppingSessionId }
    );
    return res.data;
  };

  const getTicketByShoppingSession = async (sessionId: string): Promise<TicketByShoppingSessionDTO> => {
    const res = await axiosContext.publicAxios.get<TicketByShoppingSessionDTO>(
      `/tickets/ticket/fastpass/shoppingSession/${sessionId}`
    );
    return res.data;
  };

  return {
    getShoppingSessionPayment,
    createShoppingSession,
    getShoppingSession,
    storage,
    validateToken,
    getDiscount,
    createFastPasssShoppingSession,
    getFastPassShoppingSession,
    getFastPassShoppingSessionPayment,
    getTicketByShoppingSession
  };
};
