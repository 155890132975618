import { addDays } from "date-fns";
import { useContext } from "react";
import { AxiosContext } from "../../../providers/axiosContext";
import {
  ChartDTO,
  EventStatDTO,
  FixedTotalSalesDTO,
  VariantsTableDTO,
} from "./dto";

export interface StatsService {
  getEventStats: (
    eventId: string,
    functionId: string
  ) => Promise<{ totalStats: EventStatDTO; diffStats: EventStatDTO[] }>;
  getVariantsChart: (
    eventId: string,
    functionId: string
  ) => Promise<ChartDTO[]>;
  getPaymentsChart: (
    eventId: string,
    functionId: string
  ) => Promise<ChartDTO[]>;
  getVariantsTable: (
    eventId: string,
    functionId: string
  ) => Promise<VariantsTableDTO[]>;
  getFixedTotalSales: (eventId: string) => Promise<FixedTotalSalesDTO>;
}

const getStatsURL = (eventId: string, functionId: string) =>
  functionId === "all" ? "events/" + eventId : "eventFunctions/" + functionId;

export const useStatsService = (): StatsService => {
  const axiosContext = useContext(AxiosContext);

  const getEventStats = async (
    eventId: string,
    functionId: string
  ): Promise<{ totalStats: EventStatDTO; diffStats: EventStatDTO[] }> => {
    const totalStatsRes = await axiosContext.authAxios.get(
      `/stats/tickets/${getStatsURL(eventId, functionId)}/general`
    );

    const today = new Date();
    const yesterday = addDays(today, -1);

    const diffStatsRes = await axiosContext.authAxios.get(
      `/stats/tickets/${getStatsURL(
        eventId,
        functionId
      )}/general?granularity=day&fromDate=${yesterday.toISOString()}&toDate=${today.toISOString()}`
    );

    const todayData = diffStatsRes.data.find(
      (dataset) => dataset.day === today.getDate()
    );
    const yesterdayData = diffStatsRes.data.find(
      (dataset) => dataset.day === yesterday.getDate()
    );

    return {
      totalStats: totalStatsRes.data,
      diffStats: [yesterdayData, todayData],
    };
  };

  const getVariantsChart = async (
    eventId: string,
    functionId: string
  ): Promise<ChartDTO[]> => {
    const res = await axiosContext.authAxios.get(
      `/stats/tickets/${getStatsURL(eventId, functionId)}/ticketVariant`
    );
    return res.data;
  };

  const getPaymentsChart = async (
    eventId: string,
    functionId: string
  ): Promise<ChartDTO[]> => {
    const res = await axiosContext.authAxios.get(
      `/stats/payment/${getStatsURL(eventId, functionId)}/typePay`
    );
    return res.data;
  };

  const getVariantsTable = async (
    eventId: string,
    functionId: string
  ): Promise<VariantsTableDTO[]> => {
    const res = await axiosContext.authAxios.get(
      `/stats/tickets/${getStatsURL(eventId, functionId)}/tickets`
    );
    return res.data;
  };

  const getFixedTotalSales = async (
    functionId: string
  ): Promise<FixedTotalSalesDTO> => {
    const { data } = await axiosContext.authAxios.get(
      `/tickets/shopping-sessions/stats/${functionId}`
    );
    return data;
  };

  return {
    getEventStats,
    getVariantsChart,
    getPaymentsChart,
    getVariantsTable,
    getFixedTotalSales,
  };
};
