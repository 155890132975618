import React, { useContext } from "react";
import { AuthContext } from "../providers/authContext";
import ModalContainer from "./LoginModal";

export default function PrivateRoute({ component: Component, ...rest }) {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.authState.status;

  return currentUser === "loading" ? null : currentUser === "logged" ? (
    <Component />
  ) : (
    <ModalContainer isOpen={true} onClose={() => {}} onOpen={() => {}} />
  );
}
