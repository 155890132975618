import React from "react";
import { HomeProps } from "./interfaces";
import { useHomeController } from "./home.controller";
import { Box, Icon, SimpleGrid } from "@chakra-ui/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
//import "swiper/css/navigation";
import "swiper/css/autoplay";
import "./styles.css";
import EventCard from "./components/eventCard";
import { EventsCarouselItem } from "./components/carouselItem";

import ModalEmailVerification from "./components/modalEmailVerification";

export const Home: React.FC<HomeProps> = ({
  useController = useHomeController,
}) => {
  const controller = useController();
  return (
    <Box>
      <Box h={"max-content"}>
        <Swiper
          // style={{ aspectRatio: isMobile ? 2.5 : 3.8 }}
          style={{ aspectRatio: 3.8 }}
          className="mySwiper"
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
            disabledClass: "swiper-button-disabled",
          }}
          loop={true}
          autoplay={{ delay: 5600 }}
          modules={[Navigation, Pagination, Autoplay, EffectFade]}
          pagination={false}
        >
          {controller.carouselEvents.map((slide, index) => (
            <SwiperSlide key={"slide-" + index} onClick={slide.onSelect}>
              <EventsCarouselItem
                imageURL={slide.imageURL}
                loading={controller.eventsLoading}
              />
            </SwiperSlide>
          ))}
          <Box
            className=" image-swiper-button image-swiper-button-prev"
            bg={"backgroundColor"}
            _hover={{ bg: "secondary.800" }}
            transition="background-color 0.2s ease"
          >
            <Box className="image-swiper-button-content image-swiper-button-content-left">
              <Icon
                as={FaChevronLeft}
                fontSize={"1rem"}
                color={"backgroundContrast"}
              />
            </Box>
          </Box>
          <Box
            className=" image-swiper-button image-swiper-button-next"
            bg={"backgroundColor"}
            _hover={{ bg: "secondary.800" }}
          >
            <Box className="image-swiper-button-content image-swiper-button-content-right">
              <Icon
                as={FaChevronRight}
                fontSize={"1rem"}
                color={"backgroundContrast"}
              />
            </Box>
          </Box>
        </Swiper>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignContent={"center"}
        padding="auto"
        justifyContent={"center"}
        mt={5}
      >
        <SimpleGrid columns={[1, 1, 2, 2, 3]} rowGap={10} gap={20}>
          {controller.listEvents.map((event, index) => (
            <Box key={"event-" + index}>
              <EventCard {...event} loading={controller.eventsLoading} />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
      <ModalEmailVerification />
    </Box>
  );
};
