export const ButtonComponent = {
  components: {
    Button: {
      variants: {
        solid: (props) => ({
          bgColor: props.colorScheme + ".main",
          color:
            (props.colorScheme === "primary" ||
              props.colorScheme === "secondary") &&
            props.colorScheme + ".contrast",
        }),
        outline: (props) => ({
          color: props.colorScheme + ".main",
          borderColor: props.colorScheme + ".main",
        }),
      },
    },
  },
};
