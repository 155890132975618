import { useContext } from "react";
import { AxiosContext } from "../../../providers/axiosContext";
import { RegularReservationDTO, SeatsReservationDTO } from "./dto";

export interface ReservationsService {
  getRegularReservations: (
    functionId: string
  ) => Promise<RegularReservationDTO>;
  getSeatsReservations: (functionId: string) => Promise<SeatsReservationDTO>;

  cancelRegularReservation: (
    functionId: string,
    reservation: RegularReservationDTO
  ) => Promise<void>;
  cancelSeatsReservation: (
    functionId: string,
    reservation: SeatsReservationDTO
  ) => Promise<void>;

  createRegularReservation: (
    functionId: string,
    reservation: RegularReservationDTO
  ) => Promise<void>;
  createSeatsReservation: (
    functionId: string,
    reservation: SeatsReservationDTO
  ) => Promise<void>;
}

export const useReservationsService = (): ReservationsService => {
  const axiosContext = useContext(AxiosContext);

  const getRegularReservations = async (functionId: string) => {
    const res = await axiosContext.authAxios.get<RegularReservationDTO>(
      `/invitations/reservations/function/${functionId}`
    );
    return res.data;
  };
  const getSeatsReservations = async (functionId: string) => {
    const res = await axiosContext.authAxios.get<SeatsReservationDTO>(
      `/invitations/reservations/seats/function/${functionId}`
    );
    return res.data;
  };
  const cancelRegularReservation = async (
    functionId: string,
    reservation: RegularReservationDTO
  ) => {
    const res = await axiosContext.authAxios.put(
      `/invitations/reservations/function/${functionId}/delete`,
      reservation
    );
    return res.data;
  };
  const cancelSeatsReservation = async (
    functionId: string,
    reservation: SeatsReservationDTO
  ) => {
    const res = await axiosContext.authAxios.put(
      `/invitations/reservations/seats/function/${functionId}/delete`,
      reservation
    );
    return res.data;
  };
  const createRegularReservation = async (
    functionId: string,
    reservation: RegularReservationDTO
  ) => {
    const res = await axiosContext.authAxios.post(
      `/invitations/reservations/function/${functionId}`,
      reservation
    );
    return res.data;
  };
  const createSeatsReservation = async (
    functionId: string,
    reservation: SeatsReservationDTO
  ) => {
    const res = await axiosContext.authAxios.post(
      `/invitations/reservations/seats/function/${functionId}`,
      reservation
    );
    return res.data;
  };

  return {
    getRegularReservations,
    getSeatsReservations,
    cancelRegularReservation,
    cancelSeatsReservation,
    createRegularReservation,
    createSeatsReservation,
  };
};
