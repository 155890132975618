import { useContext } from "react";
import { AxiosContext } from "../../providers/axiosContext";
import { ProducerDTO } from "./dto";
import { getProducerURL } from "../../utils/getProducer";

export interface ProducerService {
  getProducerConfig: () => Promise<ProducerDTO>;
  getProducerFastPassUrl: (id: string) => Promise<any>;
}

export const useProducerService = (): ProducerService => {
  const axiosContext = useContext(AxiosContext);
  const producerURL = getProducerURL();

  const getProducerConfig = async () => {
    const res = await axiosContext.authAxios.get<ProducerDTO>(
      "/companies/producers/config",
      {
        params: {
          url: producerURL ?? "",
          company: "CHILE_TICKETS",
        },
      }
    );
    return res.data;
  };
  const getProducerFastPassUrl = async (id) => {
    const res = await axiosContext.authAxios.get<any>(
      `/companies/producers/${id}`,
    );
    console.log('getProducerFastPassUrl',res.data)
    return res.data;
  };

  return {
    getProducerConfig,
    getProducerFastPassUrl
  };
};
