export const isChiletiketProducer = () => {
  const url = window.location.origin;
  const chiletiketDomains = [
    "www.chiletiket.cl",
    "www.chiletiket.com",
    "chiletiket.cl",
    "chiletiket.com",
    "test.chiletiket.cl",
    "test.chiletiket.com",
    "https://www.chiletiket.cl",
    "https://www.chiletiket.com",
    "https://chiletiket.cl",
    "https://chiletiket.com",
    "https://test.chiletiket.cl",
    "https://test.chiletiket.com",
  ];

  return (
    chiletiketDomains.some((domain) => url === domain) ||
    url.includes("localhost")
  );
};

export const getProducerURL = () => {
  if (isChiletiketProducer()) {
    return undefined;
  } else {
    return window.location.origin;
  }
};

export const getProducer = () => {
  if (isChiletiketProducer()) {
    return undefined;
  } else {
    return window.location.host.split(".")[0];
  }
};
