import {
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { formatInTimeZone } from "date-fns-tz";
import { es } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

import {
  capitalizeFirstLetter,
  formateDateAndHour,
  TIME_ZONE,
} from "../../../../utils/dateFormatters";

export interface TicketCart {
  functionId?: string;
  functionIndex?: number;
  functionDate?: Date;
  tickets: {
    id: string;
    index: number;
    quantity: number;
  }[];
}

export interface TicketSelectorProps {
  days: {
    day: string;
    date: Date;
    functions: {
      id: string;
      index: number;
      date: Date;
      hour: string;
      tickets: {
        id: string;
        name: string;
        price: string;
        color?: string;
        description?: string;
        maxQuantityPerUser?: number;
        minimumQuantityPerUser?: number;
        availables?: number;
        soldOut?: boolean;
      }[];
    }[];
  }[];

  loading?: boolean;
  setTicketCart: (cart: TicketCart) => void;
}

export const TicketSelector: React.FC<TicketSelectorProps> = (props) => {
  const [selectedDayIndex, setselectedDayIndex] = useState<number>(0);
  const [selectedFunctionIndex, setselectedFunctionIndex] = useState<number>(0);
  const [selectedTicketsIndex, setselectedTicketsIndex] = useState<number[]>(
    []
  );

  const hasDays = useMemo(() => props.days.length !== 0, [props.days]);
  const selectedDay = useMemo(
    () => props.days[selectedDayIndex],
    [props.days, selectedDayIndex]
  );
  const selectedFunction = useMemo(
    () => (hasDays ? selectedDay.functions[selectedFunctionIndex] : undefined),
    [hasDays, selectedDay, selectedFunctionIndex]
  );

  useEffect(() => {
    setselectedFunctionIndex(0);
  }, [selectedDayIndex]);

  useEffect(() => {
    if (hasDays) {
      props.days &&
        setselectedTicketsIndex(
          new Array(
            props.days[selectedDayIndex].functions[
              selectedFunctionIndex
            ].tickets.length
          ).fill(0) ?? []
        );
    }
  }, [selectedFunctionIndex, selectedDayIndex, props.days, hasDays]);

  const incrementTickets = (
    value: number,
    index: number,
    max?: number,
    min?: number
  ) => {
    setselectedTicketsIndex((tickets) => {
      const newTickets = tickets.map((v, i) => {
        if (i !== index) {
          return v;
        } else {
          const newValue = (v ?? 0) + value;
          if (max && newValue > max) {
            return max;
          }
          if (value > 0 && min && newValue < min) {
            return min;
          } else if (value < 0 && min && newValue < min) {
            return 0;
          }

          return newValue > 0 ? newValue : 0;
        }
      });
      return newTickets;
    });
  };

  useEffect(() => {
    if (hasDays) {
      props.setTicketCart({
        functionId: selectedFunction.id,
        functionIndex: selectedFunction.index,
        functionDate: selectedFunction.date,
        tickets: selectedFunction.tickets.map((ticket, index) => ({
          quantity: selectedTicketsIndex[index],
          id: ticket.id,
          index,
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTicketsIndex,
    selectedFunctionIndex,
    selectedFunction,
    hasDays,
    props.setTicketCart,
  ]);

  return (
    <Box
      w={{ base: "100%", md: "100%" }}
      borderColor="secondary.main"
      borderWidth={5}
      borderRadius={20}
      alignSelf={"flex-start"}
      p={5}
      overflow={{ xs: "unset", md: "auto" }}
    >
      {!hasDays || props.loading ? (
        <Box
          display={"flex"}
          alignItems="center"
          height={"100%"}
          justifyContent={"center"}
          w="100%"
        >
          <Spinner margin={"auto"} color={"backgroundContrast"} />
        </Box>
      ) : (
        <>
          {props.days.length === 1 && props.days[0].functions.length === 1 ? (
            <Stack direction={"column"} spacing={3}>
              <Text fontSize={18} color={"backgroundContrast"}>
                {capitalizeFirstLetter(
                  formatInTimeZone(props.days[0].date, TIME_ZONE, "EEEE", {
                    locale: es,
                  })
                ) +
                  " " +
                  formateDateAndHour(props.days[0].date)}
              </Text>
            </Stack>
          ) : (
            <>
              <VStack spacing={2.5} alignItems="left">
                <Text color={"backgroundContrast"}>Dia</Text>
                <Wrap w="100%">
                  {props.days.map((day, index) => (
                    <WrapItem key={"day-" + index}>
                      <Button
                        onClick={() => setselectedDayIndex(index)}
                        variant={
                          index === selectedDayIndex ? "solid" : "outline"
                        }
                        borderRadius="md"
                        px="2"
                        colorScheme={"primary"}
                        textTransform="none"
                        fontWeight="600"
                      >
                        {day.day}
                      </Button>
                    </WrapItem>
                  ))}
                </Wrap>
                <HStack></HStack>
              </VStack>

              <VStack spacing={2.5} alignItems="left">
                <Text color={"backgroundContrast"}>Función</Text>
                <Wrap w="100%">
                  {props.days[selectedDayIndex].functions.map(
                    (eventFunction, index) => (
                      <WrapItem key={"day-" + index}>
                        <Button
                          onClick={() => setselectedFunctionIndex(index)}
                          variant={
                            index === selectedFunctionIndex
                              ? "solid"
                              : "outline"
                          }
                          borderRadius="md"
                          px="2"
                          py="0.5"
                          colorScheme={"primary"}
                          textTransform="none"
                          fontWeight="600"
                        >
                          {eventFunction.hour}
                        </Button>
                      </WrapItem>
                    )
                  )}
                </Wrap>
              </VStack>
            </>
          )}
          <Box
            borderWidth={0.5}
            borderColor="gray"
            borderRadius={"full"}
            my={5}
          />
          {
            // Ticket Types and Quantity Field
          }
          <Text fontWeight={"bold"} color={"backgroundContrast"}>
            Tickets
          </Text>
          <VStack spacing={4} alignItems="left" mt={5}>
            {props.days[selectedDayIndex]?.functions[
              selectedFunctionIndex
            ]?.tickets?.map((ticket, index) => (
              <Stack spacing={2} key={index}>
                {index !== 0 && <Divider mb={3} />}
                <Flex
                  key={ticket.id}
                  direction={["column", "column", "row"]}
                  alignItems={["center", "center", "left"]}
                  justifyContent={"space-between"}
                >
                  <HStack
                    w={"100%"}
                    spacing={3}
                    justifyContent={["center", "center", "left"]}
                  >
                    {ticket.color && (
                      <Circle size={5} backgroundColor={ticket.color} />
                    )}
                    <Button
                      variant={
                        selectedTicketsIndex[index] > 0 ? "solid" : "outline"
                      }
                      whiteSpace="unset"
                      minW={["80%", "50%", 0]}
                      borderRadius="md"
                      minH={"40px"}
                      h={"max-content"}
                      px="2"
                      py="0.5"
                      colorScheme={ticket.soldOut ? "secondary" : "primary"}
                      textTransform="uppercase"
                      fontWeight="600"
                      mb={[2, 2, 0]}
                      onClick={() => {
                        if (
                          !ticket.soldOut &&
                          !(
                            (ticket.maxQuantityPerUser &&
                              ticket.maxQuantityPerUser <=
                                selectedTicketsIndex[index]) ||
                            (ticket.availables &&
                              ticket.availables <= selectedTicketsIndex[index])
                          )
                        ) {
                          const ticketsToIncrement =
                            ticket.minimumQuantityPerUser &&
                            selectedTicketsIndex[index] <
                              ticket.minimumQuantityPerUser
                              ? ticket.minimumQuantityPerUser
                              : 1;
                          incrementTickets(ticketsToIncrement, index);
                        }
                      }}
                    >
                      {ticket.name}
                    </Button>
                    {ticket.color && (
                      <Circle
                        display={["block", "block", "none"]}
                        size={5}
                        backgroundColor={"transparent"}
                      />
                    )}
                  </HStack>
                  <Stack
                    direction={["column", "column", "row"]}
                    spacing={[2, 2, 6]}
                    align="center"
                  >
                    <Text
                      textAlign={["center", "center", "left"]}
                      fontWeight={600}
                      fontSize={20}
                      color={"backgroundContrast"}
                    >
                      {ticket.price}
                    </Text>

                    {ticket.soldOut ? (
                      <Button
                        variant={"solid"}
                        borderRadius="md"
                        px="2"
                        colorScheme={"red"}
                        textTransform="none"
                        fontWeight="600"
                      >
                        AGOTADO
                      </Button>
                    ) : (
                      <HStack spacing={4}>
                        <IconButton
                          onClick={() =>
                            incrementTickets(
                              -1,
                              index,
                              ticket.maxQuantityPerUser,
                              ticket.minimumQuantityPerUser
                            )
                          }
                          icon={<FaMinus />}
                          aria-label="Less"
                          size={"xs"}
                          color={"backgroundContrast"}
                        />
                        <Text
                          fontSize={20}
                          textAlign={["center", "center", "left"]}
                          color={"backgroundContrast"}
                        >
                          {selectedTicketsIndex[index] ?? 0}
                        </Text>
                        <IconButton
                          onClick={() =>
                            incrementTickets(
                              1,
                              index,
                              ticket.maxQuantityPerUser,
                              ticket.minimumQuantityPerUser
                            )
                          }
                          icon={<FaPlus />}
                          aria-label="More"
                          size={"xs"}
                          color={"backgroundContrast"}
                          disabled={
                            (ticket.maxQuantityPerUser &&
                              ticket.maxQuantityPerUser <=
                                selectedTicketsIndex[index]) ||
                            (ticket.availables &&
                              ticket.availables <= selectedTicketsIndex[index])
                          }
                        />
                      </HStack>
                    )}
                  </Stack>
                </Flex>
                {ticket.description && (
                  <Text
                    mt={0}
                    pl={[0, 0, 35]}
                    textAlign={["center", "center", "left"]}
                    color={"muted.main"}
                  >
                    {ticket.description}
                  </Text>
                )}
              </Stack>
            ))}
          </VStack>
        </>
      )}
    </Box>
  );
};
