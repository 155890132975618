import {
  Box,
  Circle,
  Divider,
  Flex,
  IconButton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { DeleteIcon } from "../../../../components/Icons/DeleteIcon";

import { priceFormatter } from "../../../../utils/priceFormat";
import { ticketLabelFormatter } from "../../../../utils/stringFormatters";
import { SeatsCartSummaryProps } from "./cart-summary";

export const SeatsCartSummaryMobile: React.FC<SeatsCartSummaryProps> = (
  props
) => {
  let totalAmount = 0;

  const renderCategoriesResume = useCallback(() => {
    if (Object.keys(props.categoriesToBudget).length > 0) {
      return Object.keys(props.categoriesToBudget).map(
        (category, index, array) => {
          const tickets = props.categoriesToBudget[category];
          const isSeat = tickets[0].objectType === "Seat";

          const amountOfTickets = isSeat
            ? tickets.length
            : tickets[0].numSelected;

          const subTotal = tickets[0].pricing.price * amountOfTickets;
          // eslint-disable-next-line react-hooks/exhaustive-deps
          totalAmount = totalAmount + subTotal;

          return (
            <Stack justify={"space-between"} direction="row" px={2}>
              <Text>{category}</Text>
              <Text>{amountOfTickets + " x $" + tickets[0].pricing.price}</Text>
            </Stack>
          );
        }
      );
    } else return null;
  }, [props.categoriesToBudget]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      w={{ base: "100%", md: "100%" }}
      justifyContent="space-between"
      px={5}
      py={2}
      pos="relative"
    >
      <Box overflow={"auto"}>
        <Text fontSize={16} color={"white"} fontWeight="semibold">
          Asientos reservados:
        </Text>
        {Object.keys(props.categoriesToBudget).length === 0 ? (
          <Text fontSize={14} color={"muted.main"}>
            Una vez que hayas seleccionados tus asientos, podrás visualizar el
            detalle de los mismos aquí.
          </Text>
        ) : (
          <VStack
            spacing={1}
            alignItems="left"
            mt={3}
            maxH="20vh"
            overflowY={"auto"}
            pr={3}
            sx={{
              "&::-webkit-scrollbar": {
                width: "8px",
                borderRadius: "10px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `secondary.main`,
                borderRadius: "10px",
              },
            }}
          >
            {Object.keys(props.categoriesToBudget).map((category) => {
              const ticketsArray = props.categoriesToBudget[category];

              return (
                <>
                  <Flex
                    justifyContent={"space-between"}
                    borderWidth={1}
                    borderColor="white"
                    py={1}
                    px={2}
                    borderRadius={5}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Stack direction={"row"}>
                      <Circle
                        size={5}
                        backgroundColor={
                          props.functionCategories.find(
                            (cat) => cat.name === category
                          ).color ?? "primary.main"
                        }
                      />
                      <Text fontSize={14}>{category}</Text>
                    </Stack>
                    <Text>
                      {"$" + priceFormatter(ticketsArray[0].pricing.price)}
                    </Text>
                  </Flex>
                  {ticketsArray.map((ticket) => {
                    return (
                      <Flex
                        justifyContent={"space-between"}
                        py={1}
                        px={2}
                        borderRadius={5}
                        direction={"row"}
                        alignItems={"center"}
                        fontSize={16}
                      >
                        <Text noOfLines={2}>
                          {ticketLabelFormatter(ticket)}
                        </Text>
                        <IconButton
                          aria-label="Delete"
                          icon={<DeleteIcon />}
                          variant="ghost"
                          colorScheme={"primary"}
                          rounded="full"
                          onClick={() => {
                            ticket.objectType === "Seat"
                              ? props.chartInstance.deselectObjects([
                                  ticket.uuid,
                                ])
                              : ticket.numSelected > 1
                              ? props.chartInstance.deselectObjects([
                                  {
                                    id: ticket.id,
                                    amount: 1,
                                  },
                                ])
                              : props.chartInstance.deselectObjects([
                                  { id: ticket.id },
                                ]);
                          }}
                        />
                      </Flex>
                    );
                  })}
                </>
              );
            })}
          </VStack>
        )}
      </Box>
      <Stack spacing={2} direction={"column"} mt={2}>
        <Divider />
        <Text px={2} fontSize={18} color={"white"}>
          Resumen de compra:
        </Text>
        <Stack
          spacing={3}
          pr={5}
          sx={{
            "&::-webkit-scrollbar": {
              width: "8px",
              borderRadius: "10px",
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `secondary.main`,
              borderRadius: "10px",
            },
          }}
        >
          {renderCategoriesResume()}
        </Stack>
        <Stack spacing={3} pr={5}>
          {[
            {
              title: "Subtotal",
              price: totalAmount,
            },
            {
              title: "Cargo por servicio",
              price: Math.round(
                totalAmount * (props.serviceCharge ? props.serviceCharge : 0.15)
              ),
            },
          ].map((item, index) => (
            <Flex
              justifyContent={"space-between"}
              key={"revision-" + index}
              direction={["row"]}
              px={2}
            >
              <Text>{item.title}</Text>
              <Text alignSelf={["flex-end", "auto"]}>
                {"$" + priceFormatter(item.price)}
              </Text>
            </Flex>
          ))}
          <Flex
            justifyContent={"space-between"}
            borderWidth={1}
            borderColor="white"
            py={1}
            px={2}
            borderRadius={5}
            direction={"row"}
            alignItems={"center"}
          >
            <Text>TOTAL</Text>
            <Text>
              {"$" +
                priceFormatter(
                  totalAmount +
                    Math.round(
                      totalAmount *
                        (props.serviceCharge ? props.serviceCharge : 0.15)
                    )
                )}
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  );
};
