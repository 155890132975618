/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, Box, Text, Divider } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import Card from "../../../../components/Card";
import { SeatsCartSummary } from "../components/cart-summary";
import { TicketSelector } from "../components/ticket-selector";
import { LayoutProps, SeatsObject } from "../interfaces";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import es from "date-fns/esm/locale/es";
import {
  capitalizeFirstLetter,
  formateDateAndHour,
  TIME_ZONE,
} from "../../../../utils/dateFormatters";
import { priceFormatter } from "../../../../utils/priceFormat";
import { ticketLabelFormatter } from "../../../../utils/stringFormatters";
import { seatsKey } from "../../../../config/envConf";
import EventInfo from "../components/event-info";
import { formatInTimeZone } from "date-fns-tz";
import { useToaster } from "../../../../utils/useToaster";
import { useColors } from "../../../../utils/useColors";

export const mapToCategoriesSummary = (
  selectedSeats: SeatsObject[],
  categories: {
    category: string;
    price: number;
  }[]
) => {
  const obj = categories.reduce((o, key) => ({ ...o, [key.category]: [] }), {});
  Object.keys(obj).forEach((cat) => {
    const elementsToSet = selectedSeats.filter(
      (element) => element.category.label === cat && element
    );
    if (elementsToSet.length > 0) {
      obj[cat] = elementsToSet;
    } else {
      try {
        delete obj[cat];
      } catch {}
    }
  });
  return obj;
};

const SeatsLayout: React.FC<LayoutProps> = ({
  controller,
  hasSingleFunction = true,
}) => {
  const [formattedCategories, setFormattedCategories] = useState([]);
  const [selectedTicketsIndex, setselectedTicketsIndex] = useState<number[]>(
    []
  );
  const { warningToast } = useToaster();

  const [selectedSeats, setSelectedSeats] = useState<SeatsObject[]>([]);

  const ticketVariants = useMemo(() => {
    if (!!controller?.eventFunctions) {
      return controller?.eventFunctions?.days[0]?.functions[0]?.tickets;
    }
  }, [controller?.eventFunctions]);

  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    const ticketsManage =
      controller.eventFunctions?.days[0]?.functions[0]?.tickets;
    if (ticketsManage && ticketsManage.length > 0) {
      const categoriesToSet = ticketsManage.map((ticket) => {
        const formattedPrice = ticket.price.replace(/[$,.]+/g, "");
        return {
          category: ticket.name,
          price: parseFloat(formattedPrice),
        };
      });
      setFormattedCategories(categoriesToSet);
    }
  }, [controller?.eventFunctions?.days]);

  useEffect(() => {
    if (controller.eventFunctions?.days.length > 0) {
      controller.eventFunctions.setTicketCart({
        functionId: controller.eventFunctions.days[0].functions[0].id,
        functionIndex: 0,
        functionDate: controller.eventFunctions.days[0].functions[0].date,
        tickets: controller.eventFunctions.days[0].functions[0].tickets.map(
          (ticket, index) => ({
            quantity: selectedTicketsIndex[index],
            id: ticket.id,
            index,
          })
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTicketsIndex, controller.eventFunctions.setTicketCart]);

  const getTicketsLimits = () => {
    return ticketVariants
      .filter((variant) => variant.maxQuantityPerUser)
      .map((variant) => ({
        category: variant.name,
        quantity: variant.maxQuantityPerUser,
      }));
  };

  const colors = useColors();

  return (
    <>
      <EventInfo controller={controller} />
      {!controller.eventLoading && !controller.cartSummary.loading && (
        <Box
          display={"flex"}
          flexDir={["column", "column", "row"]}
          px={{ sm: 5, md: "7%" }}
        >
          <Stack direction={"column"} w="100%" spacing={10} px={5} mt={5}>
            {hasSingleFunction ? (
              <Card
                p={5}
                bgColor={"backgroundColor"}
                borderColor={"secondary.main"}
                borderWidth={5}
              >
                <Stack direction={"column"} spacing={3}>
                  <Text color={"primary.main"} fontWeight={600} fontSize={20}>
                    {controller.displayName}
                  </Text>
                  <Divider />
                  <Stack direction={"column"} spacing={3}>
                    <Text fontSize={18} color={"backgroundContrast"}>
                      {controller.eventFunctions.days.length > 0 &&
                        capitalizeFirstLetter(
                          formatInTimeZone(
                            controller.eventFunctions?.days[0].date,
                            TIME_ZONE,
                            "EEEE",
                            {
                              locale: es,
                            }
                          )
                        ) +
                          " " +
                          formateDateAndHour(
                            controller.eventFunctions.days[0].date
                          )}
                    </Text>
                  </Stack>
                </Stack>
              </Card>
            ) : (
              <TicketSelector {...controller.eventFunctions} />
            )}

            <Card
              bgColor={"transparent"}
              borderColor="secondary.main"
              borderWidth={5}
              borderRadius={20}
            >
              <Text
                textAlign={"center"}
                fontWeight={600}
                fontSize={20}
                color={"backgroundContrast"}
              >
                Mapa de ubicaciones
              </Text>
              <Text color={"backgroundContrast"} textAlign={"center"} my={5}>
                Utiliza el mapa para elegir tus asientos. Puedes navegar sobre
                el y hacer zoom en los espacios que desees.
              </Text>
              {controller.currentChartKey && formattedCategories && (
                <SeatsioSeatingChart
                  showSectionContents={"onlyAfterZoom"}
                  onRenderStarted={(chart) => {
                    setChartRef(chart);
                  }}
                  workspaceKey={seatsKey}
                  event={controller.currentChartKey}
                  showMinimap={true}
                  showFullScreenButton={false}
                  stylePreset="bubblegum"
                  language={"es"}
                  colorScheme={"dark"}
                  tooltipInfo={(seat) => {
                    const description = ticketVariants.find(
                      (cat) => cat.name === seat?.category?.label
                    )?.description;

                    return description && `${description}`;
                  }}
                  maxSelectedObjects={[...getTicketsLimits(), { total: 6 }]}
                  colors={{
                    colorSelected: "hsl(214, 96%, 53%)",
                    colorTitle: colors.primary.main,
                  }}
                  cursorTooltip={{
                    showActionHint: false,
                    showAvailability: false,
                    showCategory: false,
                    showLabel: true,
                    showPricing: true,
                    showUnavailableNotice: true,
                    stylizedLabel: true,
                  }}
                  pricing={formattedCategories}
                  priceFormatter={(price) => {
                    return "$" + priceFormatter(price as number);
                  }}
                  onObjectSelected={(obj) => {
                    setSelectedSeats((prev) => {
                      var copyPrev = [...prev];
                      if (
                        obj?.objectType === "GeneralAdmissionArea" &&
                        prev.find((items) => items.uuid === obj?.uuid)
                      ) {
                        copyPrev[
                          prev.findIndex((items) => items.uuid === obj?.uuid)
                        ].numSelected = obj.numSelected;
                        return [...copyPrev];
                      } else {
                        return [...prev, obj];
                      }
                    });
                  }}
                  onObjectDeselected={(obj) => {
                    if (obj?.status === "reservedByToken") {
                      !controller.cartSummary.purchaseLoading &&
                        warningToast(
                          "Asiento deseleccionado",
                          "El asiento " +
                            ticketLabelFormatter(obj) +
                            " se quitó de tus selecciones por que ya fue adquirido por otra persona"
                        );
                    }

                    !controller.cartSummary.purchaseLoading &&
                      setSelectedSeats((prev) => {
                        var copyPrev = [...prev];
                        if (
                          obj?.numSelected > 0 &&
                          prev.find((items) => items.uuid === obj?.uuid)
                        ) {
                          copyPrev[
                            prev.findIndex((items) => items.uuid === obj?.uuid)
                          ].numSelected = obj.numSelected;
                          return [...copyPrev];
                        } else {
                          return prev.filter(
                            (element) => element.uuid !== obj.uuid
                          );
                        }
                      });
                  }}
                  region="sa"
                />
              )}
            </Card>
          </Stack>
          <Box
            position={["unset", "unset", "sticky"]}
            top={71}
            mt={[0, 0, 5]}
            mx={5}
            // TODO: Research WTF is this
            h={"prose"}
            width={["calc(100% -5)", "calc(100% -5)", "80%"]}
          >
            {formattedCategories.length > 0 && chartRef && (
              <SeatsCartSummary
                controller={controller}
                isExempt={controller.cartSummary.isExempt}
                categoriesToBudget={mapToCategoriesSummary(
                  selectedSeats,
                  formattedCategories
                )}
                functionCategories={ticketVariants}
                chartInstance={chartRef}
                serviceCharge={controller.cartSummary.serviceCharge}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SeatsLayout;
