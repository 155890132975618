import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { validateRut } from "../../../../utils/rutFormatter";
import { useEmailValidator } from "../../../../utils/useEmailValidator";
import { useShoppingSessionService } from "../../../../services/shoppingSession/shoppingSessionService";
import { TicketCart, TicketSelectorProps } from "../../EventDetail/components/ticket-selector";
import { useEventsService } from "../../../../services/events/eventsService";
import { useAsync } from "../../../../utils/useAsync";
import { useNavigate, useLocation } from "react-router-dom";
import { formatSingleDate, formatSingleHour } from "../../../../utils/dateFormatters";
import { compareAsc, isAfter, isBefore, min } from "date-fns";
import { priceFormatter } from "../../../../utils/priceFormat";

interface PersonalDataDTO {
  mail: string;
  firstname: string;
  lastname: string;
  rut: string;
}

const PersonalData = () => {
  const { validateEmail, validationState, setValidationState } =
    useEmailValidator();
    const [isRutExent, setIsRutExent] = useState(false);

  const TransferFormSchema = useMemo(
    () =>
      {
        if(isRutExent){
          return Yup.object().shape({
            firstname: Yup.string()
              .required("Debe ingresar un Nombre")
              .matches(
                /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
                "Tu nombre posee caracteres inválidos"
              )
              .max(50, "El nombre es muy largo"),
            lastname: Yup.string()
              .required("Debe ingresar un Apellido")
              .matches(
                /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
                "Tu apellido posee caracteres inválidos"
              )
              .max(50, "El apellido es muy largo"),
            mail: Yup.string()
              .required("Debe ingresar un correo")
              .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                "El correo no tiene el formato correspondiente"
              )
              .max(150, "El mail es muy largo")
              .test("validation", "El email ingresado no es válido", validateEmail),
          })
        }
        return Yup.object().shape({
        firstname: Yup.string()
          .required("Debe ingresar un Nombre")
          .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu nombre posee caracteres inválidos"
          )
          .max(50, "El nombre es muy largo"),
        lastname: Yup.string()
          .required("Debe ingresar un Apellido")
          .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu apellido posee caracteres inválidos"
          )
          .max(50, "El apellido es muy largo"),
        rut: Yup.string()
          .required("Debe ingresar su RUT")
          .max(12, "El RUT es muy largo")
          .test("format", "El RUT ingresado es inválido", function (rut) {
            return validateRut(rut);
          }),
        mail: Yup.string()
          .required("Debe ingresar un correo")
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            "El correo no tiene el formato correspondiente"
          )
          .max(150, "El mail es muy largo")
          .test("validation", "El email ingresado no es válido", validateEmail),
      })
    },
    [useEmailValidator, isRutExent]
  );
  const [ticketData, setTicketData] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setTicketData(location.state)
    }
  }, [location]);
  console.log("ticketData", ticketData)

  const shoppingSessionService = useShoppingSessionService();
  const {
    loading: shoppingSessionLoading,
    error: shoppingSessionError,
    execute: shoppingSessionExecute,
  } = shoppingSessionService.storage;


  const handleSubmit = async (data: any) => {

  };

  const form = useFormik<PersonalDataDTO>({
    initialValues: {
      firstname: "",
      lastname: "",
      mail: "",
      rut: "",
    },
    onSubmit: handleSubmit,
    validationSchema: TransferFormSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true
  });

  const onPressForeign = async() => {
    setIsRutExent((prev) => !prev);
    form.setErrors({});
    setTimeout(() => {
      form.validateForm();
    },300)
  }

  const onPressNExt = async () => {

    const valid = await form.validateForm(form.values);
    if(Object.keys(valid).length){
      return
    }
    console.log("form valid", valid)
    const fastPassBody = {
      ...ticketData,
      name: form.values.firstname,
      lastname: form.values.lastname,
      rut: form.values.rut,
      email: form.values.mail

    }
    delete fastPassBody.totalPrice;
    shoppingSessionExecute(
      () =>
        shoppingSessionService.createFastPasssShoppingSession(fastPassBody),
      () => {
        navigate("payment");
      }
    );
  }

  return (
    <FormikProvider value={form}>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px", padding: "24px", paddingTop: 0, maxWidth: 800 }}>
        <p style={{ fontSize: "24px", fontWeight: "600" }}>Datos personales</p>
        <Field name="firstname">
          {({ field }) => (
            <FormControl
              variant="floating"
              id="firstname"
              colorScheme={"secondary"}
              isRequired
              isInvalid={form.touched.firstname && !!form.errors.firstname}
            >
              <Input {...field} placeholder=" " max={50} />
              <FormLabel>Nombre</FormLabel>
              <FormErrorMessage fontSize={"small"}>
                {form.errors.firstname}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="lastname">
          {({ field }) => (
            <FormControl
              variant="floating"
              id="lastname"
              colorScheme={"secondary"}
              isRequired
              isInvalid={form.touched.lastname && !!form.errors.lastname}
            >
              <Input {...field} placeholder=" " max={50} />
              <FormLabel>Apellido</FormLabel>
              <FormErrorMessage fontSize={"small"}>
                {form.errors.lastname}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="mail">
          {({ field }) => (
            <FormControl
              variant="floating"
              id="mail"
              colorScheme={"secondary"}
              isRequired
              isInvalid={form.touched.mail && !!form.errors.mail}
            >
              <Input {...field} placeholder=" " max={50} />
              <FormLabel>Mail</FormLabel>
              <FormErrorMessage fontSize={"small"}>
                {form.errors.mail}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="rut">
          {({ field }) => (
            <FormControl
              isDisabled={isRutExent}
              variant="floating"
              id="rut"
              colorScheme={"secondary"}
              isRequired
              isInvalid={form.touched.rut && !!form.errors.rut}
            >
              <Input {...field} placeholder=" " max={50} />
              <FormLabel>Rut</FormLabel>
              <FormErrorMessage fontSize={"small"}>
                {form.errors.rut}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Checkbox
          w={"max-content"}
          colorScheme={"muted"}
          color={"backgroundContrast"}
          isChecked={isRutExent}
          onChange={onPressForeign}
        >
          Soy extranjero
        </Checkbox>
        <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", backgroundColor: "rgba(38, 38, 38, 1)", padding: "24px", borderRadius: "16px", alignItems: "center", marginTop: "16px" }}>
          <p style={{ margin: 0, fontSize: "16px" }}>Precio final</p>
          <p style={{ fontSize: "20px", fontWeight: "600" }}>${ticketData ? ticketData?.totalPrice : 0}</p>
        </div>
        <Button
          borderRadius={"full"}
          width="full"
          disabled={!form.isValid || !form.values.firstname || !form.values.lastname || !form.values.mail || (!form.values.rut && !isRutExent)}
          colorScheme={"primary"}
          textAlign={"center"}
          mt={5}
          maxWidth={480}
          alignSelf={"center"}
          px={10}
          onClick={onPressNExt}
          isLoading={shoppingSessionLoading}
          loadingText="Cargando..."
        >
          IR A PAGAR
        </Button>
      </div>
    </FormikProvider>
  )
};

export default PersonalData;