import {
  ModalContent,
  ModalBody,
  Stack,
  FormControl,
  Input,
  FormErrorMessage,
  ModalFooter,
  Button,
  Text,
  Link,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FormikProvider, Field, useFormik } from "formik";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { useAuthService } from "../../../services/auth/authService";
import { FormProps } from "../interfaces";
import { useToaster } from "../../../utils/useToaster";

const ChangePassword: React.FC<FormProps> = ({ setActiveForm, onClose }) => {
  const [show, setShow] = useState(false);

  const [params, setParams] = useSearchParams();

  const authService = useAuthService();
  const { successToast, errorToast } = useToaster();

  const handleSubmit = async (values) => {
    try {
      await authService.changePassword(
        params.get("cod"),
        values.newPassword,
        params.get("email")
      );

      successToast(
        "Contraseña modificada con éxito!",
        "Se cambio la contraseña exitosamente. Inicie sesion en su cuenta con su nueva contraseña."
      );
      onClose();
      setParams({});
      setActiveForm("login");
    } catch (error) {
      console.error(error);

      errorToast(
        "Ocurrió un error",
        "Ocurrió un error al cambiar la contraseña. Porfavor, inténtalo mas tarde"
      );
    }
  };

  const formik = useFormik({
    initialValues: { newPassword: "" },
    onSubmit: handleSubmit,
  });

  return (
    <ModalContent
      borderRadius={20}
      backgroundColor={"backgroundColor"}
      borderWidth={2}
      borderColor={"secondary.main"}
      p={7}
    >
      <ModalBody justifyContent={"center"}>
        <FormikProvider value={formik}>
          <Stack spacing={5} textAlign="center">
            <Text fontWeight={"semibold"} fontSize="lg">
              Cambiar contraseña
            </Text>
            <Field>
              {({ field, form }) => (
                <FormControl
                  variant="outline"
                  borderRadius={4}
                  id="newPassword"
                  isInvalid={form.errors.newPassword}
                  isRequired
                >
                  <InputGroup variant={"outline"}>
                    <Input
                      borderRadius={4}
                      variant={"outline"}
                      color={"white"}
                      type={show ? "text" : "password"}
                      placeholder="Contraseña"
                      value={formik.values.newPassword}
                      name="newPassword"
                      autoComplete="new-password"
                      onChange={formik.handleChange}
                      _autofill={{
                        textFillColor: "white",
                        boxShadow: "transparent",
                        transition: "background-color 5000s ease-in-out 0s",
                      }}
                    />
                    <InputRightElement
                      _hover={{
                        cursor: "pointer",
                      }}
                      children={
                        show ? (
                          <FaEye color="white" />
                        ) : (
                          <FaEyeSlash color="white" />
                        )
                      }
                      onClick={() => setShow(!show)}
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.newPassword}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
        </FormikProvider>
      </ModalBody>
      <ModalFooter justifyContent={"center"}>
        <Stack direction={"column"} spacing={5}>
          <Button
            borderRadius={50}
            colorScheme="primary"
            disabled={!formik.values.newPassword || !!formik.errors.newPassword}
            isLoading={formik.isSubmitting}
            onClick={formik.submitForm}
          >
            Cambiar contraseña
          </Button>
          <Text textAlign={"center"} fontWeight="normal" fontSize={"md"}>
            ¿Recordaste la clave?{" "}
            <Link
              style={{ color: "primary.main" }}
              onClick={() => setActiveForm("login")}
            >
              Iniciar Sesion
            </Link>
          </Text>
        </Stack>
      </ModalFooter>
    </ModalContent>
  );
};

export default ChangePassword;
