import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Circle,
  Flex,
  HStack,
  Image,
  Skeleton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";

type EventMapProps = {
  eventLoading?: boolean;
  mapImageURL?: string;
  purchasedTickets?: {
    name: string;
    price: number;
    quantity: number;
    color?: string;
  }[];
};

const EventMap = (props: EventMapProps) => {
  const [mapImageLoaded, setMapImageLoaded] = useState(true);

  return (
    <Box
      position={["unset", "unset", "sticky"]}
      top={71}
      mt={[0, 0, 5]}
      mx={5}
      h={"max-content"}
      width={["calc(100% -5)", "calc(100% -5)", "50%"]}
      padding={10}
      borderColor="secondary.main"
      borderWidth={5}
      borderRadius={20}
    >
      <Text textAlign={"center"} mb={5} fontSize={24} fontWeight={600}>
        Mapa de ubicaciones
      </Text>
      <Skeleton
        startColor="secondary.800"
        endColor="secondary.700"
        width={"100%"}
        // style={{ aspectRatio: 0.64 }}
        borderRadius={20}
        overflow={"hidden"}
        isLoaded={!props.eventLoading && mapImageLoaded}
      >
        <Image
          src={props.mapImageURL}
          onLoad={() => setMapImageLoaded(true)}
          onError={() => setMapImageLoaded(true)}
          fallback={
            <Box
              width={"100%"}
              overflow={"hidden"}
              borderRadius={20}
              // style={{ aspectRatio: 0.64 }}
            ></Box>
          }
          width={"100%"}
          // style={{ aspectRatio: 0.64 }}
          overflow={"hidden"}
          borderRadius={20}
        />
      </Skeleton>
      {props.purchasedTickets.some((ticket) => ticket.quantity > 0) && (
        <>
          <Text mt={5} fontSize={22} fontWeight={600}>
            Tu compra:
          </Text>
          <Flex wrap={"wrap"}>
            {props.purchasedTickets.map(
              (ticket) =>
                ticket.quantity > 0 && (
                  <HStack
                    mr={5}
                    my={1}
                    spacing={1}
                    justifyContent={["center", "center", "left"]}
                  >
                    <Tooltip label={ticket.name}>
                      {ticket.color ? (
                        <Circle size={5} backgroundColor={ticket.color} />
                      ) : (
                        <InfoOutlineIcon />
                      )}
                    </Tooltip>
                    <Text>{`X ${ticket.quantity}`}</Text>
                  </HStack>
                )
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default EventMap;
