import {
  Box,
  Button,
  Circle,
  HStack,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { TicketVariantDTO } from "../../../../services/events/dto";

type TicketListProps = {
  hasSeats: boolean;
  tickets: TicketVariantDTO[];
  onContinue: () => void;
};

function TicketList(props: TicketListProps) {
  return (
    <Stack
      spacing={20}
      w={{ base: "100%", md: props.hasSeats ? "100%" : "70%" }}
      borderColor="secondary.main"
      borderWidth={5}
      borderRadius={20}
      alignSelf={"flex-start"}
      margin={"auto"}
      p={8}
      overflow={{ xs: "unset", md: "auto" }}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      h={props.hasSeats && "prose"}
    >
      <Stack>
        <Text>Entradas disponibles</Text>
        <List px={4}>
          {props.tickets.map((ticket, index) => (
            <ListItem
              py={4}
              borderBottomColor={"grey.100"}
              borderBottomWidth={1}
            >
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={6}
              >
                <HStack spacing={5}>
                  {ticket.color && <Circle bgColor={ticket.color} size={6} />}
                  <Stack spacing={0} justifyContent={"center"} minHeight={39}>
                    <Text
                      color={ticket.available < 1 && "gray.500"}
                      fontWeight={600}
                    >
                      {ticket.name}
                    </Text>
                    {ticket.available > 0 && (
                      <Text fontSize={14}>{ticket.available} disponibles</Text>
                    )}
                  </Stack>
                </HStack>
                <Text fontWeight={600}>
                  {ticket.available > 0 ? `$${ticket.price}` : "NO DISPONIBLE"}
                </Text>
              </HStack>
            </ListItem>
          ))}
        </List>
      </Stack>
      <Stack alignSelf={"center"} w={["auto", "auto", "md"]} spacing={8}>
        <Text textAlign={"center"}>
          Este evento tiene una cola de espera en curso, para realizar tu compra
          deberas unirte y esperar tu turno
        </Text>
        <Button
          borderRadius={"full"}
          colorScheme="primary"
          onClick={props.onContinue}
        >
          UNIRME A LA FILA
        </Button>
      </Stack>
    </Stack>
  );
}

export default TicketList;
