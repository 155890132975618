/* eslint-disable react-hooks/exhaustive-deps */
import {
  Text,
  Box,
  Skeleton,
  Image,
  Stack,
  Switch,
  Center,
  HStack,
  Grid,
} from "@chakra-ui/react";
import { FormikProvider, FormikProps } from "formik";
import { useEffect, useState } from "react";
import {
  PaymentMethodFields,
  useEventFormStore,
} from "../../../../services/createEvent/createEventStore";
import { EventStep } from "./step";
import { colors } from "../../../../theme/colors";
import { PaymentMethods } from "../../../../services/events/dto";

export interface PaymentMethodsFormProps {
  formik: FormikProps<PaymentMethodFields>;
}

interface PaymentType {
  type: PaymentMethods;
  label: string;
  image: string[];
  logos: string[];
}

interface PaymentSwitchProps {
  paymentType: PaymentType;
  handleChange: (type: string) => void;
  defaultEnabled: boolean;
}

const PaymentsForm = ({ formik }: PaymentMethodsFormProps) => {
  const { setFormData, formData } = useEventFormStore();

  const [mapImageLoaded, setMapImageLoaded] = useState(false);

  const loading = false;

  const logosSrc = "/assets/payments/cardLogos";

  const paymentTypes: PaymentType[] = [
    {
      type: "MP",
      label: "Mercado Pago",
      image: ["/assets/payments/mpago.png"],
      logos: [
        `${logosSrc}/Mercadopago.svg`,
        `${logosSrc}/Mastercard.svg`,
        `${logosSrc}/Visa.svg`,
        `${logosSrc}/DinnersClub.svg`,
        `${logosSrc}/Amex.svg`,
      ],
    },
    {
      type: "PH",
      label: "Pago Hub",
      image: [
        "/assets/payments/WebPayLogo.svg",
        "/assets/payments/FintocLogo.svg",
      ],
      logos: [
        `${logosSrc}/Mastercard.svg`,
        `${logosSrc}/Visa.svg`,
        `${logosSrc}/DinnersClub.svg`,
        `${logosSrc}/Amex.svg`,
        `${logosSrc}/Webpay.svg`,
        `${logosSrc}/WebpayOneclick.svg`,
        `${logosSrc}/Fintoc.svg`,
        `${logosSrc}/Redcompra.svg`,
      ],
    },
    {
      type: "FL",
      label: "Flow",
      image: [
        `${logosSrc}/FlowLogo.svg`],
      logos: [
        `${logosSrc}/Webpay.svg`,
        `${logosSrc}/OnePay.svg`,
        `${logosSrc}/Khipu.svg`
      ],
    },
  ];

  const PaymentSwitch: React.FC<PaymentSwitchProps> = ({
    paymentType,
    handleChange,
    defaultEnabled = false,
  }) => (
    <Stack w="auto" height={'max-content'} gap={4}>
      <Stack
        py={"12px"}
        px={"16px"}
        w="full"
        borderRadius="16px"
        bgColor={"white"}
        flex={1}
        direction="row"
        alignItems={"center"}
        borderWidth={"2px"}
      >
        <Switch
          size="md"
          colorScheme={"muted"}
          sx={{
            // eslint-disable-next-line no-useless-computed-key
            ["& > .chakra-switch__track"]: {
              bgColor: "muted.300",
              _checked: { bgColor: "black" },
            },
          }}
          onChange={() => {
            handleChange(paymentType.type);
          }}
          defaultChecked={defaultEnabled}
        />
        <Center flex={1} h="full">
          <p>{defaultEnabled}</p>
          <HStack gap={2}>
            {paymentType.image.map((img, i) => (
              <Image key={Math.random()} objectFit={"cover"} src={img} h={5} />
            ))}
          </HStack>
        </Center>
      </Stack>
      <HStack
        gap={1}
        w="full"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        {paymentType.logos.slice(0, 5).map((img, i) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
          >
            <Image objectFit={"cover"} key={Math.random()} src={img} h={7} />
          </Box>
        ))}
      </HStack>
      <HStack
        gap={1}
        w="full"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        {paymentType.logos.slice(5).map((img, i) => (
          <Image objectFit={"cover"} key={Math.random()} src={img} h={7} />
        ))}
      </HStack>
    </Stack>
  );

  const togglePaymentMethod = (method: PaymentMethods) => {
    const selectedMethods = formik.values.methods;
    if (selectedMethods.includes(method)) {
      const updatedMethods = selectedMethods.filter((m) => m !== method);
      formik.setFieldValue("methods", updatedMethods);
    } else {
      formik.setFieldValue("methods", [...selectedMethods, method]);
    }
  };

  useEffect(() => {
    setFormData({
      paymentMethods: formik.values,
    });
  }, [formik.values]);

  return (
    <EventStep
      buttonProps={{
        onClick: formik.submitForm,
        isLoading: loading,
      }}
      preview={
        formData?.images?.place?.url && (
          <Box
            width={"sm"}
            p={5}
            backgroundColor={"secondary.main"}
            borderRadius={20}
          >
            <Text mb={5} textAlign={"center"} fontSize={24} fontWeight={600}>
              Mapa de ubicaciones
            </Text>
            <Skeleton
              startColor="secondary.800"
              endColor="secondary.700"
              width={"100%"}
              // style={{ aspectRatio: 0.64 }}
              borderRadius={20}
              overflow={"hidden"}
              isLoaded={mapImageLoaded}
            >
              <Image
                src={formData?.images?.place.url}
                onLoad={() => setMapImageLoaded(true)}
                onError={() => setMapImageLoaded(true)}
                fallback={
                  <Box
                    width={"100%"}
                    overflow={"hidden"}
                    borderRadius={20}
                  // style={{ aspectRatio: 0.64 }}
                  ></Box>
                }
                width={"100%"}
                // style={{ aspectRatio: 0.64 }}
                overflow={"hidden"}
                borderRadius={20}
              />
            </Skeleton>
          </Box>
        )
      }
      label="FINALIZAR"
    >
      <FormikProvider value={formik}>
        <Text fontSize={{ base: "md", md: "lg" }} w="85%">
          Selecciona los medios de pago
        </Text>
        <Text fontSize={14} fontWeight={400} color={colors.muted.main} my={3}>
          Selecciona los medios de pago que los usuarios tendrán disponibles al
          momento de comprar entradas.
        </Text>
        <Box h={10}>
          <Text color={colors.red[500]}>{formik?.errors?.methods}</Text>
        </Box>

        <Grid gap={5} display={"grid"} gridTemplateColumns="repeat(2, 1fr)">
          {paymentTypes.map((payment, i) => (
            <PaymentSwitch
              key={Math.random()}
              paymentType={payment}
              defaultEnabled={formik.values.methods.includes(payment.type)}
              handleChange={togglePaymentMethod}
            />
          ))}
        </Grid>
      </FormikProvider>
    </EventStep>
  );
};

export default PaymentsForm;
