import { useContext } from "react";
import { AxiosContext } from "../../providers/axiosContext";
import { AsyncStore } from "../../utils/createAsyncStore";
import { TicketDTO } from "../events/dto";
import { ProfileDTO, TransferDataDTO } from "./dto";
import { useProfileAsyncStore } from "./profileStore";
import { getProducer } from "../../utils/getProducer";

export interface ProfileService {
  getProfile: () => any;
  updateProfile: (data: ProfileDTO) => Promise<ProfileDTO>;
  getTickets: () => Promise<TicketDTO[]>;
  changePassword: (oldPassword: string, newPassword: string) => any;
  profileStore: AsyncStore<ProfileDTO>;
  transferTicket: (transferData: TransferDataDTO) => Promise<any>;
}

export const useProfileService = (): ProfileService => {
  const axiosContext = useContext(AxiosContext);

  const profileStore = useProfileAsyncStore();

  const producer = getProducer();

  const getProfile = async () => {
    const res = await axiosContext.authAxios.get(
      "/auth/CHILE_TICKETS/users/profile"
    );
    return res.data;
  };

  const updateProfile = async (data: ProfileDTO) => {
    const res = await axiosContext.authAxios.put(
      "/auth/CHILE_TICKETS/users/profile",
      data
    );
    return res.data;
  };

  const getTickets = async () => {
    const res = await axiosContext.authAxios.get<TicketDTO[]>(
      "/tickets/ticket/user",
      { params: { producer } }
    );
    return res.data;
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    const res = await axiosContext.authAxios.post(
      "auth/CHILE_TICKET/users/change-password-logged",
      { newPassword, oldPassword }
    );
    return res.data;
  };

  const transferTicket = async (transferData: TransferDataDTO) => {
    const res = await axiosContext.authAxios.post(
      "/tickets/ticket/transfer",
      transferData
    );
    return res.data;
  };

  return {
    getProfile,
    updateProfile,
    profileStore,
    getTickets,
    changePassword,
    transferTicket,
  };
};
