import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Stack,
  SimpleGrid,
  Spinner,
  HStack,
  IconButton,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { StyledSelectProps } from "../../../theme/additions/select/Select";
import { OfficeSaleProps } from "./interfaces";
import { useOfficeSaleController } from "./office-sale.controller";

export const OfficeSale: React.FC<OfficeSaleProps> = ({
  useController = useOfficeSaleController,
}) => {
  const controller = useController();

  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [navigation]);

  return (
    <Stack
      px={{ sm: "2%", md: "10%" }}
      mx="2%"
      py="1%"
      direction="column"
      spacing={5}
      overflow={"auto"}
    >
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10} w="full">
        <Stack direction={["column", "row"]} w="full">
          {controller.eventLoading ? (
            <Spinner alignSelf={"center"} my={5} />
          ) : (
            <HStack spacing={3}>
              <IconButton
                aria-label="Call Segun"
                mt="0.2rem"
                size="xs"
                bgColor={"muted.main"}
                rounded={"full"}
                alignSelf="self-start"
                icon={<ArrowBackIcon color="black" boxSize={"1.3rem"} />}
                onClick={() => controller.onBackButtonPress()}
              />
              <VStack alignItems={"start"} spacing={1}>
                <Text fontSize={"xl"} fontWeight="medium" noOfLines={1}>
                  {controller.eventInfo?.name}
                </Text>
                <Text fontSize={"sm"} noOfLines={1}>
                  {controller.eventInfo?.placeName}
                </Text>
              </VStack>
            </HStack>
          )}
        </Stack>
        <Box w="full">
          <Select
            defaultValue={controller.eventFunctions}
            placeholder={"Seleccione la función"}
            isDisabled={controller.eventLoading}
            isLoading={controller.eventLoading}
            value={controller.activeFunction}
            onChange={controller.onFunctionSelect}
            {...StyledSelectProps}
            chakraStyles={{
              ...StyledSelectProps.chakraStyles,
              control: (provided) => ({
                ...provided,
                borderColor: "muted.500",
                borderWidth: 1,
                color: "muted.500",
                borderRadius: 4,
              }),
            }}
            options={controller.eventFunctions}
          />
        </Box>
      </SimpleGrid>

      <Outlet
        context={{
          ...controller.context,
          activeFunction: controller.activeFunction,
          eventInfo: controller.eventInfo,
        }}
      />
    </Stack>
  );
};
