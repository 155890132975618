import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FrequentProps } from "./interfaces";

export const Frequent: React.FC<FrequentProps> = () => {

  return (
    <Stack bgColor={"#000"} h={"100%"} justifyContent='center' alignItems='center'>
      <Stack pt={10} px={[5, 5, 20]} w="70%"  >
        <HStack spacing={3} justifyContent='center' my={6} position='relative' w='full'>
          {/* <ArrowBackIcon
            as="button"
            onClick={controller.onBackButtonPress}
            cursor="pointer"
            bgColor={"muted.main"}
            borderRadius="full"
            color={"black"}
            h={5}
            w={5}
            userSelect={"none"}
            position='absolute'
            left={2}
          /> */}
          <Text fontWeight={600} fontSize={['larger','xx-large']}>
            Preguntas frecuentes:
          </Text>
        </HStack>
      </Stack>
      <Stack px={[5, 20]} w={['100%', "90%", "70%"]}>
        <Accordion allowToggle allowMultiple>
          <AccordionItem
            bgColor="#262626"
            _expanded={{ borderRadius: "0" }}
            borderRadius="40px"
            mb={6}
          >
            <AccordionButton
              rounded="full"
              bgColor="#000"
              border="2px solid #23FEDB"
              color="#23FEDB"
              _expanded={{ bg: "#23FEDB", color: "black" }}
              _hover={{ bg: "#000" }}
            >
              <Box as="span" flex="1" textAlign="left" py={3}>
                <Text fontSize={[14, 14, 18]} fontWeight={"BOLD"}>
                  ¿Qué es Chiletiket?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={6}>
              <Text fontSize={[12,12,14]}>
                Con ChileTiket, puedes vender entradas para conciertos,
                festivales, obras de teatro, eventos deportivos y mucho más.
                Nuestro sistema de compra es seguro y fácil de usar, así que
                puedes vender tus boletos en simples pasos.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor="#262626"
            _expanded={{ borderRadius: "0" }}
            borderRadius="40px"
            mb={6}
          >
            <AccordionButton
              rounded="full"
              bgColor="#000"
              border="2px solid #23FEDB"
              color="#23FEDB"
              _expanded={{ bg: "#23FEDB", color: "black" }}
              _hover={{ bg: "#000" }}
            >
              <Box as="span" flex="1" textAlign="left" py={3}>
                <Text fontSize={[14, 14, 18]} fontWeight={"BOLD"}>
                  ¿Cómo compro un ticket?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={6}>
              <Text fontSize={[12,12,14]}>
                Para comprar tus tickets a eventos es súper simple. Primero
                debes tener una cuenta en nuestra página web, luego escoges el
                evento al que quieres ir, seleccionas el tipo de ticket que
                deseas, la cantidad y pulsa el botón COMPRAR. Luego escoges el
                medio de pago y sigues las instrucciones de compra completando
                los datos de tu tarjeta.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor="#262626"
            _expanded={{ borderRadius: "0" }}
            borderRadius="40px"
            mb={6}
          >
            <AccordionButton
              rounded="full"
              bgColor="#000"
              border="2px solid #23FEDB"
              color="#23FEDB"
              _expanded={{ bg: "#23FEDB", color: "black" }}
              _hover={{ bg: "#000" }}
            >
              <Box as="span" flex="1" textAlign="left" py={3}>
                <Text fontSize={[14, 14, 18]} fontWeight={"BOLD"}>
                  ¿Cómo pago mis tickets?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={6}>
              <Text fontSize={[12,12,14]}>
                ¡Súper fácil! Una vez que hayas seleccionado tus entradas,
                tienes que elegir el medio de pago con el cual pagaras tus
                tickets.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor="#262626"
            _expanded={{ borderRadius: "0" }}
            borderRadius="40px"
            mb={6}
          >
            <AccordionButton
              rounded="full"
              bgColor="#000"
              border="2px solid #23FEDB"
              color="#23FEDB"
              _expanded={{ bg: "#23FEDB", color: "black" }}
              _hover={{ bg: "#000" }}
            >
              <Box as="span" flex="1" textAlign="left" py={3}>
                <Text fontSize={[14, 14, 18]} fontWeight={"BOLD"}>
                  ¿Cómo recibo mis tickets?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={6}>
              <Text fontSize={[12,12,14]}>
                Una vez que tu compra haya sido validada por el medio de pago
                que elegiste y por Chiletiket, las entradas estarán disponibles
                en tu cuenta de Chiletiket y también te llegarán al correo
                electrónico en formato de ticket.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor="#262626"
            _expanded={{ borderRadius: "0" }}
            borderRadius="40px"
            mb={6}
          >
            <AccordionButton
              rounded="full"
              bgColor="#000"
              border="2px solid #23FEDB"
              color="#23FEDB"
              _expanded={{ bg: "#23FEDB", color: "black" }}
              _hover={{ bg: "#000" }}
            >
              <Box as="span" flex="1" textAlign="left" py={3}>
                <Text fontSize={[14, 14, 18]} fontWeight={"BOLD"}>
                  ¿Cómo presento mis tickets?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={6}>
              <Text fontSize={[12,12,14]}>
                Tienes que mostrar las entradas en tu Teléfono cuando llegues al
                evento para que las puedan escanear y validar. Solo el personal
                autorizado de la producción del evento puede hacer eso.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bgColor="#262626"
            _expanded={{ borderRadius: "0" }}
            borderRadius="40px"
            mb={6}
          >
            <AccordionButton
              rounded="full"
              bgColor="#000"
              border="2px solid #23FEDB"
              color="#23FEDB"
              _expanded={{ bg: "#23FEDB", color: "black" }}
              _hover={{ bg: "#000" }}
            >
              <Box as="span" flex="1" textAlign="left" py={3}>
                <Text fontSize={[14, 14, 18]} fontWeight={"BOLD"}>
                  ¿Dónde modifico mis datos?
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel bgColor="#262626" borderBottomRadius="3xl" p={6}>
              <Text fontSize={[12,12,14]}>
                Para modificar tus datos, ver entradas compradas, etc., tienes
                que ingresar a Tu Cuenta y ahí encontrarás:
              </Text>
              <Stack fontSize={[12,12,14]} mt={5}>
                <li>Mis Tickets</li>
                <li>Mi perfil (aquí podrás editar tus datos!)</li>
                <li>Mis Eventos</li>
                <li>Cambiar Clave</li>
                <li>Crear Evento</li>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </Stack>
  );
};
