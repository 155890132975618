const conf = {
  mapsApi: process.env.REACT_APP_MAPS_API_KEY,
  appUrl: process.env.REACT_APP_URL,
  photosUrl: process.env.REACT_APP_PHOTOS_URL,
  passToken: process.env.REACT_APP_PASS_TOKEN,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  environment: process.env.NODE_ENV,
  rumApplicationId: process.env.REACT_APP_RUM_APP_ID,
  rumClientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN,
  rumEnv: process.env.REACT_APP_RUM_ENV ?? "test",
  isLocalhost: process.env.REACT_APP_IS_LOCALHOST === "true",
  seatsKey: process.env.REACT_APP_SEATS_KEY,
  recaptchaKey: process.env.REACT_APP_CAPTCHA_KEY,
  baseUrl: process.env.REACT_APP_BASE_URL,
  queueURL: process.env.REACT_APP_QUEUE_URL,
};

export const {
  mapsApi,
  appUrl,
  googleClientId,
  passToken,
  environment,
  rumApplicationId,
  rumClientToken,
  rumEnv,
  isLocalhost,
  seatsKey,
  photosUrl,
  recaptchaKey,
  queueURL,
  baseUrl
} = conf;
