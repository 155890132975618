import { Stack, Text, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { QrIcon } from "../../../../components/Icons/QrIcon";
import QRCode from 'qrcode';
import { useTicketsSaleService } from '../../../../services/ticketsSale/ticketsSaleService';
import { TicketDTO, TicketByShoppingSessionDTO } from '../../../../services/events/dto';
import { useShoppingSessionService } from "../../../../services/shoppingSession/shoppingSessionService";
import {AxiosContext} from '../../../../providers/axiosContext';
import { useContext } from "react";
import { useParams } from 'react-router-dom';

interface PaymentStatusProps{
    status?: "success" | "failure";
}

const PaymentStatus = ({status = "success"}: PaymentStatusProps) => {
    const [sessionId, setShoppingSessionId] = useState<string>();
    const axiosContext = useContext(AxiosContext);
    const shoppingSessionService = useShoppingSessionService();
    const [qr, setQr] = useState<any>();
    const [tickets, setTickets] = useState<string[]>([]); // Arreglo de IDs de tickets
    const [ticketData, setTicketData] = useState<TicketDTO[]>([]); // Arreglo de detalles de los tickets
    const [loading, setLoading] = useState<boolean>(true);
    const ticketsSaleService = useTicketsSaleService();
    

    const generateQr = async () => {
        const qrImageUrl = await QRCode.toDataURL(`https://chileticket.com/fastPass`);
        setQr(qrImageUrl);
    };

    const fetchTicketDetails = async (ticketIds: string[]) => {
        console.log("Fetching ticket details for IDs:", ticketIds);
        try {
            const res = await Promise.all(ticketIds.map(ticketId => axiosContext.publicAxios.get(`/tickets/ticket/fastpass/${ticketId}`)));
            console.log("Ticket data received:", res);
            setTicketData(res.map(response => response.data));
            setLoading(false); // Set loading to false once tickets are fetched
        } catch (error) {
            console.error("Error fetching ticket details:", error);
            setLoading(false); // Also set loading to false on error
        }
    };

    useEffect(() => {
        const storedSessionId = localStorage.getItem('shoppingSessionId');
        if (storedSessionId) {
            setShoppingSessionId(storedSessionId);
            console.log("Using Shopping Session ID from local storage:", storedSessionId);
        }
    }, []);

    useEffect(() => {
        if (status === "success" && sessionId) {
            console.log("Fetching tickets for session ID:", sessionId);
    
            shoppingSessionService.getTicketByShoppingSession(sessionId)
                .then(response => {
                    console.log('Response:', response);
                    const ticketIds = response.tickets; // Extraemos los tickets del formato esperado
                    console.log('Ticket IDs:', ticketIds);
                    console.log("Formato de ticket IDs:", JSON.stringify(ticketIds));
    
                    // Llamamos a fetchTicketDetails con el array de IDs
                    fetchTicketDetails(ticketIds); 
                })
                .catch(error => {
                    console.error('Error fetching ticket IDs:', error);
                });
        }
    }, [status, sessionId]);

    useEffect(() => {
        if (!ticketData || ticketData.length === 0) {
            const timeoutId = setTimeout(() => {
                window.location.reload();
            }, 10000);
            return () => clearTimeout(timeoutId);
        }
    }, [ticketData]);

    return(
        <Stack alignItems={"center"} justifyContent={"center"}>
            {
                loading || !ticketData || ticketData.length === 0 ? (
                    <Stack 
  alignItems="center" 
  spacing={3} 
  justifyContent="center" 
  minHeight="100vh"
>
  <Spinner size="xl" />
  <Text fontWeight="600" fontSize="24px" textAlign="center">
    Cargando ticket...
  </Text>
  <Text color="muted.400" textAlign="center">
    Esto puede tardar unos segundos, gracias por tu paciencia
  </Text>
</Stack>

                ) : status === "failure" ?
                <>
                    <Text fontWeight={"600"} fontSize={"24px"}>Hubo un error en el pago</Text>
                    <Text paddingLeft={"48px"} textAlign={"center"} paddingRight={"48px"} fontWeight={"400"} fontSize={"16px"}>
                        Vuelve a intentarlo o prueba con otro método de pago
                    </Text>
                </>
                :
                ticketData && ticketData.length > 0 ? (
                    <>
                        <Stack alignItems="center" spacing={3} marginBottom={3}>
                            <Text fontSize="30px" fontWeight="bold">¡Compraste tus tickets!</Text>
                            <Text>Puedes descargarlas también desde tu email</Text>
                            <Text fontSize="20px" color="white">{ticketData[0].meta.user.email}</Text>
                        </Stack>
                        {ticketData.map((ticket, index) => (
                            <Stack key={ticket._id} padding={"16px 32px"} border={"1px solid rgba(170, 169, 169, 1)"} borderRadius={"16px"} marginBottom={"16px"}>
                                <Stack alignItems="start" spacing={1} fontWeight={"600"} fontSize={"25px"}>
                                    <Text>Ticket {index + 1}</Text>
                                    <img src={ticket.qr} alt="QR Code" style={{ width: "277px", height: "277px" }} />
                                </Stack>
                                <Text>Sector: {ticket.meta.name || "No disponible"}</Text>
                                <Text>ID Ticket: {ticket.transactionId|| "No disponible"}</Text>
                                {ticket?.meta?.user?.profile?.billingData?.fullname && (
  <Text>Nombre del Asistente: {ticket.meta.user.profile.billingData.fullname}</Text>
)}
{ticket?.meta?.user?.profile?.billingData?.idNumber && (
  <Text>RUT: {ticket.meta.user.profile.billingData.idNumber === "55555555-5" || ticket.meta.user.profile.billingData.idNumber === "55.555.555-5" ? "EXTRANJERO" : ticket.meta.user.profile.billingData.idNumber}</Text>
)}
                            </Stack>
                        ))}
                    </>
                ) : null
            }
        </Stack>
    )
};

export default PaymentStatus;