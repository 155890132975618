import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { RiAddFill, RiArrowRightUpLine } from "react-icons/ri";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { SearchIcon } from "@chakra-ui/icons";

export interface TableActionProps {
  leftElement?: any;
  filter: any;
  setFilter: (value?: any) => void;
  inputProps: InputProps;
  adding?: {
    addMethod: () => void;
    addLabel: string;
  };
  showSearch: boolean;
}

export const TableActions: React.FC<TableActionProps> = ({
  filter,
  setFilter,
  adding,
  leftElement,
  inputProps,
  showSearch,
  ...props
}) => {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 500);

  return (
    <Stack
      spacing="4"
      direction={{
        base: "column",
        md: "row",
      }}
      justify="space-between"
    >
      <Box w={["full", "full", "full"]} pos="relative" minH={"3vh"}>
        {typeof leftElement === "string" ? (
          <Text pos={"absolute"} top="25%" pl={"1rem"}>
            {leftElement}
          </Text>
        ) : (
          leftElement
        )}
      </Box>
      <HStack
        w={["full", "full", "35%"]}
        display={showSearch ? "flex" : "none"}
      >
        <FormControl id="search">
          <InputGroup
            size="md"
            bgColor={"secondary.main"}
            borderRadius="full"
            borderColor={"secondary.main"}
          >
            <InputLeftElement
              pointerEvents="none"
              color={"muted.300"}
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              borderRadius="full"
              placeholder="Buscar..."
              value={value || ""}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              {...inputProps}
            />
          </InputGroup>
        </FormControl>
      </HStack>
      {adding?.addLabel && adding?.addMethod && (
        <ButtonGroup size="sm" variant="outline">
          <Button
            iconSpacing="0.5"
            leftIcon={<RiAddFill fontSize="1.25em" />}
            onClick={adding.addMethod}
          >
            {adding.addLabel}
          </Button>
          <Button
            iconSpacing="0.5"
            leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
            onClick={() => alert("Esta opción aún no esta disponible!")}
          >
            Exportar Excel
          </Button>
        </ButtonGroup>
      )}
    </Stack>
  );
};
