import axios from 'axios';
import { useContext } from 'react';
import { AxiosContext } from '../../../providers/axiosContext';
import { AuthContext } from '../../../providers/authContext';
import { SearchPlaceDTO } from './dto';

export interface PlacesSearchService {
  fetchResults: (params?: any) => Promise<SearchPlaceDTO>;
}

export const usePlacesSearchService = (): PlacesSearchService => {
  const axiosContext = useContext(AxiosContext);
  const authContext = useContext(AuthContext);
  const fetchResults = async search => {
    //console.log('Params get: ', search);
    const placesUrl = process.env.REACT_APP_URL;
    const res = await axiosContext.authAxios.get(`${placesUrl}/events/places/autocomplete`, {
      headers: {
        Authorization: "Bearer " + authContext.getAccessToken(),
      },
      params: {
        input: search,
      },
    });
    return res.data;
  };

  return { fetchResults };
};
