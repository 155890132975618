import create from "zustand";

interface FastPassState {
  fastPass: {
    id: string;
    haveFastPass: boolean;
  };
  setFastPassStore: (newId: string, newHaveFastPass: boolean) => void;
  cleanFastPassStore: () => void;
}

// Crea el store con el tipo FastPassState
export const useFastPassStore = create<FastPassState>((set) => ({
  fastPass: {
    id: "",
    haveFastPass: false,
  },
  setFastPassStore: (newId, newHaveFastPass) => set((state) => ({
    fastPass: {
      id: newId || state.fastPass.id,
      haveFastPass: newHaveFastPass ?? state.fastPass.haveFastPass,
    },
  })),
  cleanFastPassStore: () =>
    set({
      fastPass: {
        id: "",
        haveFastPass: false,
      },
    }),
}));
