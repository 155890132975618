import {
  ModalContent,
  ModalBody,
  Stack,
  Button,
  FormControl,
  FormErrorMessage,
  Link,
  ModalFooter,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useAuthService } from "../../../services/auth/authService";
import { FormProps } from "../interfaces";
import * as Yup from "yup";
import { useEmailValidator } from "../../../utils/useEmailValidator";
import { EmailInput } from "../../EmailInput";
import { useToaster } from "../../../utils/useToaster";

const ResetPassword: React.FC<FormProps> = ({ setActiveForm }) => {
  const { successToast, errorToast } = useToaster();
  const authService = useAuthService();

  const { validateEmail, validationState } = useEmailValidator();

  const ResetSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email("Correo invalido")
          .required("Debe ingresar un correo")
          .test("validation", "El email ingresado no es válido", validateEmail),
      }),
    [validateEmail]
  );

  const handleSubmit = async (values) => {
    try {
      await authService.forgotPassword(values.email);

      successToast(
        "Correo enviado",
        "Se envio un correo al email con los pasos de recuperacion. Verifica la carpeta spam si no lo encuentras en tu bandeja principal."
      );
    } catch (error) {
      console.error(error);

      errorToast(
        "Ocurrió un error",
        "Ocurrió un error al enviar el correo de recuperacion. Porfavor, inténtalo mas tarde"
      );
    }
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: ResetSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  return (
    <ModalContent
      borderRadius={20}
      backgroundColor={"backgroundColor"}
      borderWidth={2}
      borderColor={"secondary.main"}
      p={7}
    >
      <ModalBody justifyContent={"center"}>
        <FormikProvider value={formik}>
          <Stack spacing={5} textAlign="center">
            <Text fontWeight={"semibold"} fontSize="lg">
              Recupera tu clave
            </Text>
            <Text fontWeight={"normal"} fontSize="md">
              Ingresa tu email y te enviaremos una nueva contraseña para que
              puedas ingresar.
            </Text>
            <Field name={"email"}>
              {({ field, form }) => (
                <FormControl
                  variant="outline"
                  borderRadius={4}
                  id="email"
                  isInvalid={form.errors.email}
                  isRequired
                >
                  <FormLabel>Email</FormLabel>

                  <EmailInput
                    field={field}
                    form={form}
                    inputProps={{
                      borderColor: "white",
                    }}
                    validationState={validationState}
                  />

                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
        </FormikProvider>
      </ModalBody>
      <ModalFooter justifyContent={"center"}>
        <Stack direction={"column"} spacing={5}>
          <Button
            borderRadius={50}
            colorScheme="primary"
            disabled={formik.isSubmitting || formik.isValidating}
            isLoading={formik.isSubmitting || formik.isValidating}
            onClick={() => formik.submitForm()}
          >
            Recuperar
          </Button>
          <Text textAlign={"center"} fontWeight="normal" fontSize={"md"}>
            ¿Recordaste la clave?{" "}
            <Link
              style={{ color: "primary.main" }}
              onClick={() => {
                formik.resetForm();
                setActiveForm("login");
              }}
            >
              Inicia Sesión
            </Link>
          </Text>
        </Stack>
      </ModalFooter>
    </ModalContent>
  );
};

export default ResetPassword;
