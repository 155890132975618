import { Stack, Button, Text, Icon, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { ShoppingSessionDTO } from "../../../../services/shoppingSession/dto";
import { useTicketsSaleService } from "../../../../services/ticketsSale/ticketsSaleService";
import { OfficeSaleOutletContext } from "../interfaces";
import { SaleStep } from "./step";
import { useToaster } from "../../../../utils/useToaster";

const ResumeView = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { successToast, errorToast } = useToaster();

  const ticketSaleService = useTicketsSaleService();
  const { onFinish, DownloadSalePDF } =
    useOutletContext<OfficeSaleOutletContext>();
  const currentShoppingSession: ShoppingSessionDTO =
    ticketSaleService.store.formData?.createdShoppingSession ?? null;

  const transactionId: string =
    ticketSaleService.store.formData?.transactionId ?? null;

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const bufferedFile = await DownloadSalePDF();

      const href = URL.createObjectURL(new Blob([bufferedFile]));

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        "ChileTiket-" + new Date().toISOString() + ".pdf"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL

      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }

      URL.revokeObjectURL(href);
      successToast(
        "Descarga finalizada",
        "El ticket se descargo en su carpeta predeterminada."
      );
    } catch (error) {
      console.error(error);
      errorToast(
        "Ocurrió un error en la descarga",
        "No se pudo descargar. Vuelva a intentar más tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <SaleStep>
      <Stack spacing={65} w="100%">
        <Stack textAlign={"center"} spacing={3} mt="2rem">
          <Heading fontSize={"3xl"}>Venta exitosa!</Heading>
          <Stack maxW="inherit" alignSelf={"center"}>
            <Text fontSize={"md"} textAlign="left">
              ID de la transacción: <strong>{transactionId}</strong>
            </Text>
            <Text fontSize={"md"} textAlign="left">
              Monto cobrado:{" "}
              <strong>$ {currentShoppingSession?.price.finalPrice}</strong>
            </Text>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justify="center"
          align="center"
          color={"muted.main"}
        >
          <Icon />
          <Text fontSize={"sm"}>
            El comprador ya puede visualizar los tickets adquiridos desde su
            email.
          </Text>
        </Stack>
        <Stack spacing={4} align="center" justify={"center"}>
          <Button
            colorScheme={"primary"}
            rounded="full"
            maxW={"fit-content"}
            isLoading={isLoading}
            onClick={() => {
              handleDownload();
            }}
          >
            IMPRIMIR TICKETS
          </Button>
          <Button
            colorScheme={"primary"}
            variant="ghost"
            rounded="full"
            maxW={"fit-content"}
            onClick={() => {
              ticketSaleService.store.reset();
              onFinish();
            }}
          >
            Volver a VENTAS
          </Button>
        </Stack>
      </Stack>
    </SaleStep>
  );
};

export default ResumeView;
