import { extendTheme } from "@chakra-ui/react";
import { breakpoints } from "./foundation/breakpoints";
import { fonts } from "./foundation/fonts";
import { globalStyles } from "./styles";
import { CalendarStyles } from "./components/Calendar";
import { FloatinLabelInputVariant } from "./components/FloatinLabelInput";
import { CardComponent } from "./additions/card/Card";
import { calendarTheme } from "../components/CalendarPicker/themedCalendar";
import { ButtonComponent } from "./components/Button";

export const appTheme = extendTheme(
  calendarTheme,
  {
    config: {
      initialColorMode: "dark",
      useSystemColorMode: false,
    },
  },
  CalendarStyles,
  FloatinLabelInputVariant,
  breakpoints, // Breakpoints
  globalStyles, // Global Styles
  fonts, // Global Fonts
  CardComponent, // Card component
  ButtonComponent
);

export const adminTheme = extendTheme(
  {
    config: {
      initialColorMode: "dark",
      useSystemColorMode: false,
    },
  },
  { breakpoints } // Breakpoints
);
