import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { DeleteIcon } from "../../../../../components/Icons/DeleteIcon";

type Props = {
  onSubmit: (amount: number) => Promise<void>;
  limit: number;
};

const CancelReservationsModal = (props: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={!loading ? onClose : null} isCentered>
        <ModalOverlay />
        <ModalContent
          bgColor={"secondary.main"}
          borderRadius={20}
          px={2}
          py={5}
          w={"max-content"}
          color={"secondary.contrast"}
        >
          {/* <ModalHeader>Cancelar reservas</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <Text>Cuantas reservas desea cancelar?</Text>
              <NumberInput
                onChange={(string, number) => {
                  if (number) {
                    setAmount(number);
                  } else {
                    setAmount(0);
                  }
                }}
                defaultValue={1}
                clampValueOnBlur={false}
                max={props.limit}
                min={1}
              >
                <NumberInputField />
              </NumberInput>
              {props.limit < amount && (
                <Text
                  color={"red.300"}
                >{`Solo tienes ${props.limit} reservas`}</Text>
              )}
              {amount < 1 && (
                <Text
                  color={"red.300"}
                >{`Debes cancelar al menos una reserva`}</Text>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent={"center"}>
            <Button
              colorScheme="red"
              mr={3}
              disabled={props.limit < amount || amount < 1 || loading}
              onClick={async () => {
                setLoading(true);
                await props.onSubmit(amount);
                setLoading(false);
                onClose();
              }}
              isLoading={loading}
            >
              Cancelar reservas
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <IconButton
        aria-label="Call Segun"
        size="md"
        variant={"ghost"}
        rounded={"full"}
        alignSelf="self-start"
        colorScheme={"primary"}
        onClick={onOpen}
        icon={<DeleteIcon />}
      />
    </Box>
  );
};

export default CancelReservationsModal;
