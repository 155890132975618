import { useMemo } from "react";
import { useEmailValidator } from "../../../utils/useEmailValidator";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  EventDataFormFields,
  FunctionFormFields,
  ImageFormFields,
  OrganizerFormFields,
  PaymentMethodFields,
  TicketFormFields,
  useEventFormStore,
} from "../../../services/createEvent/createEventStore";
import { validateRut } from "../../../utils/rutFormatter";
import { useColors } from "../../../utils/useColors";

export const useEventDataFormik = () => {
  const { validateEmail, validationState } = useEmailValidator();

  const EventDataFormSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required("Debe ingresar un Nombre")
          .max(50, "El nombre es muy largo"),
        category: Yup.string().required("Debe elegir una categoría"),
        type: Yup.string().required("Debe elegir un tipo"),
        restriction: Yup.string().required("Debe elegir una clasificación"),
        placeName: Yup.string()
          .required("Debe ingresar un lugar")
          .max(50, "El nombre de la ubicacion es muy largo"),
        place: Yup.object().required("Debe elegir un lugar"),
        description: Yup.string()
          .required("Debe ingresar una descripción")
          .max(2000, "Descripción demasiado larga"),
        seatsMail: Yup.string()
          .max(150, "El mail es muy largo")
          .test("validation", "El email ingresado no es válido", validateEmail),
        seatsPhone: Yup.string().max(20, "El teléfono es muy largo"),
      }),
    [validateEmail]
  );

  const { formData } = useEventFormStore();

  const formik = useFormik<EventDataFormFields>({
    initialValues: {
      name: formData?.eventData?.name ?? "",
      category: formData?.eventData?.category ?? undefined,
      type: formData?.eventData?.type ?? "",
      restriction: formData?.eventData?.restriction ?? "ATP",
      placeName: formData?.eventData?.placeName ?? "",
      place: formData?.eventData?.place ?? undefined,
      description: formData?.eventData?.description ?? "",
      hasSeats: formData?.eventData?.hasSeats ?? false,
      hasQueue: formData?.eventData?.hasQueue ?? false,
      seatsMail:
        formData?.eventData?.seatsMail ?? formData?.organizer?.mail ?? "",
      seatsPhone:
        formData?.eventData?.seatsPhone ?? formData?.organizer?.phone ?? "",
    },
    validationSchema: EventDataFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {},
  });

  return { formik, validationState };
};

export const useOrganizerFormik = () => {
  const { formData } = useEventFormStore();
  const { validateEmail, validationState } = useEmailValidator();

  const OrganizerFormSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required("Debe ingresar un nombre")
          .max(50, "El nombre es muy largo"),
        mail: Yup.string()
          .required("Debe ingresar un mail ")
          .max(150, "El mail es muy largo")
          .test("validation", "El email ingresado no es válido", validateEmail),
        phone: Yup.string()
          .required("Debe ingresar un teléfono")
          .max(20, "El teléfono es muy largo"),
        bank: Yup.string().required("Debe seleccionar un banco"),
        accountType: Yup.string().required(
          "Debe seleccionar un tipo de cuenta"
        ),
        accountNumber: Yup.string()
          .required("Debe ingresar un número de cuenta")
          .matches(/^[a-zA-Z0-9-.]*$/, "Número de cuenta inválido")
          .max(50, "Número demasiado largo")

          .when(["accountType"], (accountType, schema) =>
            schema.test(
              "format",
              "El RUT ingresado es inválido",
              function (rut) {
                return accountType === "rut" ? validateRut(rut) : true;
              }
            )
          ),
        exemptionPDF: Yup.object().shape({
          url: Yup.string(),
          _id: Yup.string(),
        }),
      }),
    [validateEmail]
  );

  const formik = useFormik<OrganizerFormFields>({
    initialValues: {
      name: formData?.organizer?.name ?? "",
      mail: formData?.organizer?.mail ?? "",
      phone: formData?.organizer?.phone ?? "",
      bank: formData?.organizer?.bank ?? "",
      accountType: formData?.organizer?.accountType ?? "",
      accountNumber: formData?.organizer?.accountNumber ?? "",
      exemptionPDF: formData?.organizer?.exemptionPDF,
    },
    validationSchema: OrganizerFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {},
  });

  return { formik, validationState };
};

export const useTicketsFormik = () => {
  const TicketFormSchema = Yup.object().shape({
    tickets: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Debe ingresar un Nombre")
          .max(50, "Nombre muy largo"),
        description: Yup.string().max(100, "Descripción muy larga"),
        // color: Yup.string(),
        amount: Yup.number()
          .required("Debe ingresar una cantidad")
          .moreThan(0, "Cantidad inválida"),
        maxQuantityPerUser: Yup.number().moreThan(0, "Cantidad inválida"),
        minimumQuantityPerUser: Yup.number()
          .moreThan(0, "Cantidad inválida")
          .test(
            "LessThanMax",
            "No puede ser mayor al máximo",
            function (value, context) {
              return (
                !value ||
                !context.parent.maxQuantityPerUser ||
                value <= context.parent.maxQuantityPerUser
              );
            }
          ),
        price: Yup.string().required("Debe ingresar un precio"),
        openingDate: Yup.object().shape({
          value: Yup.string().required("Debe ingresar una fecha"),
          label: Yup.string().required("Debe ingresar una fecha"),
        }),
        closingDate: Yup.object().shape({
          value: Yup.string().required("Debe ingresar una fecha"),
          label: Yup.string().required("Debe ingresar una fecha"),
        }),
        openingHour: Yup.string().required("Debe ingresar una hora"),
        closingHour: Yup.string().required("Debe ingresar una hora"),
      })
    ),
  });

  const { formData } = useEventFormStore();

  const colors = useColors();

  const formik = useFormik<TicketFormFields>({
    initialValues: formData?.tickets ?? {
      tickets: [
        {
          name: "",
          description: "",
          amount: undefined,
          color: colors.primary.main,
          price: "",
          openingDate: { label: "", value: "" },
          closingDate: { label: "", value: "" },
          openingHour: "00:00",
          closingHour: "00:00",
          maxQuantityPerUser: undefined,
          minimumQuantityPerUser: undefined,
        },
      ],
    },
    validationSchema: TicketFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: undefined,
  });

  return { formik };
};

export const useFunctionsFormik = () => {
  const FunctionFormSchema = Yup.object().shape({
    functions: Yup.array().of(
      Yup.object().shape({
        openingDate: Yup.object().shape({
          value: Yup.string().required("Debe ingresar una fecha"),
          label: Yup.string().required("Debe ingresar una fecha"),
        }),
        closingDate: Yup.object().shape({
          value: Yup.string().required("Debe ingresar una fecha"),
          label: Yup.string().required("Debe ingresar una fecha"),
        }),
        openingHour: Yup.string().required("Debe ingresar una hora"),
        closingHour: Yup.string().required("Debe ingresar una hora"),
      })
    ),
  });

  const { formData } = useEventFormStore();

  const formik = useFormik<FunctionFormFields>({
    initialValues: formData?.functions ?? {
      functions: [
        {
          openingDate: { label: "", value: "" },
          closingDate: { label: "", value: "" },
          openingHour: "00:00",
          closingHour: "00:00",
        },
      ],
    },
    validationSchema: FunctionFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {},
  });

  return { formik };
};

export const useImagesFormik = () => {
  const ImagesFormSchema = Yup.object().shape({
    card: Yup.object().shape({
      url: Yup.string().required("Esta imagen es obligatoria"),
      _id: Yup.string(),
    }),
    detail: Yup.object().shape({
      url: Yup.string().required("Esta imagen es obligatoria"),
      _id: Yup.string(),
    }),
    banner: Yup.object().shape({
      url: Yup.string(),
      _id: Yup.string(),
    }),
    bannerMobile: Yup.object().shape({
      url: Yup.string(),
      _id: Yup.string(),
    }),
  });

  const { formData } = useEventFormStore();

  const formik = useFormik<ImageFormFields>({
    initialValues: formData?.images ?? {
      card: formData?.images?.card,
      detail: formData?.images?.detail,
      banner: formData?.images?.banner,
      bannerMobile: formData?.images?.bannerMobile,
      place: formData?.images?.place,
    },
    validationSchema: ImagesFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {},
  });

  return { formik };
};

export const usePaymentsFormik = () => {
  const paymentSchema = Yup.object().shape({
    methods: Yup.array()
      .of(Yup.string())
      .min(1, "Se debe elegir al menos 1 método de pago"),
  });

  const { formData } = useEventFormStore();
  const formik = useFormik<PaymentMethodFields>({
    initialValues: formData?.paymentMethods ?? {
      methods: ["MP", "PH", "FL"],
    },
    validationSchema: paymentSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {},
  });

  return { formik };
};
