import create from "zustand";
import { ShoppingCartDTO } from "../shoppingSession/dto";

export interface SaleClientFields {
  firstname: string;
  lastname: string;
  idNumber?: string;
  phone?: string;
  fullname?: string;
  city?: string;
  district?: string;
  address?: string;
  email?: string;
  clientDataBilling?: string;
  billingData?: {
    idNumber?: string;
    fullname?: string;
    address?: string;
    district?: string;
    city?: string;
    email?: string;
  };
}

interface TicketSaleStoreData {
  profile: SaleClientFields;
  shoppingSessionData: ShoppingCartDTO;
  createdShoppingSession: any;
  transactionId: string;
}

export type TicketSaleStoreProps = {
  formData?: TicketSaleStoreData;
  setFormData: (data: Partial<TicketSaleStoreData>) => void;
  reset: () => void;
};

export const useTicketsSaleStore = create<TicketSaleStoreProps>()((set) => ({
  formData: undefined,
  setFormData: (data) =>
    set((store) => ({ formData: { ...store.formData, ...data } })),
  reset: () => set(() => ({ formData: undefined })),
}));
