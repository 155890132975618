import React, { useState, useEffect } from "react";
import { EventListProps } from "./interfaces";
import { useEventListController } from "./event-list.controller";
import { Box, Button, Link, Stack, Text } from "@chakra-ui/react";
import EventListCard from "./components/eventListCard";
import { useNavigate } from "react-router-dom";
import ModalFastPass from "./components/ModalFastPass";
import { useProducerService } from "../../../services/producer/producerService";

export const EventList: React.FC<EventListProps> = ({
  useController = useEventListController,
}) => {
  const controller = useController();

  const [openFastPass, setOpenFastPass] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [step, setStep] = useState("qr");
  const [haveFastPass, setHaveFastPass] = useState(false);
  const [urlFastPass, setUrlFastPass] = useState<string | null>(null);
  const producerService = useProducerService();

  useEffect(() => {
    const fetchProducerConfig = async () => {
      const config = await producerService.getProducerConfig();
      setUrlFastPass(config?.urlFastPass || null);
    };
    fetchProducerConfig();
  }, [producerService]);

  const onCloseModal = () => {
    setOpenFastPass(false);
    setStep("options");
  };

  const onPressFastPass = () => {
    setSelectedOption("");
    setOpenFastPass(true);
  };

  const navigate = useNavigate();
  console.log("haveFastPass", haveFastPass)
  console.log("controller", controller.userEventList)

  return (
    <>
      <ModalFastPass
        setHaveFastPass={setHaveFastPass}
        onCloseModal={onCloseModal}
        openFastPass={openFastPass}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        userEventList={controller.userEventList}
        step={"qr"}
      />
      {controller.userEventList.length > 0 ? (
        <Stack
          px={{ sm: "2%", md: "10%" }}
          mx="4%"
          py="2%"
          direction="column"
          spacing={5}
          overflow={"auto"}
        >
          {urlFastPass && (
            <Button size={"sm"}
              maxW={"sm"}
              borderRadius={"full"}
              p={5}
              pr={8}
              onClick={onPressFastPass}
              pl={8}
              colorScheme={"primary"} alignSelf={"flex-end"}>
              Utilizar Fast Pass
            </Button>
          )}
          {controller.userEventList?.map((event, index) => (
            <Box key={"event-item-" + index}>
              <EventListCard
                event={event}
                navigate={navigate}
                haveFastPass={haveFastPass}
                selectedOption={selectedOption}
                loading={controller.loadingList}
                onDuplicatePress={async () =>
                  await controller.onEventDuplicate(event)
                }
              />
            </Box>
          ))}
        </Stack>
      ) : (
        <Box
          px={{ sm: "2%", md: "10%" }}
          mx={"auto"}
          w={["96%", "96%", "max-content"]}
          my={{ sm: 5, md: 20 }}
          py={{ sm: "2%", md: "8%" }}
          borderColor={"secondary.main"}
          borderWidth={"3px"}
          borderRadius={"xl"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          p={5}
        >
          <Text textAlign={"center"} fontSize={36} fontWeight={700}>
            Ups!
          </Text>
          <Text textAlign={"center"} fontSize={16} fontWeight={400}>
            No administras ningún evento.
          </Text>
          <Text
            noOfLines={[0, 0, 1]}
            textAlign={"center"}
            fontSize={16}
            fontWeight={400}
          >
            Una vez que administres uno, podrás verlo y darle seguimiento aquí
            mismo.
          </Text>
          <Text textAlign={"center"} fontSize={16} fontWeight={400}>
            ¡Anímate a crear tu primer evento y compartirlo con la comunidad!
          </Text>
          <Link textAlign={"center"} color={"primary.main"} href="/create-event">
            Crear evento
          </Link>
        </Box>
      )}
    </>
  );
};
