import { Box, ButtonProps, HTMLChakraProps } from "@chakra-ui/react";
import React from "react";

type SaleStepProps = {
  children?: React.ReactNode;
  buttonProps?: ButtonProps;
  label?: string;
  cardProps?: HTMLChakraProps<"div">;
  containerProps?: HTMLChakraProps<"div">;
};

export const SaleStep = (props: SaleStepProps) => {
  return (
    <Box
      display={"flex"}
      flexDir={["column", "column", "row"]}
      my={5}
      {...props.containerProps}
    >
      <Box
        flexGrow={1}
        py={7}
        px={20}
        mx={20}
        borderRadius={"xl"}
        bgColor="secondary.main"
        color={"secondary.contrast"}
        {...props.cardProps}
      >
        {props.children}
      </Box>
    </Box>
  );
};
