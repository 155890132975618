import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { cssTransition, Slide, ToastContainer } from "react-toastify";

// import "react-toastify/dist/ReactToastify.css";
// // minified version is also included
import "react-toastify/dist/ReactToastify.min.css";

import "@fontsource/inter";
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";

import "@fontsource/roboto";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/400.css";

import { appTheme } from "./theme/theme";
import { initTracking } from "./utils/initTracking";
import { RoutesObject } from "./config/routes";
import { useRumUserSetup } from "./utils/useRumSession";
import "./theme/toastify-custom.css";
import { useEffect, useState } from "react";
import { useProducerService } from "./services/producer/producerService";
import { useProducerAsyncStore } from "./services/producer/producerStore";
import { colors } from "./theme/colors";
import { useProducerFastPassAsyncStore } from "./services/producer/producerFastPass";

// RUM Tracking initialization method
initTracking();

const router = createBrowserRouter(RoutesObject);

const CustomSlide = cssTransition({
  enter: "slide-in",
  exit: "slide-out",
});

export const App = () => {
  useRumUserSetup();

  const producerService = useProducerService();
  const [theme, setTheme] = useState(appTheme);

  const { data, loading, execute } = useProducerAsyncStore();
  const { data:dataFastPass, loading:loadingFastPass, execute:executeFastPass } = useProducerFastPassAsyncStore();

  useEffect(() => {
    if (data) {
      setTheme(
        extendTheme(appTheme, {
          colors: {
            ...colors,
            primary: data.colors.primary,
            secondary: data.colors.secondary,
            backgroundColor: data.colors.backgroundColor,
            backgroundContrast: data.colors.backgroundContrast,
          },
        })
      );

      //<div  data-connection-id="6380a67f00c882677c8704e8"></div>
      // <script defer="defer" src="https://omuni-prod.denwaip.com/api/v2/channels/webchat/index.js"></script>
      if (data.omuniId) {
        var omuniTag = document.createElement("div");
        omuniTag.id = "OmuniWebChat";
        omuniTag.dataset.connectionId = data.omuniId;
        omuniTag.dataset.bottom = "40";
        omuniTag.dataset.right = "40";

        var child = document.createElement("div");
        omuniTag.appendChild(child);
        console.log(omuniTag);
        document.body.append(omuniTag);

        var script = document.createElement("script");
        script.defer = true;
        script.src =
          "https://omuni-prod.denwaip.com/api/v2/channels/webchat/index.js";
        document.head.append(script);
      }
    }
  }, [data]);

  useEffect(() => {
    execute(() => producerService.getProducerConfig());
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ToastContainer
        toastStyle={{
          backgroundColor: theme?.colors.secondary.main,
          color: theme?.colors.secondary.contrast,
          borderRadius: 12,
          maxWidth: "97%",
          margin: "auto",
          marginTop: "0.75rem",
        }}
        progressStyle={{
          // backgroundColor: 'primary.main',
          height: "0.25rem",
        }}
        position="top-right"
        limit={3}
        autoClose={5000}
        transition={CustomSlide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};
