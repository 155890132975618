import { Navigate } from "react-router-dom";
import ClientForm from "./components/ClientForm";
import ResumeView from "./components/Resume";
import TicketsSelection from "./components/TicketsSelection";
import Transaction from "./components/Transaction";

export const saleStepperRoutes = [
  {
    path: "",
    element: <Navigate to={"client-data"} />,
  },
  { path: "client-data", element: <ClientForm /> },
  {
    path: "tickets",
    element: <TicketsSelection />,
  },
  { path: "transaction", element: <Transaction /> },
  { path: "resume", element: <ResumeView /> },
];
