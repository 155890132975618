import {
  Box,
  Button,
  Circle,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Field, FormikProvider, useFormik } from "formik";

import * as Yup from "yup";
import { seatsKey } from "../../../../../config/envConf";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { useEffect, useMemo, useState } from "react";
import { TicketVariantDTO } from "../../../../../services/events/dto";
import { priceFormatter } from "../../../../../utils/priceFormat";
import { ticketLabelFormatter } from "../../../../../utils/stringFormatters";
import { mapToCategoriesSummary } from "../../../../Landing/EventDetail/DetailLayouts/SeatsLayout";
import { useAsync } from "../../../../../utils/useAsync";
import { useReservationsService } from "../../../../../services/Admin/reservations/reservations.service";
import { useInvitationsService } from "../../../../../services/Admin/invitations/invitations.service";
import { DeleteIcon } from "../../../../../components/Icons/DeleteIcon";
import { useEmailValidator } from "../../../../../utils/useEmailValidator";
import { EmailInput } from "../../../../../components/EmailInput";
import { useToaster } from "../../../../../utils/useToaster";
import { useColors } from "../../../../../utils/useColors";

type Props = {
  variants: TicketVariantDTO[];
  variantsLoading: boolean;
  variantsError: any;
  variantsExecute: () => void;
  eventFunctionChartKey: string;
  activeFunctionId: string;
};

type SingleInvitationSeatsFields = {
  name: string;
  mail: string;
};

export const SingleInvitationSeats = (props: Props) => {
  const { validateEmail, validationState } = useEmailValidator();

  const SingleInvitationSeatsSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required("Debe ingresar un Nombre")
          .max(50, "El nombre es muy largo"),
        mail: Yup.string()
          .max(150, "El mail es muy largo")
          .required("Debe ingresar un mail")
          .test("validation", "El email ingresado no es válido", validateEmail),
      }),
    [validateEmail]
  );
  const mappedVariants = useMemo(
    () =>
      props.variants.map((variant) => ({
        category: variant.name,
        price: variant.price,
      })),
    [props.variants]
  );

  const { successToast, errorToast, warningToast } = useToaster();
  const invitationsService = useInvitationsService();
  const [invitationLoading, setInvitationLoading] = useState(false);
  const handleSubmit = async (values: SingleInvitationSeatsFields) => {
    try {
      setInvitationLoading(true);
      await invitationsService.createSeatsInvitation(props.activeFunctionId, {
        name: values.name,
        mail: values.mail,
        eventFunctionChartKey: props.eventFunctionChartKey,
        tickets: selectedSeats.map((seat) => ({
          ticketVariantId: props.variants.find(
            (variant) => variant.name === seat.category.label
          )._id,
          id: seat.id,
          objectType: seat.objectType,
          labels: { ...seat.labels, displayName: seat.labels.displayedLabel },
          numSelected: seat?.numSelected,
        })),
      });

      successToast(
        "Cortesía enviada con éxito",
        `Se envió la cortesía al mail del usuario deseado`
      );
    } catch {
      errorToast("Ocurrió un error", `Lo sentimos. Inténtalo mas tarde`);
    } finally {
      chartInstance.clearSelection();
      reservationsExecute(props.activeFunctionId);
      props.variantsExecute();
      setInvitationLoading(false);
    }
  };

  const formik = useFormik<SingleInvitationSeatsFields>({
    initialValues: {
      name: "",
      mail: "",
    },
    validationSchema: SingleInvitationSeatsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const [chartInstance, setChartInstance] = useState(null);

  const [selectedSeats, setSelectedSeats] = useState([]);

  const mappedSelectedSeats = useMemo(
    () => mapToCategoriesSummary(selectedSeats, mappedVariants),
    [selectedSeats, mappedVariants]
  );
  const reservationsService = useReservationsService();

  const [
    reservations,
    reservationsError,
    reservationsLoading,
    reservationsExecute,
  ] = useAsync(reservationsService.getSeatsReservations, {
    defaultValue: { tickets: [] },
  });

  const reservedSeats = useMemo(
    () => reservations.tickets.map((ticket) => ticket.id),
    [reservations]
  );

  useEffect(() => {
    if (props.activeFunctionId) {
      reservationsExecute(props.activeFunctionId);
    }
  }, [props.activeFunctionId]);

  const colors = useColors();
  return (
    <FormikProvider value={formik}>
      <Stack spacing={[7, 9]} p={[5, 7]}>
        <Stack spacing={5}>
          <Text>Datos del receptor:</Text>
          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
            columnGap={20}
            rowGap={10}
          >
            <GridItem rowSpan={1} colSpan={1}>
              <Field name={"name"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    colorScheme={"secondary"}
                    borderRadius={4}
                    id="name"
                    isRequired
                    isInvalid={form.errors.name}
                  >
                    <Input {...field} placeholder=" " maxLength={50} />
                    <FormLabel>Nombre</FormLabel>
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <Field name={"mail"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    colorScheme={"secondary"}
                    borderRadius={4}
                    id="mail"
                    isRequired
                    isInvalid={form.errors.mail}
                  >
                    <EmailInput
                      field={field}
                      form={form}
                      validationState={validationState}
                      label={"Mail"}
                      inputProps={{
                        maxLength: 150,
                      }}
                    />

                    <FormErrorMessage>{form.errors.mail}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
          </Grid>
        </Stack>
        <Stack spacing={3}>
          <Text>Tickets a enviar:</Text>
          <Text fontSize={14}>
            Utiliza el mapa para elegir tus asientos. Puedes navegar sobre el y
            hacer zoom sobre los espacios que desees
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Box>
            <SeatsioSeatingChart
              showSectionContents={"onlyAfterZoom"}
              onRenderStarted={(chart) => {
                setChartInstance(chart);
              }}
              extraConfig={{
                reservedSeats: reservations.tickets.map((ticket) => ticket.id),
              }}
              selectableObjects={reservations.tickets.map(
                (ticket) => ticket.id
              )}
              workspaceKey={seatsKey}
              objectIcon={(object, defaultIcon, extraConfig) => {
                if (
                  extraConfig &&
                  extraConfig.reservedSeats &&
                  extraConfig.reservedSeats.includes(object.id)
                  // !defaultIcon
                ) {
                  return "bookmark-o";
                }
              }}
              event={props.eventFunctionChartKey}
              showMinimap={true}
              showFullScreenButton={false}
              stylePreset="bubblegum"
              language={"es"}
              colorScheme={"dark"}
              colors={{
                colorSelected: "hsl(214, 96%, 53%)",
                colorTitle: colors.primary.main,
              }}
              cursorTooltip={{
                showActionHint: false,
                showAvailability: false,
                showCategory: false,
                showLabel: true,
                showPricing: true,
                showUnavailableNotice: true,
                stylizedLabel: true,
              }}
              pricing={mappedVariants}
              priceFormatter={(price) => {
                return "$" + priceFormatter(price as number);
              }}
              onObjectSelected={(obj) => {
                setSelectedSeats((prev) => {
                  var copyPrev = [...prev];
                  if (
                    obj?.objectType === "GeneralAdmissionArea" &&
                    prev.find((items) => items.uuid === obj?.uuid)
                  ) {
                    copyPrev[
                      prev.findIndex((items) => items.uuid === obj?.uuid)
                    ].numSelected = obj.numSelected;
                    return [...copyPrev];
                  } else {
                    return [...prev, obj];
                  }
                });
              }}
              onObjectDeselected={(obj) => {
                if (obj?.status === "reservedByToken") {
                  warningToast(
                    "Asiento deseleccionado",
                    "El asiento " +
                      ticketLabelFormatter(obj) +
                      " se quitó de tus selecciones por que ya fue adquirido por otra persona"
                  );
                }

                setSelectedSeats((prev) => {
                  var copyPrev = [...prev];
                  if (
                    obj?.numSelected > 0 &&
                    prev.find((items) => items.uuid === obj?.uuid)
                  ) {
                    copyPrev[
                      prev.findIndex((items) => items.uuid === obj?.uuid)
                    ].numSelected = obj.numSelected;
                    return [...copyPrev];
                  } else {
                    return prev.filter((element) => element.uuid !== obj.uuid);
                  }
                });
              }}
              region="sa"
            />
          </Box>
          <Box
            w={"full"}
            overflowY={"auto"}
            h="100%"
            sx={{
              "&::-webkit-scrollbar": {
                width: "8px",
                borderRadius: "10px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `secondary.main`,
                borderRadius: "10px",
              },
            }}
          >
            <Text fontSize={16} mb={5} fontWeight="semibold">
              Asientos seleccionados:
            </Text>
            {Object.keys(mappedSelectedSeats).length === 0 ? (
              <Text fontSize={14} color={"muted.600"}>
                Una vez que hayas seleccionados tus asientos, podrás visualizar
                el detalle de los mismos aquí.
              </Text>
            ) : (
              <VStack spacing={1} alignItems="left" flexGrow={1}>
                {Object.keys(mappedSelectedSeats).map((category, index) => {
                  const ticketsArray = mappedSelectedSeats[category];
                  return (
                    <Box key={index}>
                      <Flex
                        justifyContent={"space-between"}
                        borderWidth={1}
                        borderColor="secondary.contrast"
                        py={1}
                        px={2}
                        borderRadius={5}
                        direction={["column", "row"]}
                        alignItems={"center"}
                      >
                        <Stack direction={"row"}>
                          <Circle
                            size={5}
                            backgroundColor={
                              props.variants.find(
                                (cat) => cat.name === category
                              ).color ?? "primary.main"
                            }
                          />
                          <Text fontSize={14}>{category}</Text>
                        </Stack>
                      </Flex>
                      {ticketsArray.map((ticket, index) => {
                        return (
                          <Flex
                            key={index}
                            justifyContent={"space-between"}
                            py={1}
                            px={2}
                            borderRadius={5}
                            direction={["column", "row"]}
                            alignItems={"center"}
                            fontSize={16}
                          >
                            <Text noOfLines={1}>
                              {ticketLabelFormatter(ticket)}
                              {reservedSeats.includes(ticket.id) && (
                                <b> ( Reservado )</b>
                              )}
                            </Text>
                            <IconButton
                              aria-label="Delete"
                              icon={<DeleteIcon />}
                              variant="ghost"
                              colorScheme={"primary"}
                              rounded="full"
                              onClick={() => {
                                ticket.objectType === "Seat"
                                  ? chartInstance.deselectObjects([ticket.uuid])
                                  : ticket.numSelected > 1
                                  ? chartInstance.deselectObjects([
                                      {
                                        id: ticket.id,
                                        amount: 1,
                                      },
                                    ])
                                  : chartInstance.deselectObjects([
                                      { id: ticket.id },
                                    ]);
                              }}
                            />
                          </Flex>
                        );
                      })}
                    </Box>
                  );
                })}
              </VStack>
            )}
          </Box>
        </Stack>
      </Stack>

      <Button
        disabled={
          invitationLoading || selectedSeats.length === 0 || formik.isValidating
        }
        isLoading={invitationLoading || formik.isValidating}
        borderRadius={"full"}
        colorScheme={"primary"}
        textAlign={"center"}
        alignSelf={"center"}
        maxW={"xs"}
        w={"100%"}
        my={5}
        px={10}
        onClick={formik.submitForm}
      >
        INVITAR
      </Button>
    </FormikProvider>
  );
};
