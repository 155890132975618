import { InfoOutlineIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { ActualFileObject } from "filepond";
import { Field, FormikProvider, useFormik } from "formik";
import { useMemo } from "react";
import { FilePond } from "react-filepond";
import * as Yup from "yup";
import { TicketVariantDTO } from "../../../../../services/events/dto";
import { StyledSelectProps } from "../../../../../theme/additions/select/Select";
import { useToaster } from "../../../../../utils/useToaster";

type Props = {
  variants: TicketVariantDTO[];
  variantsLoading: boolean;
  variantsError: any;
  variantsExecute: () => void;
  eventFunctionChartKey: string;
};

type MassiveInvitationsFields = {
  variant: string;
  guestsFile: { url: string; _id: string; file?: ActualFileObject };
};

export const MassiveInvitations = (props: Props) => {
  const mappedVariants = useMemo(
    () =>
      props.variants
        .filter((variant) => variant.available > 0 && !variant.isExpired)
        .map((variant) => ({
          value: variant._id,
          label: variant.name,
          available: variant.available,
        })),
    [props.variants]
  );

  const MassiveInvitationsSchema = useMemo(
    () =>
      Yup.object().shape({
        variant: Yup.string().required("Debe elegir una categoría"),
        guestsFile: Yup.object()
          .required("Debe subir un archivo")
          .shape({
            url: Yup.string().required("Debe subir un archivo"),
            _id: Yup.string().required("Debe subir un archivo"),
          }),
      }),
    [mappedVariants]
  );

  const { successToast } = useToaster();
  const handleSubmit = () => {
    successToast(
      "Cortesía enviada con éxito",
      `Se envió la cortesía al mail del usuario deseado`
    );
  };

  const formik = useFormik<MassiveInvitationsFields>({
    initialValues: {
      variant: "",
      guestsFile: {
        url: "",
        _id: "",
      },
    },
    validationSchema: MassiveInvitationsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Stack spacing={8} mt={5} mb={10}>
        <Box display={"flex"}>
          <InfoOutlineIcon mr={2} mt={1} />
          <Text flexGrow={1} fontSize={16} color={"muted.main"}>
            Para enviar una invitación de manera masiva, necesitamos que nos
            proporciones un archivo en formato Excel con los campos de correo
            electrónico y nombre de los invitados. Puedes utilizar nuestro
            modelo de archivo para facilitar el proceso de carga.{" "}
            <Link color={"primary.main"}>Descarga el documento aquí.</Link>
          </Text>
        </Box>
        <FormControl variant="outline" borderRadius={4} id="guestFile">
          <FilePond
            id="guestFile"
            name="guest.file"
            key="guestFile"
            files={
              formik?.values?.guestsFile?.file
                ? [formik.values.guestsFile.file]
                : []
            }
            server={{
              process: async (
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort,
                transfer,
                options
              ) => {
                try {
                  // const image = await formService.postPDF(file);
                  // formik.setFieldValue("guestFile", {
                  //   ...image,
                  //   file,
                  // });
                  // load(image.url);
                } catch (e) {
                  error("Ocurrió un error");
                }
                /* store file somewhere and call `load` when done */
              },
            }}
            instantUpload
            labelMaxFileSizeExceeded="Archivo demasiado grande"
            labelMaxFileSize="Tamaño máximo: 3MB"
            allowImagePreview={false}
            acceptedFileTypes={["application/pdf"]}
            onupdatefiles={(images) => {}}
            labelIdle='Arrastre o<span class="filepond--label-action"> seleccione el archivo desde su equipo</span>'
            labelFileLoading="Cargando"
            labelFileProcessing="Procesando"
            labelFileProcessingComplete="Listo!"
            labelFileProcessingError="Error"
            labelTapToCancel="Cancelar"
            labelTapToRetry="Reintentar"
            labelTapToUndo="Deshacer"
            labelButtonRemoveItem="Eliminar"
            labelFileLoadError={"Error al cargar el archivo"}
            allowFileTypeValidation={true}
          />
        </FormControl>
        <Stack spacing={5}>
          <Text>Ticket a enviar:</Text>
          <Box maxW={"sm"}>
            <Field name={"variant"}>
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  colorScheme={"secondary"}
                  id="variant"
                  isRequired
                  isInvalid={form.errors.variant}
                >
                  <Select
                    {...StyledSelectProps}
                    // isSearchable={false}
                    options={mappedVariants}
                    isLoading={props.variantsLoading}
                    isDisabled={props.variantsLoading}
                    placeholder=" "
                    noOptionsMessage={() => "No hay resultados"}
                    loadingMessage={() => "Cargando..."}
                    {...field}
                    value={mappedVariants.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(value: any) => {
                      formik.setFieldValue(field.name, value.value);
                    }}
                  />

                  <FormLabel>Tipo de ticket</FormLabel>
                  <FormErrorMessage>{form.errors.variant}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>
        </Stack>
        {props.eventFunctionChartKey && (
          <Box bgColor={"red.200"} borderRadius={5} p={5}>
            <Box display={"flex"}>
              <WarningIcon mr={2} mt={1} color={"black"} />
              <Text flexGrow={1} fontSize={16} color={"black"}>
                Los asientos asignados serán seleccionados de forma
                <b> aleatoria </b>
                según su disponibilidad. Recuerde que al confirmar el envío de
                las invitaciones, no podrá modificar la distribución de los
                asientos, volver o cancelar la acción.
              </Text>
            </Box>
            <Text mt={5} color={"black"} textAlign={"end"} fontWeight={600}>
              CONFIRMAR ASIGNACIÓN ALEATORIA
            </Text>
          </Box>
        )}
      </Stack>

      <Button
        borderRadius={"full"}
        colorScheme={"primary"}
        textAlign={"center"}
        alignSelf={"center"}
        maxW={"xs"}
        w={"100%"}
        my={5}
        px={10}
        onClick={formik.submitForm}
      >
        INVITAR
      </Button>
    </FormikProvider>
  );
};
