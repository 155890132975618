import { useContext, useEffect, useState } from "react";
import { LayoutProps } from "../interfaces";
import EventInfo from "../components/event-info";
import { Box, Image } from "@chakra-ui/react";
import TicketList from "../components/ticket-list";
import FunctionSelector from "../components/function-selector";
import { EventFunctionDTO } from "../../../../services/events/dto";
import QueueInfo from "../components/queue-info";
import { queueURL } from "../../../../config/envConf";
import { formatQueueDateTime } from "../../../../utils/dateFormatters";
import { useProfileAsyncStore } from "../../../../services/profile/profileStore";
import PrivateRoute from "../../../../components/PrivateRoute";
import { useProfileService } from "../../../../services/profile/profileService";
import { AuthContext } from "../../../../providers/authContext";

const QueueLayout = ({ controller }: LayoutProps) => {
  const [selectedFunction, setSelectedFunction] = useState<EventFunctionDTO>();
  useEffect(() => {
    if (controller.functions && controller.functions.length === 1) {
      setSelectedFunction(controller.functions[0]);
    }
  }, [controller]);
  const hasSeats = Boolean(selectedFunction?.eventFunctionChartKey);

  const [joinedQueue, setJoinedQueue] = useState(false);

  const profileService = useProfileService();
  const { data, loading, execute } = useProfileAsyncStore();

  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.authState.status === "logged" && !data?.email) {
      execute(profileService.getProfile);
    }
  }, [authContext, data]);

  const onWhatsAppPress = () => {
    if (data.email) {
      window.location.replace(
        queueURL +
          `?text=Buenos días, quiero unirme a una fila de espera, estos son los datos del evento: Nombre: ${
            controller.displayName
          }. Organizador: ${
            controller.organizer
          } Fecha y hora: ${formatQueueDateTime(
            new Date(selectedFunction.datetime)
          )} Lugar: ${
            controller.location
          } Quiero ingresar a la fila con este email: ${
            data.email
          } Espero su respuesta para continuar con el proceso de compra.`
      );
    }
  };

  return joinedQueue ? (
    <PrivateRoute
      component={() => (
        <QueueInfo isLoading={loading} onWhatsAppPress={onWhatsAppPress} />
      )}
    />
  ) : (
    <>
      <EventInfo controller={controller} />

      {!controller.eventLoading &&
        !controller.cartSummary.loading &&
        (!selectedFunction ? (
          <FunctionSelector
            functions={controller.functions}
            name={controller.displayName}
            location={controller.location}
            onSelectFunction={setSelectedFunction}
          />
        ) : (
          <Box
            display={"flex"}
            flexDir={["column", "column", "row"]}
            px={{ base: 5, md: "7%" }}
          >
            {hasSeats && (
              <Box
                position={["unset", "unset", "sticky"]}
                top={71}
                mt={[0, 0, 5]}
                mx={5}
                h={"max-content"}
                width={["calc(100% -5)", "calc(100% -5)", "100%"]}
                padding={10}
              >
                <Image src={controller.seatsImage} />
              </Box>
            )}
            <TicketList
              hasSeats={hasSeats}
              tickets={controller.ticketVariants[selectedFunction._id]}
              onContinue={() => setJoinedQueue(true)}
            />
          </Box>
        ))}
    </>
  );
};

export default QueueLayout;
