import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  Img,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCreateEventService } from "../../../../services/createEvent/createEventService";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import { EventStep } from "./step";
import { Field, FormikProps, FormikProvider } from "formik";
import {
  ImageFormFields,
  useEventFormStore,
} from "../../../../services/createEvent/createEventStore";
import { useEffect } from "react";

// Register the FilePond plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);
export type ImagesFormProps = {
  formik: FormikProps<ImageFormFields>;
};

const ImageHoverContainer = ({ children, imgURL }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue<boolean>({
    base: true,
    md: false,
  });

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Box
          onMouseEnter={() => {
            if (imgURL && imgURL !== "" && !isMobile) {
              onOpen();
            }
          }}
          onMouseLeave={onClose}
        >
          {children}
        </Box>
      </PopoverTrigger>
      <PopoverContent bgColor={"black"} borderColor={"muted.main"}>
        <PopoverHeader fontWeight="semibold">Preview de Imagen</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Img maxH={"30vh"} src={imgURL} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const ImagesForm = ({ formik }: ImagesFormProps) => {
  const formService = useCreateEventService();

  const { setFormData, setEvent, formData } = useEventFormStore();

  useEffect(() => {
    setFormData({
      images: formik.values,
    });
  }, [formik.values]);

  return (
    <EventStep>
      <FormikProvider value={formik}>
        <Stack direction={"column"} spacing={5} p={[5, 10]}>
          <ImageHoverContainer imgURL={formik.values?.card?.url}>
            <Field name="card">
              {({ field, form }) => (
                <FormControl
                  variant="outline"
                  borderRadius={4}
                  isInvalid={form.errors.card && form.errors.card.url}
                  id="card"
                  isRequired
                >
                  <FormLabel fontSize={"sm"}>
                    Grilla Home y Ficha interna (mobile){" "}
                    <strong>365 x 165px</strong>
                  </FormLabel>
                  <FilePond
                    files={formik.values.card ? [formik.values.card.file] : []}
                    id="storeImages"
                    name="store.images"
                    key="images"
                    instantUpload
                    //@ts-ignore
                    allowImagePreview={false}
                    labelMaxFileSizeExceeded="Archivo demasiado grande"
                    labelMaxFileSize="Tamaño máximo: 50MB"
                    maxFileSize={"50MB"}
                    maxFiles={1}
                    acceptedFileTypes={["image/*"]}
                    onupdatefiles={(images) => {}}
                    server={{
                      process: async (
                        fieldName,
                        file,
                        metadata,
                        load,
                        error,
                        progress,
                        abort,
                        transfer,
                        options
                      ) => {
                        try {
                          const image = await formService.postImage(file);
                          formik.setFieldValue("card", { ...image, file });
                          setEvent({ imageURL: image.url });
                          load(image.url);
                        } catch {
                          error("Ocurrió un error");
                        }
                        /* store file somewhere and call `load` when done */
                      },
                    }}
                    labelIdle='Arrastre la imagen o <span class="filepond--label-action">elijala desde archivo</span>'
                    labelFileLoading="Cargando"
                    labelFileProcessing="Procesando"
                    labelFileProcessingComplete="Listo!"
                    labelFileProcessingError="Error"
                    labelTapToCancel="Cancelar"
                    labelTapToRetry="Reintentar"
                    labelTapToUndo="Deshacer"
                    labelButtonRemoveItem="Eliminar"
                    labelFileLoadError={"Error al cargar el archivo"}
                    allowFileTypeValidation={true}
                  />
                  <FormErrorMessage>
                    {form.errors.card && form.errors.card.url}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ImageHoverContainer>

          <ImageHoverContainer imgURL={formik.values?.detail?.url}>
            <Field name="detail">
              {({ field, form }) => (
                <FormControl
                  variant="outline"
                  borderRadius={4}
                  id="detail"
                  isInvalid={form.errors.detail}
                  isRequired
                >
                  <FormLabel fontSize={"sm"}>
                    Ficha interna (detalle evento) <strong>1170 x 295px</strong>
                  </FormLabel>
                  <FilePond
                    files={
                      formik.values.detail ? [formik.values.detail.file] : []
                    }
                    server={{
                      process: async (
                        fieldName,
                        file,
                        metadata,
                        load,
                        error,
                        progress,
                        abort,
                        transfer,
                        options
                      ) => {
                        try {
                          const image = await formService.postImage(file);
                          formik.setFieldValue("detail", { ...image, file });
                          load(image.url);
                        } catch {
                          error("Ocurrió un error");
                        }
                        /* store file somewhere and call `load` when done */
                      },
                    }}
                    id="storeImages"
                    name="store.images"
                    key="images"
                    instantUpload
                    labelMaxFileSizeExceeded="Archivo demasiado grande"
                    labelMaxFileSize="Tamaño máximo: 50MB"
                    maxFileSize={"50MB"}
                    allowMultiple={false}
                    allowImagePreview={false}
                    acceptedFileTypes={["image/*"]}
                    labelIdle='Arrastre las imagenes o <span class="filepond--label-action">elijalas desde archivo</span>'
                    labelFileLoading="Cargando"
                    labelFileProcessing="Procesando"
                    labelFileProcessingComplete="Listo!"
                    labelFileProcessingError="Error"
                    labelTapToCancel="Cancelar"
                    labelTapToRetry="Reintentar"
                    labelTapToUndo="Deshacer"
                    labelButtonRemoveItem="Eliminar"
                    labelFileLoadError={"Error al cargar el archivo"}
                  />
                  <FormErrorMessage>
                    {form.errors.detail && form.errors.detail.url}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ImageHoverContainer>

          <ImageHoverContainer imgURL={formik.values?.banner?.url}>
            <FormControl variant="outline" borderRadius={4} id="banner">
              <FormLabel fontSize={"sm"}>
                Banner home (solo comercial) <strong>1515 x 395px </strong> área
                segura <strong>1440 x 250px </strong>
              </FormLabel>
              <FilePond
                id="storeImages"
                name="store.images"
                key="images"
                files={formik.values.banner ? [formik.values.banner.file] : []}
                server={{
                  process: async (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort,
                    transfer,
                    options
                  ) => {
                    try {
                      const image = await formService.postImage(file);
                      formik.setFieldValue("banner", { ...image, file });
                      load(image.url);
                    } catch {
                      error("Ocurrió un error");
                    }
                    /* store file somewhere and call `load` when done */
                  },
                }}
                instantUpload
                labelMaxFileSizeExceeded="Archivo demasiado grande"
                labelMaxFileSize="Tamaño máximo: 50MB"
                maxFileSize={"50MB"}
                allowImagePreview={false}
                acceptedFileTypes={["image/*"]}
                labelIdle='Arrastre las imagenes o <span class="filepond--label-action">elijalas desde archivo</span>'
                labelFileLoading="Cargando"
                labelFileProcessing="Procesando"
                labelFileProcessingComplete="Listo!"
                labelFileProcessingError="Error"
                labelTapToCancel="Cancelar"
                labelTapToRetry="Reintentar"
                labelTapToUndo="Deshacer"
                labelButtonRemoveItem="Eliminar"
                labelFileLoadError={"Error al cargar el archivo"}
              />
            </FormControl>
          </ImageHoverContainer>

          <ImageHoverContainer imgURL={formik.values?.bannerMobile?.url}>
            <FormControl variant="outline" borderRadius={4} id="bannerMobile">
              <FormLabel fontSize={"sm"}>
                Banner home (solo comercial) <strong>430 x 180px</strong> área
                segura <strong>380 x 135px </strong>
              </FormLabel>
              <FilePond
                id="storeImages"
                name="store.images"
                key="images"
                files={
                  formik.values.bannerMobile
                    ? [formik.values.bannerMobile.file]
                    : []
                }
                server={{
                  process: async (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort,
                    transfer,
                    options
                  ) => {
                    try {
                      const image = await formService.postImage(file);
                      formik.setFieldValue("bannerMobile", { ...image, file });
                      load(image.url);
                    } catch {
                      error("Ocurrió un error");
                    }
                    /* store file somewhere and call `load` when done */
                  },
                }}
                instantUpload
                labelMaxFileSizeExceeded="Archivo demasiado grande"
                labelMaxFileSize="Tamaño máximo: 50MB"
                maxFileSize={"50MB"}
                allowImagePreview={false}
                acceptedFileTypes={["image/*"]}
                onupdatefiles={(images) => {}}
                labelIdle='Arrastre la imagen o <span class="filepond--label-action">elijala desde archivo</span>'
                labelFileLoading="Cargando"
                labelFileProcessing="Procesando"
                labelFileProcessingComplete="Listo!"
                labelFileProcessingError="Error"
                labelTapToCancel="Cancelar"
                labelTapToRetry="Reintentar"
                labelTapToUndo="Deshacer"
                labelButtonRemoveItem="Eliminar"
                labelFileLoadError={"Error al cargar el archivo"}
                allowFileTypeValidation={true}
              />
            </FormControl>
          </ImageHoverContainer>
          {!formData?.eventData?.hasSeats && (
            <ImageHoverContainer imgURL={formik.values?.place?.url}>
              <FormControl variant="outline" borderRadius={4} id="place">
                <FormLabel fontSize={"sm"}>
                  Distribución del escenario (opcional){" "}
                  <strong>300 x 400px</strong>
                  <p></p>Esta imagen debe mostrar los sectores disponibles para
                  los distintos tipos de entradas
                </FormLabel>
                <FilePond
                  id="storeImages"
                  name="store.images"
                  key="images"
                  files={formik.values.place ? [formik.values.place.file] : []}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort,
                      transfer,
                      options
                    ) => {
                      try {
                        const image = await formService.postImage(file);
                        formik.setFieldValue("place", { ...image, file });
                        load(image.url);
                      } catch {
                        error("Ocurrió un error");
                      }
                      /* store file somewhere and call `load` when done */
                    },
                  }}
                  instantUpload
                  labelMaxFileSizeExceeded="Archivo demasiado grande"
                  labelMaxFileSize="Tamaño máximo: 50MB"
                  maxFileSize={"50MB"}
                  allowImagePreview={false}
                  acceptedFileTypes={["image/*"]}
                  onupdatefiles={(images) => {}}
                  labelIdle='Arrastre la imagen o <span class="filepond--label-action">elijala desde archivo</span>'
                  labelFileLoading="Cargando"
                  labelFileProcessing="Procesando"
                  labelFileProcessingComplete="Listo!"
                  labelFileProcessingError="Error"
                  labelTapToCancel="Cancelar"
                  labelTapToRetry="Reintentar"
                  labelTapToUndo="Deshacer"
                  labelButtonRemoveItem="Eliminar"
                  labelFileLoadError={"Error al cargar el archivo"}
                  allowFileTypeValidation={true}
                />
              </FormControl>
            </ImageHoverContainer>
          )}
        </Stack>
      </FormikProvider>
    </EventStep>
  );
};

export default ImagesForm;
