import { SeatsTicketProps } from "../views/Landing/EventDetail/interfaces";

export const formatFullName = (firstname?: string, lastname?: string) => {
  return (firstname ?? "") + " " + (lastname ?? "");
};

export const ticketLabelFormatter = (ticket: SeatsTicketProps) => {
  if (ticket.objectType === "Seat") {
    return (
      "Seccion: " +
      (ticket?.labels?.section || ticket.category.label) +
      " | Fila: " +
      ticket.labels.parent +
      " | Asiento: " +
      ticket.labels.own
    );
  } else {
    return ticket.labels.displayedLabel;
  }
};
