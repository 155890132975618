/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import * as Yup from "yup";
import { ShoppingSessionDTO } from "../../../../services/shoppingSession/dto";
import { useTicketsSaleService } from "../../../../services/ticketsSale/ticketsSaleService";
import { OfficeSaleOutletContext } from "../interfaces";
import { SaleStep } from "./step";
import { useToaster } from "../../../../utils/useToaster";

const TransactionSchema = Yup.object().shape({
  transactionCode: Yup.string()
    .required("Debe ingresar un codigo de transacción")
    .max(50, "El codigo es muy largo"),
});

const Transaction = () => {
  const { submitTransactionCode, onBackButtonPress } =
    useOutletContext<OfficeSaleOutletContext>();

  const ticketSaleService = useTicketsSaleService();

  const currentShoppingSession: ShoppingSessionDTO =
    ticketSaleService.store.formData?.createdShoppingSession ?? null;

  const [isLoading, setIsLoading] = useState(false);

  const { successToast, errorToast } = useToaster();

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      await submitTransactionCode(values.transactionCode);
      successToast("Venta exitosa!", "La venta se genero con exito");
    } catch (error) {
      console.log("Error: ", error);
      errorToast(
        "Ocurrió un error",
        error.response?.data?.message ??
          "La venta no pudo concretarse, reintente o comuniquese con soporte."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      transactionCode: "",
    },
    validationSchema: TransactionSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!currentShoppingSession) {
      onBackButtonPress();
    }
  }, [currentShoppingSession]);

  return (
    <SaleStep>
      <Stack spacing={65} w="100%">
        <FormikProvider value={formik}>
          <Center mt="1rem">
            <Field name="transactionCode">
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="transactionCode"
                  isInvalid={form.errors.transactionCode}
                  isRequired
                  maxW="60%"
                >
                  <Input {...field} placeholder=" " max={50} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>ID de Transacción</FormLabel>
                  <FormErrorMessage>
                    {form.errors.transactionCode}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Center>

          <Stack textAlign={"center"}>
            <Text fontSize={"3xl"} fontWeight="bold">
              MONTO A COBRAR:
              <br />
              <Text
                style={{
                  color: "primary.main",
                }}
                fontSize={"5xl"}
              >
                {" $" + currentShoppingSession?.price.finalPrice}
              </Text>
            </Text>
          </Stack>
          <Button
            colorScheme={"primary"}
            borderRadius="full"
            w="min-content"
            alignSelf={"center"}
            isLoading={isLoading}
            onClick={() => {
              formik.validateForm().then(() => {
                formik.submitForm();
              });
            }}
          >
            Enviar tickets
          </Button>
        </FormikProvider>
      </Stack>
    </SaleStep>
  );
};

export default Transaction;
