export const priceFormatter = (price?: number) => {
  if (!price && price !== 0) {
    return "";
  } else {
    const formatter = new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
      //   maximumFractionDigits: 0,
    });

    return formatter.format(price).replace("$", "");
  }
};

export const parsePrice = (val?: any) => {
  let newVal = val;
  if (typeof val !== "string") {
    newVal = val.toString();
  }
  return newVal ? newVal.replaceAll(".", "") : "";
};
