import { Box, chakra } from "@chakra-ui/react";
import React from "react";

interface AdminLayoutProps {
  header: any;
  footer?: any;
  children: any;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({
  header,
  footer,
  children,
}) => {
  const url = window.location.href;
  const isChileTiket =
  url.startsWith("https://www.chiletiket") ||
  url.startsWith("https://chiletiket") ||
  url.startsWith("https://test.chiletiket") ||
  url.startsWith("http://localhost");

  return (
    <Box
      maxW={"100%"}
      minH={"100%"}
      overflow="auto"
      bgColor={"backgroundColor"}
    >
      <chakra.header
        as={Box}
        position={"fixed"}
        top="0"
        zIndex={"999"}
        width={"100%"}
      >
        {header}
      </chakra.header>
      <Box maxW={"100vw"} minH={"100vh"} overflow="auto" mt={isChileTiket ? 24 : 16}>
        {children}
      </Box>
      {footer && <chakra.footer>{footer}</chakra.footer>}
    </Box>
  );
};

export default AdminLayout;
