import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Divider,
  DrawerFooter,
  Button,
  Box,
  Image,
  Center,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import Card from "../../../../components/Card";

import { TicketDetailProps } from "../interfaces";
import { useState } from "react";

export const TicketDetail: React.FC<TicketDetailProps> = ({
  drawerManager,
  selectedTicket,
  transferCall,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Drawer
      isOpen={drawerManager.isOpen}
      placement="right"
      onClose={drawerManager.onClose}
      size={"md"}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={"secondary.main"}>
        <DrawerCloseButton />
        {selectedTicket && (
          <DrawerBody
            color={"secondary.contrast"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
          >
            <Text
              my={7}
              fontWeight={600}
              fontSize={[18, 20]}
              textAlign={"left"}
              w="full"
            >
              Detalle de tu ticket
            </Text>
            <Center flexDir={"column"} flexGrow={1}>
              <Text
                mb={4}
                fontWeight={500}
                fontSize={[18, 20]}
                textAlign={"center"}
              >
                Muestra el QR en la puerta
              </Text>
              <Card
                bgColor={"white"}
                w={200}
                p={2.5}
                borderColor={"primary.main"}
                borderWidth={3}
              >
                <Skeleton
                  startColor="secondary.100"
                  endColor="secondary.700"
                  flex={1}
                  style={{ aspectRatio: 1 }}
                  isLoaded={imageLoaded && Boolean(selectedTicket.qr)}
                  borderRadius={11}
                >
                  <Image
                    backgroundColor={"white"}
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageLoaded(false)}
                    style={{ aspectRatio: 1 }}
                    src={selectedTicket.qr}
                  />
                </Skeleton>
              </Card>
              <Text mt={2} fontWeight={600} fontSize={22}>
                {selectedTicket.code}
              </Text>
              <Text
                mt={1.5}
                fontWeight={600}
                fontSize={22}
                color={"primary.main"}
              >
                {selectedTicket.variant}
              </Text>
              <Divider my={2} />
              <Text textAlign={"center"}>
                {selectedTicket.ticketDescription}
              </Text>
              <Text textAlign={"center"} mt={2}>
                {selectedTicket.profileName}
              </Text>
              <Text textAlign={"center"} mt={2}>
                {selectedTicket.profileIdNumber}
              </Text>
            </Center>
          </DrawerBody>
        )}
        <DrawerFooter h="15%" alignItems={"center"} justifyContent={"center"}>
          <Button colorScheme="primary" rounded={"full"} onClick={transferCall}>
            TRANSFERIR
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
