import React from "react";
import { EventStepperFormProps } from "./interfaces";
import { useEventStepperFormController } from "./event-stepper-form.controller";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { ArrowBackIcon } from "@chakra-ui/icons";
import { ticketApproved, ticketRejected } from "../../../resources";
import EventDataForm from "./components/event-data-form";
import FunctionsForm from "./components/functions-form";
import ImagesForm from "./components/images-form";
import OrganizerForm from "./components/organizer-form";
import TicketsForm from "./components/tickets-form";
import { WarningIcon } from "../../../components/Icons/WarningIcon";
import { SaveIcon } from "../../../components/Icons/SaveIcon";
import PaymentsForm from "./components/payments-form";

export const EventStepperForm: React.FC<EventStepperFormProps> = ({
  useController = useEventStepperFormController,
}) => {
  const controller = useController();

  const stepperRoutes = [
    {
      element: <OrganizerForm {...controller.organizerProps} />,
      label: "Datos organizador",
      isValid: controller.organizerProps.formik.isValid,
    },
    {
      element: <EventDataForm {...controller.eventDataProps} />,
      label: "Datos evento",
      isValid: controller.eventDataProps.formik.isValid,
    },
    {
      element: <FunctionsForm {...controller.functionsProps} />,
      label: "Fechas",
      isValid: controller.functionsProps.formik.isValid,
    },
    {
      element: <ImagesForm {...controller.imagesProps} />,
      label: "Imagenes",
      isValid: controller.imagesProps.formik.isValid,
    },
    {
      element: <TicketsForm {...controller.ticketsProps} />,
      label: "Tickets",
      isValid: controller.ticketsProps.formik.isValid,
    },
    {
      element: <PaymentsForm {...controller.paymentMethodsProps} />,
      label: "Medios de pago",
      isValid: controller.paymentMethodsProps.formik.isValid,
    },
  ];

  return (
    <Box pt={10} maxW={{ base: "80vw", xl: "5xl" }} mx="auto">
      <Box>
        <Flex justifyContent={"space-between"}>
          <HStack display={"flex"} alignItems={"center"} my={2} spacing={4}>
            <ArrowBackIcon
              as="button"
              onClick={controller.onBackButtonPress}
              cursor="pointer"
              fontSize={24}
              color={"backgroundContrast"}
              userSelect={"none"}
            />
            <Text fontSize={32} fontWeight={"semibold"} flexGrow={1} mx={2}>
              Crear evento
            </Text>
          </HStack>
          <Button
            leftIcon={<SaveIcon />}
            borderRadius={"full"}
            size="md"
            colorScheme="primary"
            onClick={controller.submitEvent}
            isLoading={controller.submitLoading}
          >
            Finalizar
          </Button>
        </Flex>
        <Tabs w="full" variant={"unstyled"} gap={2}>
          <HStack>
            <TabList w="full">
              {stepperRoutes.map((step, index) => (
                <Tab
                  key={index}
                  onClick={() => controller.onStepPress(index)}
                  w={"full"}
                  flexGrow={"initial"}
                  borderRightRadius={index === stepperRoutes.length - 1 && "8"}
                  borderLeftRadius={index === 0 && "8"}
                  fontWeight={500}
                  py={3}
                  color={"secondary.contrast"}
                  _selected={{ color: "primary.main", bg: "secondary.700" }}
                  bg={"secondary.main"}
                  fontSize={14}
                >
                  {!step.isValid && <WarningIcon color={"red.400"} mr={2} />}
                  {step.label}
                </Tab>
              ))}
            </TabList>
            <Badge
              py={2}
              px={4}
              my={"auto"}
              display={"flex"}
              height={"fit-content"}
              width={"max-content"}
              alignItems={"center"}
              colorScheme={"muted"}
              variant={"solid"}
              bgColor={"muted.main"}
              color={"black"}
              borderRadius={"full"}
            >
              {`${controller.currentStep + 1}/${stepperRoutes.length}`}
            </Badge>
          </HStack>
          <TabPanels>
            {stepperRoutes.map((step) => (
              <TabPanel>{step.element}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>

      <Modal isOpen={controller.isErrorOpen} onClose={controller.onErrorClose}>
        <ModalOverlay />
        <ModalContent bgColor={"backgroundColor"} p={5} textAlign={"center"}>
          <ModalHeader fontWeight="medium">Ocurrió un error</ModalHeader>
          <ModalBody>
            <Stack spacing={10} mb={5}>
              <Text>Revisá los datos ingresados o inténtalo de nuevo</Text>
              <Center>
                <Image src={ticketRejected} />
              </Center>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent={"center"}>
            <Button
              colorScheme="primary"
              onClick={controller.onErrorClose}
              borderRadius="full"
            >
              Continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={controller.isSuccessOpen}
        onClose={controller.onSuccessClose}
      >
        <ModalOverlay />
        <ModalContent bgColor={"backgroundColor"} p={5} textAlign={"center"}>
          <ModalHeader fontWeight="medium">Felicidades!</ModalHeader>
          <ModalBody>
            <Stack spacing={10} mb={5}>
              <Text>
                Tu evento está siendo revisado. Te enviaremos un mail cuando se
                publique!
              </Text>
              <Center>
                <Image src={ticketApproved} />
              </Center>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent={"center"}>
            <Button
              colorScheme="primary"
              onClick={controller.onSuccessClose}
              borderRadius="full"
            >
              Continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
