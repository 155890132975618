import { Box } from "@chakra-ui/react";
import ResetPasswordForm from "../../../../components/ResetPasswordFormModal/ResetModalForm";

export type ResetPasswordProps = {};

export const ResetPassword = (props: ResetPasswordProps) => {
  return (
    <Box
      borderColor={"secondary.main"}
      borderWidth={"3px"}
      borderRadius={"xl"}
      padding={5}
    >
      <Box mt={3} mb={8} fontSize={22} fontWeight={500}>
        Cambiar tu clave
      </Box>
      <Box mx={"auto"} maxW={"xs"}>
        <ResetPasswordForm submitLabel="Guardar" />
      </Box>
    </Box>
  );
};
