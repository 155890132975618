import { FlexProps, Icon } from "@chakra-ui/react";
import { useEventsService } from "../../services/events/eventsService";
import { debounce } from "../../utils/debounce";
import {
  OptionBase,
  GroupBase,
  AsyncSelect,
  components,
  ValueContainerProps,
} from "chakra-react-select";
import { SearchResponseDTO } from "../../services/events/dto";
import { StyledSelectProps } from "../../theme/additions/select/Select";

import { SearchIcon } from "../Icons/SearchIcon";
import { useState } from "react";

interface SearchOption extends OptionBase {
  label: string;
  value: string;
}

const MapEventsOptions = (events: SearchResponseDTO) =>
  events?.docs.map((item) => ({
    label: item.name,
    value: item._id,
  }));

const ValueContainer: React.FC<
  ValueContainerProps<SearchOption, true, GroupBase<SearchOption>>
> = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <Icon
            as={SearchIcon}
            style={{ position: "absolute", left: 13 }}
            color="muted.600"
          />
        )}
        <div style={{ paddingLeft: 27 }}>{children}</div>
      </components.ValueContainer>
    )
  );
};

export function DocSearch(props: FlexProps) {
  const eventService = useEventsService();
  const [isFocused, setIsFocused] = useState(false);

  const loadOptions = (inputValue, callback) => {
    debounce(async () => {
      if (inputValue !== "") {
        const events = await eventService.searchEvents(inputValue);
        callback(MapEventsOptions(events));
      }
    }, 1000);
  };

  return (
    <AsyncSelect<SearchOption, true, GroupBase<SearchOption>>
      name="search"
      placeholder={isFocused ? '' : 'Buscar eventos...'} 
      noOptionsMessage={() => "No hay resultados"}
      loadingMessage={() => "Cargando..."}
      closeMenuOnSelect={true}
      size="md"
      onChange={({ value }: any) => {
        window.location.href = `/events/${value}`;
      }}
      onFocus={() => setIsFocused(true)} 
      onBlur={() => setIsFocused(false)} 
      loadOptions={loadOptions}
      {...StyledSelectProps}
      chakraStyles={{
        ...StyledSelectProps.chakraStyles,
        inputContainer: (provided) => ({
          ...provided,
          minW: "30vw",
          maxW: "30vw",
        }),
        menuList: (provided) => ({
          ...provided,
          bg: "secondary.main",
          borderRadius: 15,
        }),
        input: (provided) => ({
          ...provided,
          bgColor: "secondary.main",
          color: "secondary.contrast",
          position: "relative",
          zIndex: 2,
        }),
        control: (provided) => ({
          ...provided,
          borderRadius: "full",
          bgColor: "secondary.main",
          borderColor: "secondary.main",
          w: "30vw",
          shadow: "xl",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          display: "none",
        }),
        valueContainer: (base) => ({
          ...base,
          paddingLeft: 7,
        }),
        placeholder: (base) => ({
          ...base,
          color: "secondary.contrast",
          position: "absolute",
          zIndex: 1,
          paddingLeft: 1,
        }),
        singleValue: (base) => ({
          ...base,
          position: "absolute",
        })
      }}
      components={{ ValueContainer }}
    />
  );
}
