import { Modal, ModalOverlay } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import LoginForm from "./LoginForm";
import ResetPassword from "./ResetPassword";
import SignUpForm from "./SignUpForm";

interface ModalContainerProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const ModalContainer: React.FC<ModalContainerProps> = (props) => {
  const [activeForm, setActiveForm] = useState("login");

  const [params] = useSearchParams();

  useEffect(() => {
    const cod = params.get("cod") ?? null;
    const email = params.get("email") ?? null;
    if (cod && email) {
      setActiveForm("change-pass");
      props.onOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleSubmit = () => {};

  const formRenders = [
    {
      form: "login",
      component: (
        <LoginForm
          setActiveForm={setActiveForm}
          handleSubmit={handleSubmit}
          onClose={props.onClose}
        />
      ),
    },
    {
      form: "register",
      component: (
        <SignUpForm
          setActiveForm={setActiveForm}
          handleSubmit={handleSubmit}
          onClose={props.onClose}
        />
      ),
    },
    {
      form: "reset-pass",
      component: (
        <ResetPassword
          setActiveForm={setActiveForm}
          handleSubmit={handleSubmit}
          onClose={props.onClose}
        />
      ),
    },
    {
      form: "change-pass",
      component: (
        <ChangePassword
          setActiveForm={setActiveForm}
          handleSubmit={handleSubmit}
          onClose={props.onClose}
        />
      ),
    },
  ];

  const getActiveform = (currentForm) =>
    formRenders.find((form) => form.form === currentForm);

  return (
    <Modal
      isCentered
      {...props}
      preserveScrollBarGap
      onClose={() => {
        props.onClose();
        setActiveForm("login");
      }}
    >
      <ModalOverlay />
      {getActiveform(activeForm).component}
    </Modal>
  );
};

export default ModalContainer;
