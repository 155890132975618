import {
  ButtonGroup,
  Flex,
  chakra,
  useDisclosure,
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  HStack,
  Circle,
  Text,
  Link,
} from "@chakra-ui/react";
import { useScroll } from "framer-motion";
import React, { useContext } from "react";
import { DocSearch } from "../SearchBar";

import { useNavigate } from "react-router-dom";

import ModalContainer from "../LoginModal";
import { AuthContext } from "../../providers/authContext";
import { HamburgerIcon } from "@chakra-ui/icons";
import LogOutmodal from "../LogOutModal";
import { useProducerAsyncStore } from "../../services/producer/producerStore";
import { isChiletiketProducer } from "../../utils/getProducer";
import { useColors } from "../../utils/useColors";

const Navbar = ({simple = false}: any) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [y, setY] = React.useState(0);
  const height = ref.current ? ref.current.getBoundingClientRect() : 0;
  const { scrollY } = useScroll();

  const { authState } = useContext(AuthContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isLogOutOpen,
    onOpen: onLogOutOpen,
    onClose: onLogOutClose,
  } = useDisclosure();

  const { data } = useProducerAsyncStore();

  const colors = useColors();

  return (
    <>
      {(isChiletiketProducer() && !simple) && (
        <HStack
          w="full"
          justifyContent="center"
          alignItems="center"
          p={2}
          bgColor="primary.main"
          gap={[2, 2, 6]}
        >
          <Link
            href="https://clientes.chiletiket.com/#somos"
            target="_blank"
            _hover={{ underline: "none" }}
          >
            <Text
              color="black"
              fontSize={["xs", "xs", "sm"]}
              fontWeight="semibold"
            >
              Acerca de ChileTiket
            </Text>
          </Link>
          <Circle size="5px" bgColor="black" />
          <Link
            href="https://clientes.chiletiket.com/#marcablanca"
            target="_blank"
            _hover={{ underline: "none" }}
          >
            <Text
              color="black"
              fontSize={["xs", "xs", "sm"]}
              fontWeight="semibold"
            >
              Crea tu propia Tiketera
            </Text>
          </Link>
          <Circle size="5px" bgColor="black" />
          <Link
            href="https://clientes.chiletiket.com/#contacto"
            target="_blank"
            _hover={{ underline: "none" }}
          >
            <Text
              color="black"
              fontSize={["xs", "xs", "sm"]}
              fontWeight="semibold"
            >
              Contacto
            </Text>
          </Link>
        </HStack>
      )}

      <chakra.nav
        ref={ref}
        h={16}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={{ base: 5, md: 10 }}
        bg={"backgroundColor"}
        shadow={y > height ? "md" : undefined}
        sx={{
          "& > div": {
            flex: 1,
          },
        }}
      >
        <Link href="/" zIndex={1} maxW={150} h="full">
          <Flex h="full" maxW={150} justify="left">
            {data && data.logo && (
              <Image objectFit={"contain"} src={data.logo} />
            )}
          </Flex>
        </Link>

        {!simple ? <Box
          w={"100vw"}
          justifyContent={"center"}
          display={{ base: "none", md: "flex" }}
          position={"absolute"}
          left={0}
        >
          <DocSearch w="sm" />
        </Box> : null}

        {!simple ?<Flex justify="right" maxW={"max-content"}>
          <ButtonGroup alignItems="center">
            {authState.userId === "" ? (
              <Button
                colorScheme="gray"
                fontWeight="semibold"
                rounded="full"
                _hover={{bgColor:colors.primary.main, color:colors.backgroundColor}}
                py={2}
                px={4}
                textTransform="uppercase"
                onClick={onOpen}
              >
                Ingresar
              </Button>
            ) : (
              <Menu>
                <MenuButton
                  as={IconButton}
                  color={"backgroundContrast"}
                  aria-label="Options"
                  icon={<HamburgerIcon />}
                  variant="ghost"
                />
                <MenuList
                  backgroundColor={"secondary.main"}
                  color={"secondary.contrast"}
                >
                  <MenuItem onClick={() => navigate("/profile?screen=tickets")}>
                    Mis Tickets
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/profile?screen=edit")}>
                    Mi Perfil
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/admin")}>
                    Mis Eventos
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/profile?screen=password")}
                  >
                    Cambiar Clave
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/create-event")}>
                    Crear Evento
                  </MenuItem>
                  <MenuItem onClick={onLogOutOpen}>Salir</MenuItem>
                </MenuList>
              </Menu>
            )}
          </ButtonGroup>
        </Flex> : null}
      </chakra.nav>
      <ModalContainer isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      <LogOutmodal isOpen={isLogOutOpen} onClose={onLogOutClose} />
    </>
  );
}

export default Navbar;
