import React from "react";
import { Center, Text } from "@chakra-ui/react";
import { NavigateFunction } from "react-router-dom";

interface NavigationItemProps {
  navigation: NavigateFunction;
  isActive: boolean;
  route: {
    path: string;
    title: string;
    element: JSX.Element;
  };
  eventID: string;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  route,
  navigation,
  eventID,
  isActive,
}) => {
  return (
    <Center
      px={10}
      py={3}
      transition={"0.3s linear"}
      color={isActive && "primary.main"}
      borderRadius={20}
      bgColor={isActive && "secondary.main"}
      cursor="pointer"
      onClick={() => navigation("/admin/" + eventID + "/info/" + route.path)}
    >
      <Text fontWeight={isActive ? 500 : 400} textAlign="center">
        {route.title}
      </Text>
    </Center>
  );
};

export default NavigationItem;
