import { Box, Image, Skeleton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export type EventsCarouselItemProps = {
  imageURL: string;
  loading?: boolean;
};

export const EventsCarouselItem = (props: EventsCarouselItemProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [props.imageURL]);

  return (
    <Skeleton
      startColor="secondary.800"
      endColor="secondary.700"
      overflow={"hidden"}
      w={"100%"}
      h={"100%"}
      isLoaded={!props.loading && imageLoaded}
    >
      <Image
        userSelect={"none"}
        src={props.imageURL}
        cursor={"pointer"}
        fallback={<Box w={"100%"} h={"100%"} bg={"secondary.main"} />}
        onLoad={() => setImageLoaded(true)}
        onError={() => setImageLoaded(true)}
        w={"100%"}
        h={"100%"}
      />
    </Skeleton>
  );
};
