const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};

export const FloatinLabelInputVariant = {
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            color: "backgroundContrast",
            borderColor: "backgroundContrast",
            _focusVisible: {
              borderColor: "primary.main",
              boxShadow: `none`,
            },
            _placeholder: {
              color: "muted.600",
            },
          },
        },
      },
    },
    NumberInput: {
      variants: {
        outline: {
          field: {
            color: "backgroundContrast",
            borderColor: "backgroundContrast",
            _focusVisible: {
              borderColor: "primary.main",
              boxShadow: "none",
            },
            _placeholder: {
              color: "muted.600",
            },
          },
        },
      },
    },
    // borderColor={"white"}
    // _focusVisible={{
    //   borderColor: 'primary.main',
    //   boxShadow: "none",
    // }}
    Textarea: {
      variants: {
        outline: {
          color: "backgroundContrast",
          borderColor: "backgroundContrast",
          _focusVisible: {
            borderColor: "primary.main",
            boxShadow: "none",
          },
          _placeholder: {
            color: "muted.600",
          },
        },
      },
    },
    Form: {
      variants: {
        floating: (props) => ({
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-react-select--has-value + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: props.colorScheme
                ? props.colorScheme + ".main"
                : "backgroundColor",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              color: "backgroundContrast",
            },
          },
          helperText: {
            color: "backgroundContrast",
          },
        }),
        floatingGray: (props) => ({
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-react-select--has-value + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: props.colorScheme
                ? props.colorScheme + ".main"
                : "secondary.main",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              color: "backgroundContrast",
            },
          },
          helperText: {
            color: "backgroundContrast",
          },
        }),
      },
    },
  },
};
