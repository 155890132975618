/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserEventsService } from "../../../services/Admin/events/userEventsService";
import { formateDateAndHour } from "../../../utils/dateFormatters";
import { useAsync } from "../../../utils/useAsync";
import { OfficeSaleController } from "./interfaces";
import { saleStepperRoutes } from "./routes";
import { useChildrenIndex } from "../../../utils/useChildrenIndex";
import { useTicketsSaleService } from "../../../services/ticketsSale/ticketsSaleService";
import { useDisclosure } from "@chakra-ui/react";
import { useShoppingSessionService } from "../../../services/shoppingSession/shoppingSessionService";
import { ShoppingCartDTO } from "../../../services/shoppingSession/dto";

const initialFun = { value: "all", label: "Todas las funciones" };

export const useOfficeSaleController = (): OfficeSaleController => {
  const { id } = useParams();
  const navigate = useNavigate();
  const routeIndex = useChildrenIndex();

  const userEventService = useUserEventsService();

  const ticketSaleService = useTicketsSaleService();

  const shoppingSessionService = useShoppingSessionService();

  const [eventInfo, , eventLoading, eventExecute] = useAsync(
    userEventService.getUserEvent
  );

  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  useEffect(() => {
    if (id) {
      eventExecute(id);
    }
  }, [id]);

  const mappedFunctions = useMemo(() => {
    const functions = eventInfo?.functions
      ? eventInfo.functions.map((fun, index) => ({
          value: fun._id,
          label: formateDateAndHour(new Date(fun.datetime)),
          token: fun.tokens[0],
          eventFunctionChartKey: fun.eventFunctionChartKey,
        }))
      : [];
    return [initialFun, ...functions];
  }, [eventInfo]);

  const [activeFunction, setActiveFunction] = useState(initialFun);

  useEffect(() => {
    if (mappedFunctions.length === 2) {
      setActiveFunction(mappedFunctions[1]);
    }
  }, [mappedFunctions]);

  const onNextStepPress = useCallback(() => {
    navigate(saleStepperRoutes[routeIndex + 1].path);
  }, [routeIndex]);

  const onBackButtonPress = useCallback(() => {
    if (routeIndex === 1) {
      navigate("/admin/" + id + "/info");
    } else {
      navigate(saleStepperRoutes[routeIndex - 1].path);
    }
  }, [routeIndex]);

  const submitSale = async (shoppingSessionData: ShoppingCartDTO) => {
    const { profile } = ticketSaleService.store.formData;

    if (!(profile && shoppingSessionData)) {
      return new Error("Datos incompletos para realizar la venta.");
    } else {
      await shoppingSessionService
        .createShoppingSession(shoppingSessionData)
        .then((res) => {
          ticketSaleService.store.setFormData({
            createdShoppingSession: res,
          });

          onNextStepPress();
        });
    }
  };

  const submitTransactionCode = async (code: string) =>
    await ticketSaleService
      .sendTransactionCode({
        functionId: activeFunction.value,
        transactionId: code,
      })
      .then(() => {
        ticketSaleService.store.setFormData({
          transactionId: code,
        });
        onNextStepPress();
      });

  const onFinish = () => {
    onSuccessClose();
    navigate(`/admin/${id}/sale`);
  };

  const DownloadSalePDF = async () => {
    const shoppingSessionId =
      ticketSaleService.store.formData.createdShoppingSession._id;
    return await ticketSaleService.getSalePDF(shoppingSessionId);
  };

  return {
    currentEventId: id,
    context: {
      onNextStepPress,
      onBackButtonPress,
      submitSale,
      activeFunction,
      eventInfo,
      submitTransactionCode,
      onFinish,
      DownloadSalePDF,
    },
    onBackButtonPress,
    eventInfo,
    eventLoading,
    eventFunctions: mappedFunctions,
    onFunctionSelect: setActiveFunction,
    activeFunction,
    isSuccessOpen,
    isErrorOpen,
    onSuccessOpen,
    onErrorOpen,
    onSuccessClose: onFinish,
    onErrorClose,
  };
};
