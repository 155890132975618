import React from "react";
import ErrorScreen from "../components/ErrorScreen";
import { Footer } from "../components/Footer";
import LandingLayout from "../components/Layouts/Landing/LandingLayout";
import Navbar from "../components/Navbar/Navbar";
import { EventStepperForm } from "../views/Landing/event-stepper-form/event-stepper-form.view";
import { EventDetail } from "../views/Landing/EventDetail/event-detail.view";
import { EventDetail as TurnSession } from "../views/Landing/TurnSession/turn-session.view";
import { Home } from "../views/Landing/Home/home.view";
import { FastPass } from "../views/Landing/FastPass/fastPass.view";
import { PaymentSummary } from "../views/Landing/payment-summary/payment-summary.view";
import { Profile } from "../views/Landing/Profile/profile.view";
import { Terms } from "../views/Landing/Terms/terms.view";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { recaptchaKey } from "../config/envConf";
import { Box } from "@chakra-ui/layout";
import "./captcha.css";
import { Frequent } from "../views/Landing/Frequent/frequent.view";
import PersonalData from "../views/Landing/payment-summary/components";
import PaymentStatus from "../views/Landing/EventDetail/components/PaymentStatus";

const LandingLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar />} footer={<Footer />}>
      <Home />
    </LandingLayout>
  );
};

export const FastPassLandingLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar simple={true} />} footer={<Footer simple={true} />}>
      <FastPass />
    </LandingLayout>
  );
};

export const ErrorLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar />} footer={<Footer />}>
      <ErrorScreen />
    </LandingLayout>
  );
};

export const EventDetailLayoutContainer = ({fastPass}: any) => {
  return (
    <LandingLayout header={<Navbar simple={fastPass}/>} footer={<Footer simple={fastPass}/>}>
      <EventDetail fastPass={fastPass} />
    </LandingLayout>
  );
};

export const PaymentStatusLayoutContainer = ({fastPass}: any) => {
  return (
    <LandingLayout center={true} header={<Navbar simple={true}/>} footer={<Footer simple={true}/>} >
      <PaymentStatus />
    </LandingLayout>
  );
};

export const PersonalDataFastPassLayoutContainer = ({fastPass}: any) => {
  return (
    <LandingLayout header={<Navbar simple={true}/>} footer={<Footer simple={true}/>} >
      <PersonalData />
    </LandingLayout>
  );
};

export const EventDetailFastPassLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar simple={true} />} footer={<Footer simple={true} />}>
      <EventDetail />
    </LandingLayout>
  );
};

export const TurnSessionLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar />} footer={<Footer />}>
      <TurnSession />
    </LandingLayout>
  );
};

export const PaymentSummaryLayoutContainer = ({fastPass = false}: any) => {
  return (
    <LandingLayout header={<Navbar simple={fastPass} />} footer={<Footer simple={fastPass} />}>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
        <PaymentSummary fastPass={fastPass} />
      </GoogleReCaptchaProvider>
    </LandingLayout>
  );
};

export const ProfileLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar />} footer={<Footer />}>
      <Profile />
    </LandingLayout>
  );
};

export const EventStepperFormLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar />} footer={<Footer />}>
      <EventStepperForm />
    </LandingLayout>
  );
};

export const TermsLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar />} footer={<Footer />}>
      <Terms />
    </LandingLayout>
  );
};

export const FrequentLayoutContainer = () => {
  return (
    <LandingLayout header={<Navbar />} footer={<Footer />}>
      <Frequent />
    </LandingLayout>
  );
};

export default LandingLayoutContainer;
