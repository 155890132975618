import {
  ModalContent,
  ModalBody,
  Stack,
  Center,
  Button,
  Input,
  ModalFooter,
  Text,
  Box,
  Link,
  InputGroup,
  InputRightElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaEye, FaEyeSlash, FaGoogle } from "react-icons/fa";
import { FormProps } from "../interfaces";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../../../providers/authContext";
import { useAuthService } from "../../../services/auth/authService";
import { Field, FormikProvider, getIn, useFormik } from "formik";
import * as Yup from "yup";
import { useProfileService } from "../../../services/profile/profileService";
import { isWebview } from "../../../utils/detectWebView";
import { useEmailValidator } from "../../../utils/useEmailValidator";
import { EmailInput } from "../../EmailInput";
import { useToaster } from "../../../utils/useToaster";
import { useTheme } from "@emotion/react";
import { useColors } from "../../../utils/useColors";

const LoginForm: React.FC<FormProps> = ({ setActiveForm, onClose }) => {
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [show, setShow] = useState(false);
  const profileService = useProfileService();

  const authService = useAuthService();
  const authContext = useContext(AuthContext);
  const { successToast, errorToast } = useToaster();

  const { execute } = profileService.profileStore;

  const { validateEmail, validationState } = useEmailValidator();

  const LoginSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email("Correo invalido")
          .required("Debe ingresar un correo")
          .test("validation", "El email ingresado no es válido", validateEmail),
        password: Yup.string().required("Debe ingresar la contraseña"),
      }),
    [validateEmail]
  );

  useEffect(() => {
    if (authContext.authState.accessToken) {
      execute(profileService.getProfile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.authState.accessToken]);

  const onSuccess = async (userInfo) => {
    setIsGoogleLoading(true);
    try {
      const res = await authService.externalLogin("google", {
        idToken: userInfo.credential,
        accessToken: userInfo.access_token,
      });
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsGoogleLoading(false);
        return;
      }

      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );
      successToast("Sesion iniciada", "Se inicio correctamente con Google.");
      onClose();
    } catch (e: any) {
      const error = e.message;
      errorToast(
        "Error al iniciar",
        "Ocurrió un error al ingresar con Google."
      );
      if (error === "Request failed with status code 400") {
      }
      setIsGoogleLoading(false);
    }
    setIsGoogleLoading(false);
  };

  const signIn = useGoogleLogin({
    onSuccess,
    flow: "implicit",
  });

  const handleSubmit = async (values) => {
    try {
      setIsLoginLoading(true);
      const { email, password } = values;
      const res = await authService.login(email, password);
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsLoginLoading(false);
        return;
      }

      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );

      successToast("Sesion iniciada", "Se inicio sesion correctamente.");
      onClose();
    } catch (e: any) {
      if (e.response.status === 400) {
        errorToast(
          "Error al iniciar",
          "Verifique sus credenciales de usuario."
        );
      } else {
        errorToast(
          "Error al iniciar",
          "Ocurrió un error al ingresar con sus credenciales."
        );
      }
    } finally {
      setIsLoginLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });
  const colors = useColors();

  return (
    <ModalContent
      borderRadius={20}
      backgroundColor={"backgroundColor"}
      borderWidth={2}
      borderColor={"secondary.main"}
      p={10}
    >
      <ModalBody justifyContent={"center"}>
        <FormikProvider value={formik}>
          <Stack direction={"column"} spacing={5} justifyContent={"center"}>
            <Text
              color="backgroundContrast"
              textAlign={"center"}
              fontWeight="normal"
              fontSize={"lg"}
            >
              Ingresa con tu cuenta o{" "}
              <Link
                fontWeight={"bold"}
                color={"primary.main"}
                onClick={() => setActiveForm("register")}
              >
                registrate
              </Link>
            </Text>
            {!isWebview && (
              <Center w="100%">
                <Button
                  colorScheme={"primary"}
                  borderRadius={4}
                  w={{ base: "100%", md: "80%" }}
                  leftIcon={<FaGoogle />}
                  onClick={() => signIn()}
                  isLoading={isGoogleLoading}
                  disabled={isLoginLoading}
                >
                  <Text maxW="100%">Iniciar sesión con Google</Text>
                </Button>
              </Center>
            )}
            <Box
              borderWidth={0.5}
              borderColor="gray"
              borderRadius={"full"}
              my={5}
            />
            <Center w="100%" as={Stack} direction="column" spacing={5}>
              <Field name={"email"}>
                {({ field, form }) => (
                  <FormControl
                    variant="outline"
                    borderRadius={4}
                    id="email"
                    isInvalid={form.errors.email}
                    isRequired
                    w="80%"
                  >
                    <EmailInput
                      field={field}
                      form={form}
                      validationState={validationState}
                      inputProps={{
                      
                        color: "backgroundContrast",
                        _placeholder: { color: "backgroundContrast" },
                        borderColor: "backgroundContrast",
                        placeholder: "Email",
                        _autofill: {
                          textFillColor: "backgroundContrast",
                          boxShadow: "transparent",
                          transition: "background-color 5000s ease-in-out 0s",
                        },
                      }}
                      inputGroupProps={{
                        borderRadius: 4,
                        borderColor: "backgroundContrast",
                      }}
                    />

                    <FormErrorMessage fontSize={"x-small"}>
                      {form.errors.email}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <FormControl
                isInvalid={getIn(formik.errors, "password")}
                isRequired
                w="80%"
              >
                <InputGroup variant={"outline"}>
                  <Input
                    borderRadius={4}
                    variant={"outline"}
                    color="backgroundContrast"
                    _placeholder={{ color: "backgroundContrast" }}
                    type={show ? "text" : "password"}
                    placeholder="Contraseña"
                    value={formik.values.password}
                    name="password"
                    autoComplete="new-password"
                    onChange={formik.handleChange}
                    borderColor="backgroundContrast"
                    _autofill={{
                      textFillColor: "backgroundContrast",
                      boxShadow: "transparent",
                      transition: "background-color 5000s ease-in-out 0s",
                    }}
                    sx={{
                      
    '-webkit-text-fill-color': `${colors.backgroundContrast} !important`

                    }}
                  />
                  <InputRightElement
                    _hover={{
                      cursor: "pointer",
                    }}
                    children={
                      show ? (
                        <FaEye color={colors?.backgroundContrast} />
                      ) : (
                        <FaEyeSlash color={colors?.backgroundContrast} />
                      )
                    }
                    onClick={() => setShow(!show)}
                  />
                </InputGroup>
                {getIn(formik.errors, "password") ? (
                  <FormErrorMessage fontSize={"x-small"}>
                    {getIn(formik.errors, "password")}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
            </Center>
          </Stack>
        </FormikProvider>
      </ModalBody>
      <ModalFooter justifyContent={"center"}>
        <Stack direction={"column"}>
          <Button
            borderRadius={50}
            colorScheme="primary"
            disabled={isGoogleLoading || isLoginLoading || formik.isValidating}
            isLoading={isLoginLoading || formik.isValidating}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Iniciar Sesión
          </Button>
          <Button
            borderRadius={50}
            colorScheme="primary"
            variant={"ghost"}
            onClick={() => setActiveForm("reset-pass")}
          >
            ¿Olvidaste la clave?
          </Button>
        </Stack>
      </ModalFooter>
    </ModalContent>
  );
};

export default LoginForm;
