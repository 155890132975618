/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Stack,
  Box,
  Text,
  Divider,
  Button,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Card from "../../../../components/Card";
import { TicketSelector } from "../components/ticket-selector";
import { LayoutProps, SeatsObject } from "../interfaces";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import es from "date-fns/esm/locale/es";
import {
  capitalizeFirstLetter,
  formateDateAndHour,
  TIME_ZONE,
} from "../../../../utils/dateFormatters";
import { priceFormatter } from "../../../../utils/priceFormat";
import { ticketLabelFormatter } from "../../../../utils/stringFormatters";
import { seatsKey } from "../../../../config/envConf";

import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "../styles.css";
import EventInfo from "../components/event-info";
import { SeatsCartSummaryMobile } from "../components/cart-summary-mobile";
import { formatInTimeZone } from "date-fns-tz";
import { useToaster } from "../../../../utils/useToaster";
import { TimeIcon } from "@chakra-ui/icons";
import { useColors } from "../../../../utils/useColors";

const mapToCategoriesSummary = (
  selectedSeats: SeatsObject[],
  categories: {
    category: string;
    price: number;
  }[]
) => {
  const obj = categories.reduce((o, key) => ({ ...o, [key.category]: [] }), {});
  Object.keys(obj).forEach((cat) => {
    const elementsToSet = selectedSeats.filter(
      (element) => element.category.label === cat && element
    );
    if (elementsToSet.length > 0) {
      obj[cat] = elementsToSet;
    } else {
      try {
        delete obj[cat];
      } catch {}
    }
  });
  return obj;
};

const SeatsLayoutMobile: React.FC<LayoutProps> = ({
  controller,
  hasSingleFunction = true,
}) => {
  const [formattedCategories, setFormattedCategories] = useState([]);
  const [selectedTicketsIndex, setselectedTicketsIndex] = useState<number[]>(
    []
  );

  const [modalOpen, setModalOpen] = useState(false);

  const { isOpen: showTickets, onToggle: toggleTickets } = useDisclosure();

  const sheetRef = useRef<BottomSheetRef>();

  useEffect(() => {
    if (sheetRef && sheetRef.current) {
      sheetRef.current.snapTo((props) => props.snapPoints[showTickets ? 1 : 0]);
    }
  }, [showTickets]);

  const [selectedSeats, setSelectedSeats] = useState<SeatsObject[]>([]);

  const ticketVariants = useMemo(() => {
    if (!!controller?.eventFunctions) {
      return controller?.eventFunctions?.days[0]?.functions[0]?.tickets;
    }
  }, [controller?.eventFunctions]);

  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    const ticketsManage =
      controller.eventFunctions?.days[0]?.functions[0]?.tickets;
    if (ticketsManage && ticketsManage.length > 0) {
      const categoriesToSet = ticketsManage.map((ticket) => {
        const formattedPrice = ticket.price.replace(/[$,.]+/g, "");
        return {
          category: ticket.name,
          price: parseFloat(formattedPrice),
        };
      });
      setFormattedCategories(categoriesToSet);
    }
  }, [controller?.eventFunctions?.days]);

  useEffect(() => {
    if (controller.eventFunctions?.days.length > 0) {
      controller.eventFunctions.setTicketCart({
        functionId: controller.eventFunctions.days[0].functions[0].id,
        functionIndex: 0,
        functionDate: controller.eventFunctions.days[0].functions[0].date,
        tickets: controller.eventFunctions.days[0].functions[0].tickets.map(
          (ticket, index) => ({
            quantity: selectedTicketsIndex[index],
            id: ticket.id,
            index,
          })
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTicketsIndex, controller.eventFunctions.setTicketCart]);

  const { warningToast } = useToaster();

  const getTicketsLimits = () => {
    return ticketVariants
      .filter((variant) => variant.maxQuantityPerUser)
      .map((variant) => ({
        category: variant.name,
        quantity: variant.maxQuantityPerUser,
      }));
  };

  const colors = useColors();

  return (
    <>
      {!modalOpen && <EventInfo controller={controller} />}
      {!controller.eventLoading && !controller.cartSummary.loading && (
        <Box
          display={"flex"}
          flexDir={["column", "column", "row"]}
          px={{ sm: 5, md: "7%" }}
        >
          {!modalOpen ? (
            <Stack
              direction={"column"}
              w="100%"
              spacing={10}
              px={5}
              mt={5}
              mb={5}
            >
              {hasSingleFunction ? (
                <Card
                  p={5}
                  bgColor={"backgroundColor"}
                  borderColor={"secondary.main"}
                  borderWidth={5}
                >
                  <Stack direction={"column"} spacing={3}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text
                        color={"primary.main"}
                        fontWeight={600}
                        fontSize={20}
                        mr={2}
                      >
                        {controller.displayName}
                      </Text>
                      <Box
                        p={2}
                        borderRadius={"xl"}
                        borderWidth={1}
                        borderColor={"white"}
                        fontWeight={600}
                        fontSize={18}
                        display={"flex"}
                        alignItems={"center"}
                        width={"max-content"}
                        justifyContent={"space-between"}
                        minW={"100px"}
                      >
                        <TimeIcon mr={2} color={"white"} />
                        <Box color={"white"}>{controller.turnTimer}</Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Stack direction={"column"} spacing={3}>
                      <Text fontSize={18}>
                        {controller.eventFunctions.days.length > 0 &&
                          capitalizeFirstLetter(
                            formatInTimeZone(
                              controller.eventFunctions?.days[0].date,
                              TIME_ZONE,
                              "EEEE",
                              {
                                locale: es,
                              }
                            )
                          ) +
                            " " +
                            formateDateAndHour(
                              controller.eventFunctions.days[0].date
                            )}
                      </Text>
                    </Stack>
                  </Stack>
                </Card>
              ) : (
                <TicketSelector {...controller.eventFunctions} />
              )}

              <Button
                size={"sm"}
                maxW={"sm"}
                borderRadius={"full"}
                colorScheme={"primary"}
                onClick={() => setModalOpen(true)}
              >
                Ver ubicaciones
              </Button>
            </Stack>
          ) : (
            <>
              <Text textAlign={"center"} my={5}>
                Utiliza el mapa para elegir tus asientos. Puedes navegar sobre
                el y hacer zoom en los espacios que desees.
              </Text>
              {controller.currentChartKey && formattedCategories && (
                <SeatsioSeatingChart
                  showSectionContents={"onlyAfterZoom"}
                  onRenderStarted={(chart) => {
                    setChartRef(chart);
                  }}
                  workspaceKey={seatsKey}
                  event={controller.currentChartKey}
                  showMinimap={true}
                  showFullScreenButton={false}
                  stylePreset="bubblegum"
                  language={"es"}
                  colorScheme={"dark"}
                  colors={{
                    colorSelected: "hsl(214, 96%, 53%)",
                    colorTitle: colors.primary.main,
                  }}
                  cursorTooltip={{
                    showActionHint: false,
                    showAvailability: false,
                    showCategory: false,
                    showLabel: true,
                    showPricing: true,
                    showUnavailableNotice: true,
                    stylizedLabel: true,
                  }}
                  pricing={formattedCategories}
                  maxSelectedObjects={[...getTicketsLimits(), { total: 6 }]}
                  priceFormatter={(price) => {
                    return "$" + priceFormatter(price as number);
                  }}
                  onObjectSelected={(obj) => {
                    setSelectedSeats((prev) => {
                      var copyPrev = [...prev];
                      if (
                        obj?.objectType === "GeneralAdmissionArea" &&
                        prev.find((items) => items.uuid === obj?.uuid)
                      ) {
                        copyPrev[
                          prev.findIndex((items) => items.uuid === obj?.uuid)
                        ].numSelected = obj.numSelected;
                        return [...copyPrev];
                      } else {
                        return [...prev, obj];
                      }
                    });
                  }}
                  onObjectDeselected={(obj) => {
                    if (obj?.status === "reservedByToken") {
                      !controller.cartSummary.purchaseLoading &&
                        warningToast(
                          "Asiento deseleccionado",
                          "El asiento " +
                            ticketLabelFormatter(obj) +
                            " se quitó de tus selecciones por que ya fue adquirido por otra persona"
                        );
                    }

                    !controller.cartSummary.purchaseLoading &&
                      setSelectedSeats((prev) => {
                        var copyPrev = [...prev];
                        if (
                          obj?.numSelected > 0 &&
                          prev.find((items) => items.uuid === obj?.uuid)
                        ) {
                          copyPrev[
                            prev.findIndex((items) => items.uuid === obj?.uuid)
                          ].numSelected = obj.numSelected;
                          return [...copyPrev];
                        } else {
                          return prev.filter(
                            (element) => element.uuid !== obj.uuid
                          );
                        }
                      });
                  }}
                  region="sa"
                />
              )}

              <BottomSheet
                open={modalOpen}
                ref={sheetRef}
                blocking={false}
                scrollLocking={false}
                onSpringStart={async (event) => {
                  if (event.type === "SNAP" && event.source === "dragging") {
                    toggleTickets();
                  }
                }}
                snapPoints={(props) => [
                  props.footerHeight + props.headerHeight,
                  props.footerHeight + props.headerHeight + 300,
                ]}
                header={
                  <Box
                    display={"flex"}
                    flexDir={"row"}
                    justifyContent={"space-between"}
                  >
                    <Text fontSize={18}>
                      {selectedSeats.length + " Tickets"}
                    </Text>
                    <Text fontSize={18} fontWeight={600}>
                      {"TOTAL: $" +
                        selectedSeats.reduce(
                          (prev, seat) => prev + seat.pricing.price,
                          0
                        )}
                    </Text>
                  </Box>
                }
                footer={
                  <Stack>
                    <Center>
                      <Button
                        mt={2}
                        colorScheme={"primary"}
                        size="md"
                        borderRadius={50}
                        w="60%"
                        mx="auto"
                        isDisabled={
                          Object.keys(
                            mapToCategoriesSummary(
                              selectedSeats,
                              formattedCategories
                            )
                          ).length === 0
                        }
                        isLoading={controller.cartSummary.purchaseLoading}
                        onClick={() => {
                          controller.cartSummary.onPurchasePress(
                            mapToCategoriesSummary(
                              selectedSeats,
                              formattedCategories
                            )
                          );
                        }}
                      >
                        COMPRAR
                      </Button>
                    </Center>
                    <Text
                      color={"primary.main"}
                      textAlign={"center"}
                      onClick={toggleTickets}
                    >
                      {showTickets ? "Ver ubicaciones" : "Ver tickets"}
                    </Text>
                  </Stack>
                }
              >
                {formattedCategories.length > 0 && chartRef && (
                  <SeatsCartSummaryMobile
                    isExempt={controller.cartSummary.isExempt}
                    controller={controller}
                    categoriesToBudget={mapToCategoriesSummary(
                      selectedSeats,
                      formattedCategories
                    )}
                    functionCategories={ticketVariants}
                    chartInstance={chartRef}
                    serviceCharge={controller.cartSummary.serviceCharge}
                  />
                )}
              </BottomSheet>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default SeatsLayoutMobile;
