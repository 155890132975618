import { colors } from "./colors";

export const globalStyles = {
  colors: {
    backgroundColor: colors.backgroundColor,
    backgroundContrast: colors.backgroundContrast,
    primary: colors.primary,
    secondary: colors.secondary,
    muted: colors.muted,
    red: colors.red,
    purple: colors.purple,
    cyan: {
      "50": "#E5FFFB",
      "100": "#B8FFF3",
      "200": "#8AFFEC",
      "300": "#5CFFE4",
      "400": "#2EFFDD",
      "500": "#00FFD5",
      "600": "#00CCAB",
      "700": "#009980",
      "800": "#006655",
      "900": "#00332B",
    },
  },

  styles: {
    global: (props) => ({
      body: {
        fontFamily: "Inter, Roboto",
        color: "backgroundContrast",
        // Fix for seats.io charts background color. Any ivalid chakra color scheme works.
        colorScheme: "foo",
      },
      html: {
        fontFamily: "Inter, Roboto",
      },
    }),
  },
};
