import { ActualFileObject } from "filepond";
import create from "zustand";
import { emptyEvent } from "../../config/constants";
import { EventCardProps } from "../../views/Landing/Home/components/eventCard";
import { PlaceResult } from "../Admin/placesService/dto";
import { PaymentMethods } from "../events/dto";

export type CreateEventMode = "create" | "duplicate";
export interface OrganizerFormFields {
  name: string;
  mail: string;
  phone: string;
  bank: string;
  accountType: string;
  accountNumber: string;
  exemptionPDF?: { url: string; _id: string; file: ActualFileObject };
  // authorized: string[];
}

export interface EventDataFormFields {
  name: string;
  category?: string;
  type: string;
  restriction?: string;
  placeName: string;
  place: PlaceResult;
  description: string;
  hasSeats: boolean;
  hasQueue: boolean;
  seatsMail?: string;
  seatsPhone?: string;
}

export interface FunctionFormFields {
  functions: {
    openingDate: { value: string; label: string };
    closingDate: { value: string; label: string };
    openingHour: string;
    closingHour: string;
  }[];
}

export interface TicketFormFields {
  tickets: {
    name: string;
    description?: string;
    color?: string;
    amount?: number;
    price?: string;
    openingDate: { value: string; label: string };
    closingDate: { value: string; label: string };
    openingHour: string;
    closingHour: string;
    maxQuantityPerUser?: number;
    minimumQuantityPerUser?: number;
  }[];
}

export interface ImageField {
  url: string;
  _id: string;
  file: ActualFileObject;
}

export interface ImageFormFields {
  card: ImageField;
  detail: ImageField;
  banner?: ImageField;
  bannerMobile?: ImageField;
  place?: ImageField;
}

export interface PaymentMethodFields {
  methods: PaymentMethods[];
}

export interface StoredFormData {
  organizer: OrganizerFormFields;
  eventData: EventDataFormFields;
  functions: FunctionFormFields;
  images: ImageFormFields;
  tickets: TicketFormFields;
  paymentMethods: PaymentMethodFields;
}

export type EventFormStoreProps = {
  eventCard: EventCardProps;
  setEvent: (data: Partial<EventCardProps>) => void;
  formData?: StoredFormData;
  setFormData: (data: Partial<StoredFormData>) => void;
  mode: CreateEventMode;
  setMode: (mode: CreateEventMode) => void;
};

export const useEventFormStore = create<EventFormStoreProps>()((set) => ({
  eventCard: emptyEvent,
  setEvent: (data) =>
    set((store) => ({ eventCard: { ...store.eventCard, ...data } })),
  formData: undefined,
  setFormData: (data) =>
    set((store) => ({ formData: { ...store.formData, ...data } })),
  mode: "create",
  setMode: (mode: CreateEventMode) => set(() => ({ mode })),
}));
