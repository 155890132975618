import { Link, Stack, Text } from "@chakra-ui/react";
import React from "react";

type Props = {};

const ErrorScreen = (props: Props) => {
  return (
    <Stack
      spacing={10}
      maxW={["95%", "95%", "60%"]}
      borderColor={"secondary.main"}
      mx={"auto"}
      mt={[20, 20, 100]}
      borderWidth={"3px"}
      borderRadius={"xl"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      h={"full"}
      p={5}
      py={10}
    >
      <Text textAlign={"center"} fontSize={36} fontWeight={700}>
        Ups!
      </Text>
      <Text textAlign={"center"} fontSize={16} fontWeight={400}>
        Ha ocurrido un error, por favor vuelve a cargar la página.
      </Text>
      <Link textAlign={"center"} color={"brandAccent.main"} href="/">
        Ir al home
      </Link>
    </Stack>
  );
};

export default ErrorScreen;
