import { useCallback, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useEventsService } from "../../../../services/events/eventsService";
import { useAsync } from "../../../../utils/useAsync";
import { GuestTicketsController } from "./interfaces";

export const useGuestTicketsController = (): GuestTicketsController => {
  const { activeFunction } = useOutletContext<{
    activeFunction: {
      label: string;
      value: string;
      token: string;
      eventFunctionChartKey: string;
    };
  }>();

  const eventService = useEventsService();
  const [variants, variantsError, variantsLoading, execute] = useAsync(
    eventService.getFunctionTicketVariants,
    { defaultValue: [] }
  );

  const variantsExecute = useCallback(() => {
    if (activeFunction && activeFunction.value) {
      execute(activeFunction.value);
    }
  }, [activeFunction]);

  useEffect(() => {
    variantsExecute();
  }, [variantsExecute]);

  return {
    activeFunction,
    variants,
    variantsLoading,
    variantsError,
    variantsExecute,
  };
};
