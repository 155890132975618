/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { AssistanceProps } from "./interfaces";
import { useAssistanceController } from "./assistance.controller";
import {
  Stack,
  SimpleGrid,
  Checkbox,
  Spinner,
  Center,
  HStack,
  Text,
  Button,
  Link,
  IconButton,
} from "@chakra-ui/react";
import Card from "../../../../components/Card";
import CustomTable from "../../../../components/Table";
import StatCard from "../components/StatCard";
import { useOutletContext } from "react-router-dom";
import NoFunctionSelected from "../../../../components/NoFunctionSelected";
import { GuestTickets } from "../GuestTickets/guest-tickets.view";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { DownloadIcon } from "../../../../components/Icons/DownloadIcon";
import { useToaster } from "../../../../utils/useToaster";

export const AssistanceView: React.FC<AssistanceProps> = ({
  useController = useAssistanceController,
}) => {
  const { activeFunction } = useOutletContext<{
    activeFunction: { label: string; value: string; token: string };
  }>();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const controller = useController();

  const getTicketPDF = async (id: string) => {
    try {
      const bufferedFile = await controller.getTicketPDF(id);

      const href = URL.createObjectURL(new Blob([bufferedFile]));

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        "ChileTiket-" + new Date().toISOString() + ".pdf"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL

      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }

      URL.revokeObjectURL(href);
    } catch (error) {
      console.error(error);
    }
  };

  const { successToast, errorToast } = useToaster();

  const columns = useMemo(
    () => [
      {
        Header: "Ingresó",
        accessor: "used",
        isCentered: true,
        Cell: ({
          row: {
            original: { used, onSelect, code },
          },
        }) => {
          const [isLoading, setIsLoading] = useState(false);
          const [usedValue, setUsedValue] = useState(used);
          const handleChange = async () => {
            try {
              setIsLoading(true);
              await onSelect(usedValue);
              setUsedValue(!usedValue);
            } catch (error) {
              console.error("Error: ", error);
            } finally {
              setIsLoading(false);
            }
          };

          return (
            <Center>
              {isLoading ? (
                <Spinner size={"sm"} colorScheme="primary" />
              ) : (
                <Checkbox
                  isChecked={usedValue}
                  onChange={handleChange}
                  colorScheme={"primary"}
                  borderColor={"muted.300"}
                />
              )}
            </Center>
          );
        },
      },
      {
        Header: "Código",
        accessor: "code",
        isCentered: true,
      },
      {
        Header: "Detalle",
        accessor: "detail",
        isCentered: true,
      },
      {
        accessor: "_id",
      },
      {
        accessor: "ticketReference",
      },
      {
        Header: "Nombre",
        accessor: "name",
        isCentered: true,
      },
      {
        Header: "Correo",
        accessor: "email",
        id: "email",
        isCentered: true,
      },
      {
        Header: "Teléfono",
        id: "phone",
        accessor: "phone",
        isCentered: true,
      },
      {
        Header: "RUT",
        accessor: "idNumber",
        isCentered: true,
      },
      {
        Header: "Ticket",
        isCentered: true,
        Cell: ({
          row: {
            original: { ticketReference },
          },
        }) => {
          const [isLoading, setIsLoading] = useState(false);

          return (
            <IconButton
              isLoading={isLoading}
              aria-label="Download ticket PDF"
              icon={<DownloadIcon fontSize={20} color={"primary.main"} />}
              size={{ xs: "lg", md: "md" }}
              colorScheme={"primary"}
              variant="ghost"
              onClick={async () => {
                try {
                  setIsLoading(true);
                  await getTicketPDF(ticketReference);
                  successToast(
                    "Se descargó el ticket satisfactoriamente",
                    "El ticket se descargó correctamente en su carpeta de descargas"
                  );
                } catch (error) {
                  errorToast(
                    "Error al descargar el ticket",
                    "Ocurrió un error en la descarga. Inténtelo nuevamente más tarde."
                  );
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          );
        },
      },
    ],
    []
  );

  return controller.guestTicketsShown ? (
    <Stack spacing={5}>
      <Link onClick={() => controller.setGuestTicketsShown(false)}>
        <ArrowBackIcon /> Volver a la lista de asistencia
      </Link>
      <GuestTickets />
    </Stack>
  ) : activeFunction.value !== "all" ? (
    <Stack
      direction="column"
      spacing={5}
      overflow={"auto"}
      color={"secondary.contrast"}
    >
      <SimpleGrid
        columns={{ sm: 1, md: 3, xl: 3 }}
        spacing="30px"
        color={"muted.600"}
      >
        <StatCard
          title="Ingresaron"
          value={controller.attendeesCount.used}
          loading={controller.attendeesCountLoading}
        />
        <StatCard
          title="No ingresaron"
          value={controller.attendeesCount.notUsed}
          loading={controller.attendeesCountLoading}
        />
        <StatCard
          title="Total"
          value={controller.attendeesCount.total}
          loading={controller.attendeesCountLoading}
        />
      </SimpleGrid>
      <Card py={10} h="fit-content">
        <CustomTable
          customHeader={
            <HStack spacing={[2, 5, 10]} px={5}>
              <Text fontSize={18} fontWeight={600}>
                Lista de asistencia
              </Text>
              <Button
                colorScheme={"primary"}
                variant={"outline"}
                borderRadius={"full"}
                size={"sm"}
                onClick={() => controller.setGuestTicketsShown(true)}
              >
                Enviar cortesía
              </Button>
            </HStack>
          }
          columns={columns}
          initialHiddenColumns={["_id", "ticketReference"]}
          initialPageSize={10}
          data={controller.assistanceListData.data}
          fetchData={controller.getUserList}
          pageCount={controller.assistanceListData.totalPages}
          loading={controller.userListLoading}
          totalDocs={controller.assistanceListData.totalDocs}
          paginatorOptions={{
            enabled: true,
            type: "basic",
          }}
          searchProps={{
            enabled: true,
            inputProps: {
              borderColor: "muted.400",
            },
          }}
        />
      </Card>
    </Stack>
  ) : (
    <NoFunctionSelected />
  );
};
