import React from "react";
import { Outlet } from "react-router-dom";
import AdminLayout from "../components/Layouts/Admin/AdminLayout";
import Navbar from "../components/Navbar/Navbar";
import { AssistanceView } from "../views/Admin/EventInfo/Assistance/assistance.view";
import { EventList } from "../views/Admin/EventList/event-list.view";
import { EventStats } from "../views/Admin/EventInfo/Stats/stats.view";
import { EventAdminInfo } from "../views/Admin/EventInfo/event-admin-info.view";
import { Footer } from "../components/Footer";
import { OfficeSale } from "../views/Admin/OfficeSale/office-sale.view";

const AdminLayoutContainer = () => {
  return (
    <AdminLayout header={<Navbar />} footer={<Footer />}>
      <Outlet />
    </AdminLayout>
  );
};

export const EventInfoLayout = () => {
  return <EventAdminInfo />;
};

export const EventListLayoutContainer = () => {
  return <EventList />;
};

export const StatsLayoutContainer = () => {
  return <EventStats />;
};

export const AssistanceLayoutContainer = () => {
  return <AssistanceView />;
};

export const OfficeSaleLayout = () => {
  return <OfficeSale />;
};

export default AdminLayoutContainer;
