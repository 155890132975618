import { Box, useStyleConfig } from '@chakra-ui/react';
import {
  HTMLChakraProps,
} from "@chakra-ui/system"
export interface CardProps extends HTMLChakraProps<'div'> {
  variant?: any;
  style?: any;
  width?: any;
  height?: any;
}

const Card: React.FC<CardProps> = ({
  variant,
  children,
  style,
  width,
  height,
  ...rest
}) => {
  const styles = useStyleConfig('Card', { variant });
  // Pass the computed styles into the `__css` prop
  return (
    <Box
      __css={styles}
      style={style}
      {...rest}
      width={width}
      height={height}
      transition="all 0.15s ease">
      {children}
    </Box>
  );
};

export default Card;