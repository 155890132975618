/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Center,
  useDisclosure,
  Box,
  Flex,
  useOutsideClick,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Badge,
} from "@chakra-ui/react";
import {
  CalendarControls,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from "@uselessdev/datepicker";
import {
  add,
  format,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  parse,
} from "date-fns";
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FormikProps,
  FormikProvider,
} from "formik";
import { useEffect, useRef, useState } from "react";
import { RiPencilFill } from "react-icons/ri";

import ThemedCalendar from "../../../../components/CalendarPicker/themedCalendar";
import ConfirmActionModal from "../../../../components/ConfirmActionModal";
import {
  FunctionFormFields,
  useEventFormStore,
} from "../../../../services/createEvent/createEventStore";

import {
  formateDateRange,
  formatSingleDate,
} from "../../../../utils/dateFormatters";
import { EventStep } from "./step";

export type FunctionsFormProps = { formik: FormikProps<FunctionFormFields> };

const FunctionsForm = ({ formik }: FunctionsFormProps) => {
  const { setFormData, setEvent } = useEventFormStore();

  const [selectedFunction, setSelectedFunction] = useState(0);

  const [dates, setDates] = useState<CalendarValues>({});

  const {
    isOpen: isCalendarOpen,
    onOpen: onCalendarOpen,
    onClose: onCalendarClose,
  } = useDisclosure();
  const {
    isOpen: isModalConfirmOpen,
    onOpen: onModalConfirmOpen,
    onClose: onModalConfirmClose,
  } = useDisclosure();
  const initialRef = useRef(null);
  const calendarRef = useRef(null);
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  const handleSelectDate = (dates: CalendarValues) => {
    setDates(dates);

    formik.setFieldValue(
      `functions.${selectedFunction}.openingDate`,
      isValid(dates.start)
        ? {
          value: format(dates.start as Date, "MM/dd/yyyy"),
          label: format(dates.start as Date, "dd/MM/yyyy"),
        }
        : { value: "", label: "" }
    );

    formik.setFieldValue(
      `functions.${selectedFunction}.closingDate`,
      isValid(dates.end)
        ? {
          value: format(dates.end as Date, "MM/dd/yyyy"),
          label: format(dates.end as Date, "dd/MM/yyyy"),
        }
        : { value: "", label: "" }
    );
  };

  const match = (value?: string) =>
    value && value.match(/(\d{2})\/(\d{2})\/(\d{4})/);

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(`functions.${selectedFunction}.${target.name}`, {
      label: target.value,
      value: format(
        parse(target.value, "dd/MM/yyyy", new Date()),
        "MM/dd/yyyy"
      ),
    });

    if (
      target.name === "openingDate" &&
      match(target.value) &&
      endInputRef.current
    ) {
      endInputRef.current.focus();
    }
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onCalendarClose,
    enabled: isCalendarOpen,
  });

  useEffect(() => {
    const { openingDate, closingDate } =
      formik.values.functions[selectedFunction];
    if (match(openingDate.value)) {
      const startDate = new Date(openingDate.value);
      const isValidStartDate = isValid(startDate);
      const isAfterEndDate =
        closingDate && isAfter(startDate, new Date(closingDate.value));
      if (isValidStartDate && isAfterEndDate) {
        formik.setFieldValue(`functions.${selectedFunction}.closingDate`, {
          value: "",
          label: "",
        });

        return setDates({ end: undefined, start: startDate });
      }
      return setDates({ ...dates, start: startDate });
    }
  }, [formik.values.functions[selectedFunction].openingDate]);

  useEffect(() => {
    const { openingDate, closingDate } =
      formik.values.functions[selectedFunction];
    if (match(closingDate.value)) {
      const endDate = new Date(closingDate.value);
      const isValidEndDate = isValid(endDate);
      const isBeforeStartDate =
        openingDate && isBefore(endDate, new Date(openingDate.value));

      if (isValidEndDate && isBeforeStartDate) {
        formik.setFieldValue(`functions.${selectedFunction}.openingDate`, {
          value: "",
          label: "",
        });

        startInputRef.current?.focus();

        return setDates({ start: undefined, end: endDate });
      }

      // onCalendarClose();
      return setDates({ ...dates, end: endDate });
    }
  }, [formik.values.functions[selectedFunction].closingDate]);

  useEffect(() => {
    const { openingDate, closingDate } =
      formik.values.functions[selectedFunction];

    let newOpeningDate = undefined;
    let newClosingDate = undefined;

    if (match(closingDate.value)) {
      const date = new Date(closingDate.value);
      newClosingDate = isValid(date) ? date : undefined;
    }

    if (match(openingDate.value)) {
      const date = new Date(openingDate.value);
      newOpeningDate = isValid(date) ? date : undefined;
    }

    setDates({ start: newOpeningDate, end: newClosingDate });
  }, [selectedFunction]);

  useEffect(() => {
    const dates = formik.values.functions;
    if (dates.every((date) => isValid(new Date(date.openingDate.value)))) {
      const starts = dates.map((date) => new Date(date.openingDate.value));
      const { min, max } = starts.reduce(
        (prev, date) => {
          let { min, max } = prev;
          if (isAfter(date, max)) {
            max = date;
          }
          if (isBefore(date, min)) {
            min = date;
          }
          return { min, max };
        },
        { min: starts[0], max: starts[0] }
      );
      setEvent({
        date: isEqual(min, max)
          ? formatSingleDate(min)
          : formateDateRange(min, max),
      });
    }
  }, [formik.values]);

  let boundArrayHelpers: FieldArrayRenderProps | null;

  const bindArrayHelpers = (arrayHelpers: FieldArrayRenderProps) => {
    boundArrayHelpers = arrayHelpers;
  };

  useEffect(() => {
    setFormData({
      functions: formik.values,
    });
  }, [formik.values]);

  return (
    <EventStep>
      <FormikProvider value={formik}>
        <FieldArray name="functions">
          {(props) => {
            const { push } = props;
            bindArrayHelpers(props);
            return (
              <Stack spacing={8}>
                <Stack spacing={2}>
                  <Text
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={500}
                    w="85%"
                  >
                    Fechas del evento
                  </Text>
                  <Text fontSize={"sm"} w="85%">
                    Elige la fecha y hora en los que se realizará tu evento.
                  </Text>
                  <Text fontSize={"sm"} w="85%">
                    Si el evento tiene más de una fecha, también podrás
                    agregarlas en esta sección.
                  </Text>
                </Stack>

                <Flex wrap={"wrap"}>
                  {formik.values &&
                    formik.values.functions.map(
                      (fun, index) =>
                        fun.closingDate?.value?.length > 0 &&
                        fun.openingDate?.value?.length > 0 && (
                          <Badge
                            mr={2}
                            mb={2}
                            key={index}
                            p={2}
                            display={"flex"}
                            width={"max-content"}
                            alignItems={"center"}
                            colorScheme={
                              selectedFunction === index ? "primary" : "muted"
                            }
                            bgColor={
                              selectedFunction === index
                                ? "backgroundColor"
                                : "muted.main"
                            }
                            color={
                              selectedFunction === index
                                ? "primary.main"
                                : "black"
                            }
                            variant={
                              selectedFunction === index ? "outline" : "solid"
                            }
                            borderRadius={"xl"}
                            cursor={"pointer"}
                            onClick={() => setSelectedFunction(index)}
                          >
                            <Box mr={2} maxW={"xl"} overflow={"hidden"}>
                              {formateDateRange(
                                new Date(fun.openingDate.value),
                                new Date(fun.closingDate.value)
                              )}
                            </Box>
                            <RiPencilFill
                              size={18}
                              color={
                                selectedFunction === index
                                  ? "primary.main"
                                  : "black"
                              }
                            />
                          </Badge>
                        )
                    )}
                </Flex>
                <Flex wrap={"wrap"} justifyContent={"space-between"}>
                  <Box w={["100%", "100%", "48%"]}>
                    <Popover
                      placement="bottom"
                      isOpen={isCalendarOpen}
                      onClose={onCalendarClose}
                      initialFocusRef={initialRef}
                    >
                      <PopoverTrigger>
                        <Flex
                          direction={"column"}
                          onClick={onCalendarOpen}
                          ref={initialRef}
                        >
                          <Box my={2}>
                            <Field
                              name={`functions.${selectedFunction}.openingDate.label`}
                            >
                              {({ field, form }) => (
                                <FormControl
                                  variant="floating"
                                  borderRadius={4}
                                  id={`functions.${selectedFunction}.openingDate.label`}
                                  isInvalid={
                                    form.errors.functions &&
                                    form.errors.functions[selectedFunction] &&
                                    form.errors.functions[selectedFunction]
                                      .openingDate
                                  }
                                  isRequired
                                >
                                  <Input
                                    {...field}
                                    name={`openingDate`}
                                    placeholder="dd/MM/yyyy"
                                    onChange={handleInputChange}
                                    ref={startInputRef}
                                  />
                                  <FormLabel>Inicio del evento</FormLabel>
                                  <FormErrorMessage>
                                    {form.errors.functions &&
                                      form.errors.functions[selectedFunction] &&
                                      form.errors.functions[selectedFunction]
                                        .openingDate?.label}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                          <Box my={2}>
                            <Field
                              name={`functions.${selectedFunction}.closingDate.label`}
                            >
                              {({ field, form }) => (
                                <FormControl
                                  variant="floating"
                                  borderRadius={4}
                                  id={`functions.${selectedFunction}.closingDate.label`}
                                  isInvalid={
                                    form.errors.functions &&
                                    form.errors.functions[selectedFunction] &&
                                    form.errors.functions[selectedFunction]
                                      .closingDate
                                  }
                                  isRequired
                                >
                                  <Input
                                    {...field}
                                    name={`closingDate`}
                                    placeholder="dd/MM/yyyy"
                                    onChange={handleInputChange}
                                    ref={endInputRef}
                                  />
                                  <FormLabel>Fin del evento</FormLabel>
                                  <FormErrorMessage>
                                    {form.errors.functions &&
                                      form.errors.functions[selectedFunction] &&
                                      form.errors.functions[selectedFunction]
                                        .closingDate?.label}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                        </Flex>
                      </PopoverTrigger>

                      <PopoverContent
                        p={0}
                        ref={calendarRef}
                        bgColor={"backgroundColor"}
                      >
                        <ThemedCalendar
                          disablePastDates={add(new Date(), { days: -1 })}
                          allowSelectSameDay
                          value={dates}
                          onSelectDate={handleSelectDate}
                        >
                          <PopoverBody w={"xs"} p={0}>
                            <CalendarControls>
                              <CalendarPrevButton />
                              <CalendarNextButton />
                            </CalendarControls>

                            <CalendarMonths>
                              <CalendarMonth>
                                <CalendarMonthName />
                                <CalendarWeek />
                                <CalendarDays />
                              </CalendarMonth>
                            </CalendarMonths>
                          </PopoverBody>
                        </ThemedCalendar>
                      </PopoverContent>
                    </Popover>
                  </Box>
                  <Box
                    w={["100%", "100%", "48%"]}
                    display={"flex"}
                    flexDir={"column"}
                  >
                    <Box w={"100%"} my={2}>
                      <Field name={`functions.${selectedFunction}.openingHour`}>
                        {({ field, form }) => (
                          <FormControl
                            variant="floating"
                            borderRadius={4}
                            id={`functions.${selectedFunction}.openingHour`}
                            isInvalid={
                              form.errors.functions &&
                              form.errors.functions[selectedFunction] &&
                              form.errors.functions[selectedFunction]
                                .openingHour
                            }
                            isRequired
                          >
                            <Input
                              {...field}
                              type={"time"}
                              placeholder="00:00"
                            />

                            <FormLabel>Horario de apertura</FormLabel>
                            <FormErrorMessage>
                              {form.errors.functions &&
                                form.errors.functions[selectedFunction] &&
                                form.errors.functions[selectedFunction]
                                  .openingHour}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box w={"100%"} my={2}>
                      <Field name={`functions.${selectedFunction}.closingHour`}>
                        {({ field, form }) => (
                          <FormControl
                            variant="floating"
                            borderRadius={4}
                            id={`functions.${selectedFunction}.closingHour`}
                            isInvalid={
                              form.errors.functions &&
                              form.errors.functions[selectedFunction] &&
                              form.errors.functions[selectedFunction]
                                .closingHour
                            }
                            isRequired
                          >
                            <Input
                              type={"time"}
                              {...field}
                              placeholder="00:00"
                            />
                            <FormLabel>Horario de cierre</FormLabel>
                            <FormErrorMessage>
                              {form.errors.functions &&
                                form.errors.functions[selectedFunction] &&
                                form.errors.functions[selectedFunction]
                                  .closingHour}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </Box>
                  <Box w={"100%"}>
                    <Text color={"muted.main"}>
                      No se podrán vender tickets para esta función una vez
                      alcanzada la fecha de cierre
                    </Text>
                  </Box>
                </Flex>

                <Center>
                  <Stack direction={["column", "column", "row"]} spacing={5}>
                    {formik.values?.functions?.length > 1 && (
                      <Button
                        colorScheme={"red"}
                        borderRadius="full"
                        variant={"outline"}
                        isLoading={false}
                        w={{ xs: "fit-content", md: "30%" }}
                        onClick={onModalConfirmOpen}
                      >
                        {"Eliminar función"}
                      </Button>
                    )}
                    <Button
                      colorScheme={"primary"}
                      borderRadius="full"
                      variant={"outline"}
                      isLoading={false}
                      w={{ xs: "fit-content", md: "30%" }}
                      onClick={() => {
                        const length = formik.values?.functions?.length;
                        push({
                          name: "",
                          amount: "",
                          price: "",
                          absorbed: false,
                          openingDate: "",
                          closingDate: "",
                          openingHour: "00:00",
                          closingHour: "00:00",
                        });
                        setSelectedFunction(length);
                      }}
                    >
                      {"Añadir otra función"}
                    </Button>
                  </Stack>
                </Center>
              </Stack>
            );
          }}
        </FieldArray>
      </FormikProvider>
      <ConfirmActionModal
        title={"¿Deseas eliminar esta función?"}
        confirmationMessage="La función se eliminiará para este evento"
        isOpen={isModalConfirmOpen}
        onClose={onModalConfirmClose}
        onConfirm={() => {
          boundArrayHelpers?.remove(selectedFunction);
          setSelectedFunction(
            selectedFunction - 1 < 0 ? selectedFunction : selectedFunction - 1
          );
        }}
      />
    </EventStep>
  );
};

export default FunctionsForm;
