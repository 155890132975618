export const SOCIAL_DATA = {
  CHOC_TWITTER: "https://twitter.com/choc_ui",
  CHOC_DISCORD: "https://discord.gg/XcD8bNv7Ne",
  CHOC_GITHUB: "https://github.com/anubra266/choc-ui",

  SPONSOR_LINK: "https://patreon.com/anubra266",
  AUTHOR_GITHUB: "https://github.com/anubra266",
  AUTHOR_TWITTER: "https://twitter.com/anubra266",
  AUTHOR_LINKEDIN: "https://linkedin.com/in/anubra266",
  AUTHOR_EMAIL: "abraham@anubra266.com",
};

export const centerDef = {
  lat: parseFloat("-31.417"),
  lng: parseFloat("-64.183"),
};

export const emptyEvent = {
  id: "",
  index: 0,
  imageURL: "",
  onSelect: () => {},
  blurred: false,
  title: "-------",
  location: "-----",
  date: "-----",
  price: "--------",
  blackTags: [],
  grayTags: [],
};

export const emailModalData = [
  {
    state: "ok",
    title: "El email ha sido verificado",
    subtitle: "El correo de su cuenta se verifico satisfactoriamente.",
  },
  {
    state: "error",
    title: "Ocurrió un error",
    subtitle:
      "El correo de su cuenta no se puedo verificar. Porfavor, vuelva a intentar más tarde o comuniquese con soporte.",
  },
];

export const paymentMethodsLabels = [
  {
    key: "credit_card",
    label: "Tarjeta de credito",
  },
  {
    key: "debit_card",
    label: "Tarjeta de debito",
  },
  {
    key: "account_money",
    label: "Dinero en cuenta",
  },
  {
    key: "bank_transfer",
    label: "Transferencia",
  },
];

export const paymentMethods: { [key: string]: string } = {
  pagoHub: "PH",
  mercadoPago: "MP",
  flow: "FL",
};
