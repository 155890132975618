import { useContext } from "react";
import { AuthContext } from "../../providers/authContext";
import { AxiosContext } from "../../providers/axiosContext";
import { TicketSaleStoreProps, useTicketsSaleStore } from "./ticketsSaleStore";

export interface TicketSaleService {
  store: TicketSaleStoreProps;
  sendTransactionCode: (data: {
    functionId: string;
    transactionId: string;
  }) => Promise<any>;
  getSalePDF: (shoppingSessionId: string) => Promise<Buffer>;
}

export const useTicketsSaleService = (): TicketSaleService => {
  const ticketSaleFormStore = useTicketsSaleStore();
  const axiosContext = useContext(AxiosContext);
  const authContext = useContext(AuthContext);

  const sendTransactionCode = async (data: {
    functionId: string;
    transactionId: string;
  }) => {
    const { transactionId, functionId } = data;
    const res = await axiosContext.authAxios.post(
      `/tickets/external-sale/office-sale/${functionId}`,
      {
        user: { profile: ticketSaleFormStore.formData.profile },
        shoppingSessionId:
          ticketSaleFormStore.formData.createdShoppingSession._id,
        transactionId,
        serviceType: "mpago",
      }
    );
    return res;
  };

  const getSalePDF = async (shoppingSessionId: string): Promise<Buffer> => {
    const res = await axiosContext.authAxios.get(
      `/tickets/pdf/shopping-session/${shoppingSessionId}`,
      {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + authContext.getAccessToken(),
          "Content-Type": "application/pdf",
          accept: "*/*",
        },
      }
    );

    return res.data;
  };

  return {
    store: ticketSaleFormStore,
    sendTransactionCode,
    getSalePDF,
  };
};
